import React from 'react';
import { PrimaryButton } from "../../../../../UI/atoms/buttons/PrimaryButton";
import styled from "styled-components/macro";
import { PageTitle } from "../../../../../UI/atoms/PageTitle";
import { BodyRegular } from "../../../../../UI/atoms/fonts/Body";
import { CustomWhereaboutsList } from "../components/CustomWhereaboutsList";
import { DefaultWhereaboutsOptionsContentCard } from "../components/DefaultWhereaboutsOptionsContentCard";
import { WithCustomWhereaboutsUsageLimitChecker } from "../hooks/WithCustomWhereaboutsUsageLimitChecker";
import { useDispatch } from "react-redux";
import { redirectTo } from "../../../../../../store/ducks/auth.duck";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../../../store/ducks/dialog.duck";
import { UsageTypeKey } from "../../../../../dialogs/usage-limit-dialog/UsageLimitReachedDialog";

export function CustomWhereaboutsListView(props: Props) {
  const dispatch = useDispatch();
  const limitReached = WithCustomWhereaboutsUsageLimitChecker();

  const onAddClicked = () => {
    if (limitReached) {
      dispatch(openDialogWithPayload({
        payload: { usageType: UsageTypeKey.CustomWhereabouts },
        activeDialog: DialogIdentifiers.UsageLimitReachedDialog
      }))
    } else {
      dispatch(redirectTo('/manage/custom-whereabouts/add'))
    }
  }

  return (
    <Container>
      <PageTitle text={"whereabouts.custom-whereabouts"} headlineLarge={true} style={{margin: '0 0 12px 0'}} />
      <BodyRegular style={{marginBottom: 32}}>Enable your team to plan their working week.</BodyRegular>

      <CustomWhereaboutsList />

      <PrimaryButton fullWidth={true}
                     text={'whereabouts.add-custom-whereabouts'}
                     style={{marginBottom: 24}}
                     click={onAddClicked} />

      <DefaultWhereaboutsOptionsContentCard />


    </Container>
  )
}

interface Props {
}

const Container = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
`
