import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Icon, { IconTypes } from "../../../UI/atoms/icon/Icon";
import { SpaceBetweenRow } from "../../settings/StyleComponents";
import Colours from "../../../UI/atoms/Colours";
import { HolidayDay } from "../../holidays-v2/models/Holidays.model";
import { CompanyHoliday } from "../../../../services/CompanyHolidayService";

export enum PopupPosition {
  Left, Right, None,
}

export function SimpleHolidayInfoPopup(props: Props) {
  const {holiday} = props;
  const popupPositioning = props.popupPositioning ?? PopupPosition.Left;
  const date = useMemo(() => moment(holiday.date), [holiday]);
  const {t} = useTranslation();

  const days = useMemo(() => {
    return 1
  }, []);

  if (days === 0) {
    return null;
  }

  return (
    <HolidayInfoPopupWrapper className="popup" popupPositioning={popupPositioning}>
      <SpaceBetweenRow style={{alignItems: 'center', marginBottom: 4}}>
        <BodyRegular colour={Colours.blue} weight={600}>{t('holiday.holiday-booking')}</BodyRegular>
        <Icon icon={IconTypes.Holiday} />
      </SpaceBetweenRow>

      <BodyRegular style={{marginBottom: 2}}>{`${date.format('Do MMMM')}`}</BodyRegular>
    </HolidayInfoPopupWrapper>
  )
}

interface Props {
  holiday: HolidayDay;
  companyHoliday?: CompanyHoliday;
  popupPositioning?: PopupPosition;
}

const HolidayInfoPopupWrapper = styled.div<any>`
  display: none;
  flex-direction: column;
  max-width: 448px;
  min-width: 300px;
  min-height: 50px;
  padding: 24px;
  position: absolute;
  top: 30px;
  left: ${props => props.popupPositioning === PopupPosition.Left ? 0 : 'auto'};
  right: ${props => props.popupPositioning === PopupPosition.Right ? 0 : 'auto'};
  z-index: 999999;
  background: #FFFFFF;
  box-shadow: 0 2px 8px 2px rgba(76, 109, 172, 0.15);
  border-radius: 24px;
  cursor: default;
  p {
    max-width: 100%;
  }
`
