import React from 'react';
import styled from "styled-components/macro";
import { BodyVerySmall } from "../../../../UI/atoms/fonts/Body";
import Colours from "../../../../UI/atoms/Colours";

export function CustomLeaveInfo(props: Props) {
  const {customLeave} = props;
  return (
    <>
      {customLeave.map((leave, key) => (
        <Row key={key}>
          <BodyVerySmall>{leave.label}</BodyVerySmall>
          <BodyVerySmall>{leave.count}</BodyVerySmall>
        </Row>
      ))}
    </>
  )
}

interface Props {
  customLeave: any[];
}

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  margin-top: 8px;
  border-top: 1px solid ${Colours.lightGrey};
`
