import React from 'react';

function getOutlineIcon(colour: string, image: string) {
  switch (colour) {
    case 'green': return image.replace('.svg', '-green.svg').toLowerCase()
    case 'yellow': return image.replace('.svg', '-yellow.svg').toLowerCase()
    default: return image.replace('.svg', '-grey.svg').toLowerCase()
  }
}

const SUPPORTED_IMAGES = [
  'Adverse.svg', 'aeroplane.svg', 'bank-holiday.svg', 'Boat.svg', 'building-site.svg', 'Car.svg', 'DryDock.svg',
  'Duvet.svg', 'euro.svg', 'Factory.svg', 'Flag.svg', 'GardenLeave.svg', 'HardHat.svg', 'Hospital.svg', 'Holiday.svg', 'House.svg',
  'ill.svg', 'Office.svg', 'Offsite.svg', 'PendingHoliday.svg', 'NotWorking.svg', 'Port.svg', 'Volunteering.svg', 'Maternity.svg',
  'Inlieu.svg', 'Jury.svg', 'Morning.svg', 'NightShift.svg', 'Parental.svg', 'BankHoliday.svg', 'university.svg', 'shop.svg',
  'Travel.svg', 'Seminar.svg', 'sales-fayre.svg', 'Sick.svg', 'Star.svg', 'Study.svg', 'Time.svg', 'Train.svg', 'Sabbatical.svg',
]

function isImageSupported(image: string) {
  return SUPPORTED_IMAGES.includes(image);
}

export function WhereaboutsIconOutline(props: Props) {
  const title = props.label
  const imageUrl = getOutlineIcon(props.colour, props.image)
  const imageSupported = isImageSupported(props.image);

  if (imageSupported) {
    return <img title={title} style={{marginRight: 16}}
                className={"whereabouts__outlineIcon"}
                src={`/assets/icons/outline-icons/${imageUrl}`}
                onMouseOver={(e: any) => !!props.stopPropagation ? e.stopPropagation() : false}
                onMouseLeave={(e: any) => !!props.stopPropagation ? e.stopPropagation() : false}
                onTouchStart={(e: any) => !!props.stopPropagation ? e.stopPropagation() : false}
                onTouchEnd={(e: any) => !!props.stopPropagation ? e.stopPropagation() : false}
                onTouchCancel={(e: any) => !!props.stopPropagation ? e.stopPropagation() : false}
                alt="" />;
  } else {
    console.log(imageSupported, imageUrl)
    return <WhereaboutsIconOutlineUnknown stopPropagation={props.stopPropagation} />
  }
}

export function WhereaboutsIconOutlineUnknown(props: {stopPropagation?: boolean}) {
  return <img className={"whereabouts__outlineIcon"}
              style={{marginRight: 16}}
              src="/assets/icons/outline-icons/dot-outline.svg"
              onMouseOver={(e: any) => !!props.stopPropagation ? e.stopPropagation() : false}
              onMouseLeave={(e: any) => !!props.stopPropagation ? e.stopPropagation() : false}
              onTouchStart={(e: any) => !!props.stopPropagation ? e.stopPropagation() : false}
              onTouchEnd={(e: any) => !!props.stopPropagation ? e.stopPropagation() : false}
              onTouchCancel={(e: any) => !!props.stopPropagation ? e.stopPropagation() : false}
              alt="" />
}

interface Props {
  image: string;
  label: string;
  colour: string;
  stopPropagation?: boolean;
}
