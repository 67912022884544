import i18next from 'i18next';
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';
import translation from './en/translation.json';
import translationFr from './fr/translation.json';
import translationDe from './de/translation.json';

export const resources = {
  en: {
    translation
  },
  fr: {
    translation: translationFr
  },
  de: {
    translation: translationDe
  }
};

i18next
  .use(detector)
  .use(initReactI18next)
    .init({
      supportedLngs: ['en', 'fr', 'de'], // List of supported languages
      detection: {
        order: ['navigator'], // Detect language from the browser
      },
      debug: process.env.NODE_ENV !== 'production',
      resources,
      fallbackLng: "en", // use en if detected lng is not available
      interpolation: {
        escapeValue: true,
      }
    });
