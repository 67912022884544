import { useEffect, useState } from "react";
import { OfficeEntity } from "../services/AdvanceHotDeskingService";
import moment, { Moment } from "moment";
import { getOfficeAvailability } from "../services/OfficeAvailabilityService";
import { DATE_FORMAT } from "../utils/DateUtils";

export function WithOfficeAvailability(office?: OfficeEntity, selectedDate?: Moment): any {
  const [loading, setLoading] = useState(false);
  const [amSpaces, setAmSpaces] = useState(0);
  const [pmSpaces, setPmSpaces] = useState(0);
  const [failed, setFailed] = useState(false);
  const [lastLoaded, setLastLoaded] = useState('');
  const selectedDateString = selectedDate?.format(DATE_FORMAT);

  useEffect(() => {
    setAmSpaces(0);
    setPmSpaces(0);
    setLastLoaded('');
  }, [office, selectedDateString]);

  const reloadAvailability = () => {
    if (loading || !office || !selectedDateString || amSpaces || pmSpaces || failed) {
      return;
    }

    if (lastLoaded === `${office.id}-${selectedDateString}`) {
      return;
    }

    setLoading(true);
    setLastLoaded(`${office.id}-${selectedDateString}`);
    getOfficeAvailability(office.id, moment(selectedDateString))
      .then((resp) => {
        setAmSpaces(resp.amTotalSpacesAvailable);
        setPmSpaces(resp.pmTotalSpacesAvailable);
      })
      .catch(() => {
        setFailed(true);
      })
      .finally(() => setLoading(false))
  };

  const spacesAvailable = () => {
    return Math.min(amSpaces, pmSpaces);
  }

  return {
    loading: loading,
    reloadAvailability,
    amSpaces: amSpaces,
    pmSpaces: pmSpaces,
    spacesAvailable: spacesAvailable
  };
}
