import React, { useEffect, useMemo } from 'react';
import { Column } from "../../UI/atoms/StructuralLayout";
import { HeadlineLarge } from "../../UI/atoms/fonts/Headline";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { GoBackButton } from "../../UI/atoms/buttons/GoBackButton";
import { MountedContentCard } from "../../UI/atoms/MountedContentCard";
import { useParams } from "react-router";
import {
  loadUsersHolidays, selectCompanyHolidays,
  selectHolidayUserInfo,
  selectUsersBookedHolidays, selectUsersCustomLeave
} from "../../../store/ducks/holidays-v2.duck";
import Layout from '../../UI/organisms/layout/Layout';
import { DATE_FORMAT, PRETTY_SHORT_DATE_FORMAT } from "../../../utils/DateUtils";
import { useTranslation } from "react-i18next";
import { BodyRegular, BodyVerySmall } from "../../UI/atoms/fonts/Body";
import { UserInfoAndLeaveTypeBox } from "../../UI/molecules/UserInfoAndLeaveTypeBox";
import { selectConfig } from "../../../store/ducks/config.duck";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import moment from "moment";
import { Pill } from "../../UI/atoms/Pill";
import Colours from "../../UI/atoms/Colours";
import { OutlineButton } from "../../UI/atoms/buttons/OutlineButton";
import IconButton from "../../UI/molecules/icon-button/IconButton";
import { IconTypes } from "../../UI/atoms/icon/Icon";
import { WhereaboutsHeaderImage } from "./components/WhereaboutsHeaderImage";
import { selectAllWhereaboutsOptions } from "../../../store/ducks/whereaboutsOptions.duck";
import { selectIconPack } from "../../../store/ducks/auth.duck";
import { iconPackPath } from "../../../utils/WhereaboutsHelper";
import { COMPANY_HOLIDAY_ID, FreeBusyStatus, HOLIDAY_ID, UNKNOWN_ID } from "../../../services/WhereaboutOptions";
import { HolidayDateRangeDisplay } from "./components/HolidayDateRangeDisplay";

export function HolidayCreatedSuccessfullyPage() {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const params = useParams<any>();
  const userIdFromParams = params.user;
  const holidayUserInfo = useSelector(selectHolidayUserInfo);
  const holidayBookings = useSelector(selectUsersBookedHolidays);
  const customLeaveBookings = useSelector(selectUsersCustomLeave);
  const companyHolidays = useSelector(selectCompanyHolidays);
  const config = useSelector(selectConfig);
  const allWhereaboutsOptions = useSelector(selectAllWhereaboutsOptions);
  const iconPack = useSelector(selectIconPack);

  const fromDate = moment(params.from);
  const toDate = moment(params.to);

  const filteredLeaveBookings = useMemo(() => {
    return holidayBookings.filter(hb => hb.date >= fromDate.format(DATE_FORMAT) && hb.date <= toDate.format(DATE_FORMAT));
  }, [fromDate, holidayBookings, toDate]);

  const filteredCustomLeaveBookings = useMemo(() => {
    return customLeaveBookings.filter(clb => clb.date >= fromDate.format(DATE_FORMAT) && clb.date <= toDate.format(DATE_FORMAT));
  }, [customLeaveBookings, fromDate, toDate]);

  const filteredCompanyHolidays = useMemo(() => {
    return companyHolidays.filter(clb => clb.date >= fromDate.format(DATE_FORMAT) && clb.date <= toDate.format(DATE_FORMAT));
  }, [companyHolidays, fromDate, toDate]);

  const daysBookedOff = useMemo(() => {
    if (filteredLeaveBookings.length > 0) {
      return filteredLeaveBookings;
    } else if (filteredCustomLeaveBookings.length > 0) {
      return filteredCustomLeaveBookings;
    } else if (filteredCompanyHolidays.length > 0) {
      return filteredCompanyHolidays;
    } else {
      return [];
    }
  }, [filteredLeaveBookings, filteredCustomLeaveBookings, filteredCompanyHolidays]);

  const numberOfDaysBookedOff = useMemo(() => {
    let counter = 0;
    daysBookedOff.forEach((d: any) => {
      if (d.am && d.pm) counter += 2;
      else if (d.am && !d.pm) counter += 1;
      else if (!d.am && d.pm) counter += 1;
    })
    return counter / 2;
  }, [daysBookedOff])

  const leaveWhereaboutsTypeId = useMemo(() => {
    if (filteredLeaveBookings.length > 0) {
      return HOLIDAY_ID;
    } else if (filteredCustomLeaveBookings.length > 0) {
      return filteredCustomLeaveBookings[0].amWhereaboutsId;
    } else if (companyHolidays.length > 0) {
      return COMPANY_HOLIDAY_ID;
    } else {
      return UNKNOWN_ID;
    }
  }, [companyHolidays.length, filteredCustomLeaveBookings, filteredLeaveBookings.length])

  const selectedWhereabouts = useMemo(() => {
    return allWhereaboutsOptions.find(wo => wo.id === leaveWhereaboutsTypeId);
  }, [allWhereaboutsOptions, leaveWhereaboutsTypeId]);

  const whereaboutsImageUrl = useMemo(() => {
    if (selectedWhereabouts) {
      return iconPackPath(iconPack, selectedWhereabouts.image, FreeBusyStatus.FREE);
    }
  }, [iconPack, selectedWhereabouts]);

  useEffect(() => {
    if (userIdFromParams) {
      dispatch(loadUsersHolidays({userId: userIdFromParams}));
    }
  }, [dispatch, userIdFromParams]);

  return (
    <Layout>
      <MountedContentCardWrapper centre={true}>
        <GoBackButton/>
        <Column style={{position: 'relative', marginBottom: 8}}>

          <WhereaboutsHeaderImage imageUrl={whereaboutsImageUrl} />

          <HeadlineLarge style={{marginBottom: 12, marginLeft: 'auto', marginRight: 'auto'}}>{t('holidays.booked')}</HeadlineLarge>
          <BodyRegular style={{marginBottom: 24, marginLeft: 'auto', marginRight: 'auto'}}>{t('holidays.holiday-year-span', {from: config.getHolidayYearStartDate()?.format(PRETTY_SHORT_DATE_FORMAT), to: config.getHolidayYearEndDate()?.format(PRETTY_SHORT_DATE_FORMAT)})}</BodyRegular>

          <UserInfoAndLeaveTypeBox firstName={holidayUserInfo.firstName}
                                   lastName={holidayUserInfo.lastName}
                                   teamName={holidayUserInfo.teamName}
                                   userId={holidayUserInfo.userId}
                                   style={{marginBottom: 24, maxWidth: '100%', width: '100%'}}>

            <IconButton link={`/holidays/${holidayUserInfo.userId}/edit/${fromDate.format(DATE_FORMAT)}`}
                        style={{position: 'absolute', top: 16, right: 16}}
                        icon={IconTypes.Edit} />

            <BookingContentWrapper>

              <BookingContentInfo>
                <WhereaboutsImage src={whereaboutsImageUrl} />

                <div>
                  <BodyRegular weight={600}>{selectedWhereabouts?.label}</BodyRegular>
                  <HolidayDateRangeDisplay toDate={toDate} fromDate={fromDate} />

                  {numberOfDaysBookedOff > 0 && <Pill style={{marginBottom: 24}} backgroundColour={Colours.blue02} colour={Colours.blue}>
                    <BodyVerySmall weight={600}>{numberOfDaysBookedOff} {t('approvals.days')}</BodyVerySmall>
                  </Pill>}
                </div>

              </BookingContentInfo>

              <OutlineButton text={'holidays.add-more-leave'} fullWidth={true}
                             link={`/holidays/${holidayUserInfo.userId}/add`} />
            </BookingContentWrapper>
          </UserInfoAndLeaveTypeBox>
        </Column>



        <PrimaryButton text={'button.done-for-now'}
                       fullWidth={true}
                       link={'/holidays'}/>

      </MountedContentCardWrapper>
    </Layout>
  )
}

const MountedContentCardWrapper = styled<any>(MountedContentCard)`
  margin-top: 40px;
  max-width: 600px;
  margin-bottom: 100px;
  .linkButton {
    width: 100%;
  }
`

const BookingContentWrapper = styled.div`
  border-top: 1px solid ${Colours.mildGrey};
  padding-top: 24px;
  margin-top: 12px;
  margin-left: -42px;
`

const BookingContentInfo = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
`

const WhereaboutsImage = styled.img`
  width: 48px;
  height: 48px;
  margin-right: 12px;
`
