import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { BodyRegular, BodyXXSmall } from '../../atoms/fonts/Body';
import Icon, { IconTypes } from "../../atoms/icon/Icon";
import { useTranslation } from "react-i18next";

const WEEKS = 6;

export interface CalendarProps {
  onDateSelect: (date: Moment) => void;
  activeDate?: Moment | null;
  initialDate: Moment | null;
  highlightToday?: boolean;
  endDate?: Moment | null;
  stretch?: boolean;
  className?: string;
  enabledDateRange?: boolean;
  highlightedDays?: HighlightDay[];
  secondaryHighlightedDays?: HighlightDay[];
  hideFlicker?: boolean;
  hideActiveDayHighlight?: boolean;
  hideDaysOutsideOfMonth?: boolean;
  enabledStartDate?: Moment;
  enabledEndDate?: Moment;
  enableWeekends?: Moment;
  renderOnHover?: any;
  style?: any;
}

export interface HighlightDay {
  date: Moment;
  color?: 'blue' | 'yellow' | 'grey' | 'lilac'
  am: boolean;
  pm: boolean;
}

export default function Calendar(props: CalendarProps) {
  const {hideActiveDayHighlight, hideFlicker, hideDaysOutsideOfMonth, initialDate, activeDate, enableWeekends} = props;
  // const date = (props.initialDate ?? moment()).clone();
  const highlightedDays = props.highlightedDays ?? [];
  const [viewingDate, setViewingDate] = useState(moment());
  const firstDayOfMonth = (viewingDate).clone().startOf('month');
  const startOfCalendar = firstDayOfMonth.clone().isoWeekday('monday');
  const {t} = useTranslation();
  const weeks = [];

  useEffect(() => {
    if (initialDate) {
      setViewingDate((initialDate).clone());
    }
    // if (date.diff(viewingDate, 'days', false) > 0 || date.diff(viewingDate, 'days', false) < 0) {
    // }
  }, [initialDate]);

  for (let i = 0; i < WEEKS; i++) {
    const startOfWeek = startOfCalendar.clone().add(i, 'weeks');
    const week = [];

    week.push(startOfWeek.clone().add(0, 'days'));
    week.push(startOfWeek.clone().add(1, 'days'));
    week.push(startOfWeek.clone().add(2, 'days'));
    week.push(startOfWeek.clone().add(3, 'days'));
    week.push(startOfWeek.clone().add(4, 'days'));
    week.push(startOfWeek.clone().add(5, 'days'));
    week.push(startOfWeek.clone().add(6, 'days'));

    weeks.push(week);
  }
  const isInCurrentMonth = (d: Moment) => {
    return d.isSame(viewingDate, 'months');
  }

  const isActiveDay = (d: Moment) => {
    if (!activeDate) return false;
    return d.isSame(activeDate, 'days');
  }

  const isActiveEndDay = (d: Moment) => {
    return props.endDate && d.isSame(props.endDate, 'days');
  }

  const isActiveWeek = (d: Moment) => {
    if (props.enabledDateRange || props.hideActiveDayHighlight) return false;
    return d.week() === viewingDate.week();
  }

  const setDate = (moment: Moment) => {
    if (props.onDateSelect) props.onDateSelect(moment);
  }

  const changeMonth = (num: number) => {
    setViewingDate(viewingDate.clone().add(num, 'month'))
  }

  const isAmOnlyHighlighted = (day: Moment) => {
    const highlightedDay = highlightedDays.find((hd: HighlightDay) => hd.date.isSame(day, 'days'));
    return highlightedDay?.am && !highlightedDay?.pm;
  }

  const isPmOnlyHighlighted = (day: Moment) => {
    const highlightedDay = highlightedDays.find((hd: HighlightDay) => hd.date.isSame(day, 'days'));
    return !highlightedDay?.am && highlightedDay?.pm;
  }

  const getHighlightedDayClass = (day: Moment) => {
    var highlightedDay = highlightedDays.find((hd: HighlightDay) => hd.date.isSame(day, 'days'));

    if (highlightedDay) {
      const colorClass = `calendar__day--highlight--${highlightedDay.color ?? 'blue'}`
      const side = highlightedDay.am && highlightedDay.pm ? 'full' : highlightedDay.am ? 'top' : 'bottom';
      const sideClass = `calendar__day--highlight--${side}`
      return `calendar__day--highlight ${colorClass} ${sideClass} `;
    } else {
      return '';
    }
  }

  const isDateOutsideOfEnabledDateRange = (day: Moment) => {
    if (props.enabledStartDate && props.enabledEndDate) {
      return day.isBefore(props.enabledStartDate) || day.isAfter(props.enabledEndDate);
    }
    return false;
  }

  const isWeekendDayDisabled = (day: Moment) => {
    if (enableWeekends) return false;
    return day.isoWeekday() === 6 || day.isoWeekday() === 7;
  }

  const hideDay = (day: Moment) => hideDaysOutsideOfMonth && day.month() !== viewingDate.month();

  const getDayClasses = (day: Moment) => {
    const a = isInCurrentMonth(day) ? '' : 'calendar__day--disabled ';
    const b = isWeekendDayDisabled(day) ? 'calendar__day--disabled ' : '';
    const c = isActiveDay(day) && !hideActiveDayHighlight ? 'calendar__day--active ' : '';
    const d = isActiveEndDay(day) && !hideActiveDayHighlight ? 'calendar__day--end ' : '';
    const e = getHighlightedDayClass(day);
    const f = hideDay(day) ? 'calendar__day--hidden ' : '';
    const g = isDateOutsideOfEnabledDateRange(day) ? 'calendar__day--disabled ' : '';

    return ('calendar__day ' + a + b + c + d + e + f + g).trim();
  }

  return (
    <div style={props?.style ?? {}} className={`calendar ${props.stretch ? 'calendar--fullWidth' : ''} ${highlightedDays.length > 0 ? 'calendar--showHighlightedRange' : ''} ${props.className || ''}`.trim()}>
      <div className="calendar__title">
        <BodyRegular weight={600}>{viewingDate.format('MMMM YYYY')}</BodyRegular>

        {!hideFlicker && <div className="calendar__controls">
          <Icon className="calendar__control" icon={IconTypes.Previous} circle={true} onClick={() => changeMonth(-1)}/>
          <Icon className="calendar__control" icon={IconTypes.Next} circle={true} onClick={() => changeMonth(1)}/>
        </div>}

      </div>
      <div className={"calendar__rowsContainer"}>
        <div className="calendar__header calendar__week">
          <BodyXXSmall className="calendar__day--header">{t('calendar.M')}</BodyXXSmall>
          <BodyXXSmall className="calendar__day--header">{t('calendar.T')}</BodyXXSmall>
          <BodyXXSmall className="calendar__day--header">{t('calendar.W')}</BodyXXSmall>
          <BodyXXSmall className="calendar__day--header">{t('calendar.T')}</BodyXXSmall>
          <BodyXXSmall className="calendar__day--header">{t('calendar.F')}</BodyXXSmall>
          <BodyXXSmall className="calendar__day--header">{t('calendar.S')}</BodyXXSmall>
          <BodyXXSmall className="calendar__day--header">{t('calendar.S')}</BodyXXSmall>
        </div>
        {weeks.map((week, key) => (
          <div key={key} className={`calendar__week ${(isActiveWeek(week[0])) ? 'calendar__week--active' : ''}`}>
            {week.map((day, keyDay) => (
              hideDay(day) ? <BodyXXSmall className={getDayClasses(day)} key={keyDay}>&nbsp;</BodyXXSmall> :
                <CalendarDay className={getDayClasses(day)} key={keyDay}
                             keyDay={keyDay} setDate={setDate} day={day}
                             renderOnHover={props.renderOnHover}
                             isAmOnlyHighlighted={isAmOnlyHighlighted(day)}
                             isPmOnlyHighlighted={isPmOnlyHighlighted(day)} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

function CalendarDay(props: any) {
  const {className, setDate, day, renderOnHover, keyDay, isAmOnlyHighlighted, isPmOnlyHighlighted} = props;
  const [hovering, setHovering] = useState(false);

  const onClick = () => {
    setDate(day);
    setHovering(true);
  }

  return <div className={"calendarDay"}>
    <div className={className}
         onMouseOver={() => setHovering(true)}
         onTouchStart={() => setHovering(true)}
         onMouseLeave={() => setHovering(false)}
         onTouchEnd={() => setHovering(false)}
         onTouchCancel={() => setHovering(false)}
         onClick={onClick}>
      <BodyXXSmall key={keyDay}>
        {day.format('D')}
      </BodyXXSmall>
      {isAmOnlyHighlighted && <><div className="calendar__day--background--am"/></>}
      {isPmOnlyHighlighted && <><div className="calendar__day--background--pm"/></>}
    </div>
    {hovering && day && renderOnHover && renderOnHover(day)}
  </div>
}
