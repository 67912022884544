import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { selectCalendarDate } from "../../../store/ducks/dashboard.duck";
import { DialogIdentifiers, openDialogWithPayload } from "../../../store/ducks/dialog.duck";
import IconButton from "./icon-button/IconButton";
import { IconTypes } from "../atoms/icon/Icon";
import styled from "styled-components/macro";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import Colours from "../atoms/Colours";

export function ShowWeekNotesButton(props: Props) {
  const dispatch = useDispatch();
  const calendarDate = useSelector(selectCalendarDate);
  const user = useSelector(selectCurrentUser);

  const onClick = () => {
    dispatch(openDialogWithPayload({
      payload: { date: calendarDate, teamIds: [props.teamId] },
      activeDialog: DialogIdentifiers.WeeklyNotes
    }))
  }

  if (!user?.companyEntity.weeklyNotesEnabled) {
    return null;
  }

  return (
    <Wrapper>
      <IconButton text={"week-notes.week-notes"}
                  style={{...props?.style ?? {}}}
                  onClick={onClick}
                  icon={IconTypes.Email} />
      {props.hasWeekNotesToView && <AvailableCircle />}
    </Wrapper>
  )
}

interface Props {
  style?: any;
  teamId: string;
  hasWeekNotesToView?: boolean;
}

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  padding: 2px 12px;
  margin-right: -12px;
  border-radius: 8px;
  i {
    font-size: 26px;
    width: unset;
  }
  &:hover {
    background-color: ${Colours.veryLightGrey};
  }
`

const AvailableCircle = styled.div`
  border-radius: 100%;
  width: 12px;
  height: 12px;
  background-color: ${Colours.darkBlue};
  top: 4px;
  right: 116px;
  position: absolute;
`
