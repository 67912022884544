import React from 'react';
import { Pill } from "./Pill";
import Colours from "./Colours";
import { useTranslation } from "react-i18next";
import { BodyVerySmallWrapper } from "../../pages/holidays-v2/approvals/components/ApprovalLineStructure";

export function HolidayDaysPill(props: Props) {
  const {t} = useTranslation();
  return (
    <Pill colour={Colours.darkGreen} backgroundColour={Colours.green2} style={props?.style ?? {}}>
      <BodyVerySmallWrapper weight={600}>{t('holidays.holiday-duration', {duration: props.length})}</BodyVerySmallWrapper>
    </Pill>
  )
}

interface Props {
  length: number;
  style?: any;
}
