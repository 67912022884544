import React, { useMemo, useState } from 'react';
import { Moment } from "moment";
import { FloatingUserCell } from "./FloatingUserCell";
import {
  BorderDayCell,
  ROW_HEIGHT,
  USER_ROW_HEIGHT_MOBILE,
  WallPlannerRow,
  WEEK_WIDTH,
  WeekCell
} from '../WallPlannerStructuralComponents';
import styled from "styled-components/macro";
import { UserAvatarAndName } from "../../../UI/molecules/UserAvatarAndName";
import { BasicUserInfo, Birthday, Permission } from "../../../../models/user.models";
import { useDispatch, useSelector } from "react-redux";
import { BodyVerySmall } from "../../../UI/atoms/fonts/Body";
import { redirectTo, selectCurrentUser, selectIconPack } from "../../../../store/ducks/auth.duck";
import { smallTablet } from "../../../UI/atoms/MediaQueries";
import { HolidayBlock } from "./HolidayBlock";
import { WithWeeksInCurrentCalendarMonth } from "../../../../hooks/WithWeekInCurrentCalendarMonth";
import { Period } from "../../../../models/movements.models";
import { CompanyHoliday } from "../../../../services/CompanyHolidayService";
import { CompanyHolidayBlock } from "./CompanyHolidayBlock";
import { HolidayUsagePopup } from "../../holidays-v2/components/HolidayUsagePopup";
import { CheckLineReport } from "../../../../hooks/management-structure/CheckLineReport";
import { IconTypes } from "../../../UI/atoms/icon/Icon";
import { FloatingBirthdayIcon } from "./FloatingBirthdayIcon";
import { CustomLeaveDays, NonWorkingDays } from "../../../../services/HolidayService";
import { NonWorkingDayBlock } from "./NonWorkingDayBlock";
import { CustomLeaveBlock } from "./CustomLeaveBlock";
import { ActivityBlockV2 } from "./WallPlannerActivityBlocksV2";

export interface WallPlannerActivity {
  startDate: Moment;
  endDate: Moment;
}

interface Props {
  user?: BasicUserInfo;
  holidays?: WallPlannerActivity[];
  working?: WallPlannerActivity[];
  companyHolidays?: CompanyHoliday[];
  nonWorkingDays?: NonWorkingDays[];
  customLeaveDays?: CustomLeaveDays[];
  children?: any;
  id?: string;
}

export function WallPlannerUserRow(props: Props) {
  const {user, holidays, working, children,
    companyHolidays, nonWorkingDays, customLeaveDays} = props;
  const [popupVisible, setPopupVisible] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const iconPack = useSelector(selectIconPack);
  const weeksInMonth = WithWeeksInCurrentCalendarMonth();
  const dispatch = useDispatch();
  const holidayBookingEnabled = currentUser?.companyEntity.enableHolidayBooking;

  const isLineReport = CheckLineReport(currentUser, user?.userId);

  const birthday: Birthday | undefined = (currentUser?.companyEntity.showBirthdays && user?.birthdayDay && user.birthdayMonth) ? {day: user.birthdayDay, month: user.birthdayMonth} : undefined

  const canUserViewPopup = useMemo(() => {
    if (!window.location.href.includes('/holidays')) {
      return false;
    }

    if (!holidayBookingEnabled) {
      return false;
    }

    if (currentUser?.role === Permission.CompanyAdmin) {
      return true;
    }

    if (currentUser?.role === Permission.TeamAdmin && currentUser.teamEntity !== null && currentUser.teamEntity.id === user?.teamId) {
      return true;
    }

    return isLineReport;
  }, [holidayBookingEnabled, currentUser?.role, currentUser?.teamEntity, user?.teamId, isLineReport]);

  const onUserAvatarClicked = () => {
    if (user && canUserViewPopup) {
      window.history.pushState({}, 'Leave planner', window.location.pathname)
      dispatch(redirectTo(`/holidays/${user.userId}`))
    }
  }

  const firstDay = weeksInMonth[0];

  if (!weeksInMonth || weeksInMonth.length === 0) {
    return null;
  }

  return (
    <WallPlannerUserRowContainer weekCount={weeksInMonth.length} id={props?.id ?? ''}>
      <FloatingUserCell>
        {children && <>
            {children}
        </>}
        {user && !children && <UserAvatarAndName colour={currentUser?.id === user.userId ? 'blue' : 'darkBlue'}
                                                 onClick={canUserViewPopup ? onUserAvatarClicked : undefined}
                                                 onHoverIcon={canUserViewPopup ? IconTypes.Holiday : undefined}
                                                 mouseOver={() => setPopupVisible(true)}
                                                 mouseLeave={() => setPopupVisible(false)}
                                                 firstName={user.firstName}
                                                 lastName={user.lastName}/>}

        {holidayBookingEnabled && canUserViewPopup && popupVisible && user && <HolidayUsagePopup user={user} date={firstDay} />}

      </FloatingUserCell>
      {weeksInMonth.map((timeFrame, k) => (
        <WeekCell key={k}>
          <BorderDayCell/>
          <BorderDayCell/>
          <BorderDayCell/>
          <BorderDayCell/>
          <BorderDayCell/>
          <BorderDayCell weekend={true}/>
          <BorderDayCell weekend={true}/>
        </WeekCell>
      ))}

      <UserActivitiesContainer>
        {working?.map((activity: any, key) => (
          <React.Fragment key={key}>
            {(activity?.amOption?.key !== activity?.pmOption?.key) ? <HalfDayColumn>
                <ActivityBlockV2 iconPack={iconPack} style={{top: 0, height: 14}} whereabouts={activity} sideOfDay={Period.AM} firstDay={firstDay} birthday={birthday} />
                <ActivityBlockV2 iconPack={iconPack} style={{top: 18, height: 14}} whereabouts={activity} sideOfDay={Period.PM} firstDay={firstDay} birthday={birthday} />
              </HalfDayColumn> :
              <ActivityBlockV2 iconPack={iconPack}
                               whereabouts={activity}
                               firstDay={firstDay}
                               sideOfDay={Period.AllDay}
                               birthday={birthday} />
            }
          </React.Fragment>
        ))}

        {holidays?.map((activity: any, key) => (
          <HolidayBlock activity={activity} key={key}
                        firstDateOnCalendar={firstDay}
                        weeksInMonth={weeksInMonth.length}
                        user={props.user}
                        birthday={birthday}>
            <BodyVerySmall weight={600}>{activity?.name ?? ''}</BodyVerySmall>
          </HolidayBlock>
        ))}

        {nonWorkingDays?.map((activity: any, key) => (
          <NonWorkingDayBlock activity={activity} key={key}
                        firstDateOnCalendar={firstDay}
                        weeksInMonth={weeksInMonth.length}
                        birthday={birthday} />
        ))}

        {customLeaveDays?.map((activity: any, key) => (
          <CustomLeaveBlock activity={activity} key={key}
                            firstDateOnCalendar={firstDay}
                            weeksInMonth={weeksInMonth.length}
                            birthday={birthday} />
        ))}

        {companyHolidays?.map((activity: any, key) => (
          <CompanyHolidayBlock activity={activity} key={key}
                               firstDateOnCalendar={firstDay}
                               weeksInMonth={weeksInMonth.length}
                               birthday={birthday}>
            <BodyVerySmall weight={600}>{activity?.name ?? ''}</BodyVerySmall>
          </CompanyHolidayBlock>
        ))}
      </UserActivitiesContainer>

      {birthday && <FloatingBirthdayIcon birthday={birthday} monthStartDate={firstDay} weeksInMonth={weeksInMonth.length} />}

    </WallPlannerUserRowContainer>
  )
}


export const WallPlannerUserRowContainer = styled<any>(WallPlannerRow)<any>`
  height: ${USER_ROW_HEIGHT_MOBILE}px;
  width: 100%;
  @media (${smallTablet}) {
    height: ${ROW_HEIGHT}px;
    left: 0;
  }
`

export const UserActivitiesContainer = styled.div`
  position: absolute;
  left: -${WEEK_WIDTH}px;
  top: 40px;
  @media (${smallTablet}) {
    left: 0;
    top: 12px;
  }
`

export const HalfDayColumn = styled.div`
  display: flex;
  flex-direction: column;
`
