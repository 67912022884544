import React, { useMemo, useState } from 'react';
import Dialog from "../../../../UI/molecules/Dialog";
import { closeDialog } from "../../../../../store/ducks/dialog.duck";
import DialogHeader from "../../../../dialogs/DialogHeader";
import { BodyLarge } from "../../../../UI/atoms/fonts/Body";
import { useDispatch, useSelector } from "react-redux";
import { GroupedApprovalRequest } from "../../../../../models/approval-requests.models";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";
import styled from "styled-components/macro";
import Colours from "../../../../UI/atoms/Colours";
import { selectAllWhereaboutsOptions } from "../../../../../store/ducks/whereaboutsOptions.duck";
import { WhereaboutsHeaderImage } from "../../components/WhereaboutsHeaderImage";
import { iconPackPath } from "../../../../../utils/WhereaboutsHelper";
import { FreeBusyStatus } from "../../../../../services/WhereaboutOptions";
import { selectIconPack } from "../../../../../store/ducks/auth.duck";
import moment from "moment";
import { PRETTY_DATE_FORMAT } from "../../../../../utils/DateUtils";
import { LoadingSpinner } from "../../../../UI/atoms/LoadingSpinner";
import { selectApprovals, setApprovals } from "../../../../../store/ducks/approvalRequests.duck";
import { cancelApproval } from "../../../../../services/ApprovalRequests";

export function CancelApprovedLeaveDialog(props: Props) {
  const allWhereaboutsOptions = useSelector(selectAllWhereaboutsOptions);
  const iconPack = useSelector(selectIconPack);
  const {approval} = props.payload;
  const dispatch = useDispatch();
  const approvalsList = useSelector(selectApprovals);
  const [loading, setLoading] = useState(false);

  const amWhereabouts = useMemo(() => {
    return allWhereaboutsOptions.find(wo => wo.id === approval.amWhereaboutsId);
  }, [allWhereaboutsOptions, approval]);

  const pmWhereabouts = useMemo(() => {
    return allWhereaboutsOptions.find(wo => wo.id === approval.pmWhereaboutsId);
  }, [allWhereaboutsOptions, approval]);

  const whereaboutsImageUrl = useMemo(() => {
    const image = amWhereabouts?.image ?? pmWhereabouts?.image ?? undefined;
    if (image) {
      return iconPackPath(iconPack, image, FreeBusyStatus.FREE);
    }
  }, [amWhereabouts?.image, iconPack, pmWhereabouts?.image]);

  const whereaboutsLabel = useMemo(() => {
    return amWhereabouts?.label ?? pmWhereabouts?.label ?? 'Error!';
  }, [amWhereabouts?.label, pmWhereabouts?.label]);

  const confirmCancel = async () => {
    setLoading(true);
    try {
      await cancelApproval(approval);
      const filteredApprovalsList = approvalsList.filter(a => a !== approval);
      dispatch(setApprovals(filteredApprovalsList));
      dispatch(closeDialog());
    } finally {
      setLoading(false);
    }
  }

  const close = () => {
    dispatch(closeDialog());
  }

  return (
    <DialogWrapper>
      <Dialog isOpen={true} onClose={() => dispatch(closeDialog())}>
        <DialogContent>
          {loading && <LoadingSpinner hideText={true} hideBorder={false} fullScreen={true} />}
          {whereaboutsImageUrl && <WhereaboutsHeaderImage imageUrl={whereaboutsImageUrl} />}

          <DialogHeader title="Cancel Approved Leave?"/>

          <BodyLarge style={{marginTop: 16, marginBottom: 0}} weight={600}>{whereaboutsLabel}</BodyLarge>
          <BodyLarge style={{marginBottom: 32}} weight={400} colour={Colours.darkGrey}>{moment(approval.fromDate).format(PRETTY_DATE_FORMAT)}</BodyLarge>

          <OutlineButton className={'cancelApproval__yes'}
                         click={confirmCancel}
                         disabled={loading}
                         style={{marginBottom: 12}}
                         text="Yes, Cancel Leave"
                         fullWidth={false}
                         size="large"/>
          <OutlineButton className={'cancelApproval__no'}
                         disabled={loading}
                         click={close}
                         text="No, Don't Cancel Leave"
                         fullWidth={false}
                         size="large"/>
        </DialogContent>
      </Dialog>
    </DialogWrapper>
  )
}

interface Props {
  payload: Payload;
}

interface Payload {
  approval: GroupedApprovalRequest;
}

const DialogWrapper = styled.div`
  .dialog__content {
    width: 412px;
  }
`

const DialogContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 16px;
  .cancelApproval__yes, .cancelApproval__yes p {
    border-color: ${Colours.red};
    color: ${Colours.red};
  }
  .cancelApproval__no, .cancelApproval__no p {
    border-color: ${Colours.darkGrey};
    color: ${Colours.darkGrey};
  }
`
