import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import { SpaceBetweenRow } from "../../settings/StyleComponents";
import Colours from "../../../UI/atoms/Colours";
import { useSelector } from "react-redux";
import { CustomLeaveDays } from "../../../../services/HolidayService";
import { selectAllWhereaboutsOptions } from "../../../../store/ducks/whereaboutsOptions.duck";
import { iconPackPath } from "../../../../utils/WhereaboutsHelper";
import { FreeBusyStatus } from "../../../../services/WhereaboutOptions";
import { selectIconPack } from "../../../../store/ducks/auth.duck";
import { SideOfDay } from "../../../../models/movements.models";
import Icon, { IconTypes } from "../../../UI/atoms/icon/Icon";

export enum PopupPosition {
  Left, Right, None,
}

export function CustomLeaveInfoPopup(props: Props) {
  const {customLeaveDay} = props;
  const customLeaveList = useSelector(selectAllWhereaboutsOptions);
  const iconPack = useSelector(selectIconPack);
  const popupPositioning = props.popupPositioning ?? PopupPosition.Left;

  const amCustomLeave = useMemo(() => {
    return customLeaveList.find(cl => cl.id === customLeaveDay.amWhereaboutsId)
  }, [customLeaveDay.amWhereaboutsId, customLeaveList]);

  const pmCustomLeave = useMemo(() => {
    return customLeaveList.find(cl => cl.id === customLeaveDay.pmWhereaboutsId)
  }, [customLeaveDay.pmWhereaboutsId, customLeaveList]);

  const customLeaveForSideOfDay = useMemo(() => {
    if (props.sideOfDay === SideOfDay.AM || props.sideOfDay === SideOfDay.AllDay) {
      return amCustomLeave
    } else {
      return pmCustomLeave
    }
  }, [amCustomLeave, pmCustomLeave, props.sideOfDay]);

  if (!amCustomLeave || !pmCustomLeave || !customLeaveForSideOfDay) {
    return null;
  }

  if (props.showBothSidesOfDayIfDifferent && customLeaveDay.amWhereaboutsId !== customLeaveDay.pmWhereaboutsId) {
    return (
      <HolidayInfoPopupWrapper className="popup" popupPositioning={popupPositioning}>
        <SpaceBetweenRow style={{alignItems: 'center', marginBottom: 4}}>
          <BodyRegular colour={Colours.darkGreen} weight={600}>Custom Leave</BodyRegular>
          <Icon icon={IconTypes.Calendar} />
        </SpaceBetweenRow>

        <BodyRegular style={{marginBottom: 2}}><strong>AM:</strong> {amCustomLeave.label}</BodyRegular>
        <BodyRegular style={{marginBottom: 2}}><strong>PM:</strong> {pmCustomLeave.label}</BodyRegular>

      </HolidayInfoPopupWrapper>
    )
  } else {
    return (
      <HolidayInfoPopupWrapper className="popup" popupPositioning={popupPositioning}>
        <SpaceBetweenRow style={{alignItems: 'center', marginBottom: 4}}>
          <BodyRegular colour={Colours.darkGreen} weight={600}>Custom Leave</BodyRegular>
          <LeaveImage src={iconPackPath(iconPack, customLeaveForSideOfDay.image, FreeBusyStatus.FREE)} alt={""} />
        </SpaceBetweenRow>

        <BodyRegular style={{marginBottom: 2}} weight={600}>{customLeaveForSideOfDay.label}</BodyRegular>

      </HolidayInfoPopupWrapper>
    )
  }
}

interface Props {
  customLeaveDay: CustomLeaveDays;
  popupPositioning?: PopupPosition;
  sideOfDay: SideOfDay;
  showBothSidesOfDayIfDifferent?: boolean;
}

const HolidayInfoPopupWrapper = styled.div<any>`
  display: none;
  flex-direction: column;
  max-width: 448px;
  min-width: 300px;
  min-height: 50px;
  padding: 24px;
  position: absolute;
  top: 30px;
  left: ${props => props.popupPositioning === PopupPosition.Left ? 0 : 'auto'};
  right: ${props => props.popupPositioning === PopupPosition.Right ? 0 : 'auto'};
  z-index: 999999;
  background: #FFFFFF;
  box-shadow: 0 2px 8px 2px rgba(76, 109, 172, 0.15);
  border-radius: 24px;
  cursor: default;
  p {
    max-width: 100%;
  }
`
const LeaveImage = styled.img`
  max-width: 40px;
  max-height: 40px;
`
