import React from 'react';
import { BodyRegular } from "../../../../../UI/atoms/fonts/Body";
import { FlatContentCard } from "../../../../../UI/atoms/FlatContentCard";
import styled from "styled-components/macro";

export function DefaultWhereaboutsOptionsContentCard(props: Props) {
  return (
    <ContentCard>
      <BodyRegular style={{marginBottom: 16}}><strong>Working from home</strong>, <strong>holiday</strong> and <strong>non working days</strong> are system defaults. Add <strong>further</strong> custom whereabouts above.</BodyRegular>
      <WhereaboutsDemoImage src={"/assets/images/whereabouts-demo.svg"} alt={"whereabouts"} />
    </ContentCard>
  )
}

interface Props {
}

const WhereaboutsDemoImage = styled.img`
`
const ContentCard = styled<any>(FlatContentCard)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`
