import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import Colours from "../atoms/Colours";
import { UserAvatarAndName } from "./UserAvatarAndName";
import { BodyRegular, BodySmall } from "../atoms/fonts/Body";
import { useDispatch, useSelector } from "react-redux";
import { DialogIdentifiers, openDialogWithPayload } from "../../../store/ducks/dialog.duck";
import { FreeBusyStatus, WhereaboutsOption } from "../../../services/WhereaboutOptions";
import { selectAllWhereaboutsOptions } from "../../../store/ducks/whereaboutsOptions.duck";
import { selectIconPack } from "../../../store/ducks/auth.duck";
import { iconPackPath } from "../../../utils/WhereaboutsHelper";

export function UserInfoAndLeaveTypeBox(props: Props) {
  const dispatch = useDispatch();
  const whereaboutsOptions = useSelector(selectAllWhereaboutsOptions);
  const iconPack = useSelector(selectIconPack);
  const {firstName, lastName, userId, teamName} = props;

  const selectedLeaveOption = useMemo(() => {
    return whereaboutsOptions.find(wo => wo.id === props.selectedLeaveType)
  }, [whereaboutsOptions, props.selectedLeaveType]);

  const onLeaveTypeSelected = (wo: WhereaboutsOption) => {
    if (props.onLeaveTypeSelected) {
      props.onLeaveTypeSelected(wo);
    }
  }

  const onChangeLeaveTypeClicked = () => {
    dispatch(openDialogWithPayload({
      payload: { onSelect: onLeaveTypeSelected },
      activeDialog: DialogIdentifiers.SelectLeaveTypeDialog
    }))
  }

  if (!firstName || !lastName || !userId) {
    return null;
  }

  const showLeaveType = props.selectedLeaveType && selectedLeaveOption;
  const showLeaveTypeSelector = showLeaveType && props.onLeaveTypeSelected;

  return (
    <Wrapper style={props?.style ?? {}}>
      <ContentContainer showLeaveType={showLeaveType}>
        <UserAvatarAndName hideName={true} firstName={firstName} lastName={lastName} colour={'blue'} />
        <DetailsList>
          <BodyRegular>{`${firstName} ${lastName}`}</BodyRegular>
          {teamName && <BodySmall colour={Colours.darkGrey}>{teamName}</BodySmall>}
          {!!props.children && props.children}
        </DetailsList>
      </ContentContainer>
      {showLeaveType && <>
        <ContentContainer showLeaveType={showLeaveType}>
          <HolidayImg src={iconPackPath(iconPack, selectedLeaveOption.image, FreeBusyStatus.BUSY)} />
          <DetailsList>
            <BodyRegular weight={600} colour={Colours.darkGrey}>{selectedLeaveOption?.label ?? 'Holiday'}</BodyRegular>
            {showLeaveTypeSelector && <ChangeLeaveType onClick={onChangeLeaveTypeClicked}>Change leave type</ChangeLeaveType>}
          </DetailsList>
        </ContentContainer>
      </>}
    </Wrapper>
  )
}

interface Props {
  firstName?: string;
  lastName?: string;
  teamName?: string;
  userId?: string;
  children?: any;
  style?: any;
  selectedLeaveType?: number;
  onLeaveTypeSelected?: (wo: WhereaboutsOption) => any;
}

const HolidayImg = styled.img`
  width: 40px;
  height: 40px;
`

const Wrapper = styled.div`
  position: relative;
  justify-self: center;
  align-self: center;
  border: solid 1px ${Colours.blue};
  display: flex;
  padding: 24px;
  border-radius: 8px;
  min-width: 100%;
  .userAvatarAndName {
    align-items: unset;
  }
`

const DetailsList = styled.div`
  padding-left: 12px;
  flex: 1;
`

const ContentContainer = styled.div<any>`
  display: flex;
  width: ${props => props.showLeaveType ? '50%' : '100%'};
  &:first-child {
    border-right: ${props => props.showLeaveType ? `1px solid ${Colours.blue}` : 'none'};
  }
  &:last-child {
    padding-left: ${props => props.showLeaveType ? '32px' : '0'};
  }
`

const ChangeLeaveType = styled<any>(BodyRegular)`
  color: var(--Blue, #0057FF);
  font-family: Soleil;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  cursor: pointer;
`
