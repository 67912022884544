import React from 'react';
import { closeDialog } from "../../../store/ducks/dialog.duck";
import { microsoftRequest } from "../../../services/EnvironmentVariables";
import { useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { setAuthLoading } from "../../../store/ducks/auth.duck";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { emitRegisterEvent } from "../../../services/RegisterService";
import { getParameterByName } from "../../../utils/UrlUtils";

export function RegisterWithMicrosoftButton(props: Props) {
  const { agreeToTnC, onError, informMe } = props;
  const dispatch = useDispatch();
  const msal = useMsal();

  const continueToRegister = async () => {
    if (!agreeToTnC) {
      onError('Please accept the terms and conditions to continue.')
      return;
    }
    dispatch(closeDialog());

    localStorage.setItem('informMe', (!!informMe).toString());

    const ad = getParameterByName('ad', window.location.href);
    if (ad) {
      localStorage.setItem('ad', ad)
    }

    // @ts-ignore
    if (window.gtag_report_conversion) {
      // @ts-ignore
      window.gtag_report_conversion();
    }

    try {
      emitRegisterEvent();
      dispatch(setAuthLoading(true));
      await msal.instance.loginRedirect({
        ...microsoftRequest.register,
        prompt: 'select_account',
      });
    } catch (e: any) {
      console.error(e);
      dispatch(setAuthLoading(false));
    }
  }

  return (
    <>
      <PrimaryButton click={continueToRegister} fullWidth={true} size="large" text="button.continue-to-signup"/>
    </>
  )
}

interface Props {
  agreeToTnC: boolean;
  onError: (error: string) => void;
  informMe: boolean;
}
