import React from 'react';
import { FlatContentCard } from "../../../../../UI/atoms/FlatContentCard";
import styled from "styled-components/macro";
import { BasicMovementIcon } from "../../../../../UI/atoms/BasicMovementIcon";
import { useSelector } from "react-redux";
import { selectIconPack } from "../../../../../../store/ducks/auth.duck";
import { OFFICE_OPTION } from "../../../../../../services/WhereaboutOptions";
import { BodyRegular, BodySmall } from "../../../../../UI/atoms/fonts/Body";
import { OutlineButton } from "../../../../../UI/atoms/buttons/OutlineButton";

export function CreatingOfficeContentCard(props: Props) {
  const iconPack = useSelector(selectIconPack)
  return (
    <Container style={props?.style ?? {}}>
      <ColumnLeft>
        <BasicMovementIcon onClick={() => {}}
                           option={OFFICE_OPTION}
                           iconPack={iconPack} />
      </ColumnLeft>
      <Column>
        <BodyRegular weight={600}>Creating office locations?</BodyRegular>
        <BodySmall style={{marginBottom: 12}}>Create and manage multiple offices in the office management area instead for accurate reporting and office counts.</BodySmall>
        <OutlineButton link={'/manage/hot-desking/add'} size={'small'} style={{width: 150}} text={'button.setup-offices'} />
      </Column>
    </Container>
  )
}

interface Props {
  style?: any;
}

const Container = styled<any>(FlatContentCard)`
  display: flex;
  margin-bottom: 32px;
`

const ColumnLeft = styled.div`
  min-width: 100px;
 `

const Column = styled.div`
  padding-left: 24px;
 `
