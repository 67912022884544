import { DAY_WIDTH, WEEK_WIDTH } from '../WallPlannerStructuralComponents';
import styled, { css } from "styled-components/macro";
import Colours from "../../../UI/atoms/Colours";

export const ActivityFill = styled.div<any>`
  position: absolute;
  height: 32px;
  align-self: center;
  border-radius: 4px;
  transition: background .25s;
  display: flex;
  align-items: center;
  padding-left: 4px;
  user-select: none;
  ${props => css`left: ${props.start ? ((props.start * (DAY_WIDTH) + 2) + WEEK_WIDTH + (props.startsWithHalfDay ? DAY_WIDTH * 0.5 : 0)) + 'px' : (WEEK_WIDTH + 2) + 'px'};`};
  ${props => css`width: ${props.days ? (props.days * (DAY_WIDTH) - 6 - ((props.startsWithHalfDay || props.endsWithHalfDay) ? DAY_WIDTH * 0.5 : 0)) + 'px' : 0};`};
  .whereabouts__outlineIcon {
    max-height: 80% !important;
  }
`

export const HolidayActivityFill = styled<any>(ActivityFill)<any>`
  background: ${props => !!props.fill ? props.fill : Colours.veryLightGrey};
  color: ${props => !!props.textColour ? props.textColour : Colours.black};
  border: 1px solid ${props => !!props.outline ? props.outline : '#A6B4B4'};
  transition: border .1s ease-in-out;
  cursor: pointer;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .icon {
    color: ${props => !!props.textColour ? props.textColour : Colours.black} !important;
  }
  &:hover, &:active {
    border: 1px solid ${Colours.blue};
    .popup {
      display: flex;
    }
  }
`
