import React from 'react';
import styled from "styled-components/macro";
import { Pill } from "../../../../../UI/atoms/Pill";
import { BodyVerySmall } from "../../../../../UI/atoms/fonts/Body";
import { IconFilter } from "../../../../../dialogs/custom-whereabouts-dialog/WhereaboutsImagePicker";
import Colours from "../../../../../UI/atoms/Colours";

export function IconFilterRow(props: Props) {
  const onPillClick = (iconFilter: IconFilter) => {
    if (props.disabled) {
      return;
    }
    props.onSelect(iconFilter);
  }
  // @ts-ignore
  const isAllSelected = !props.selected || props.selected === IconFilter.ALL;
  return (
    <Row>
      <IconPill onClick={() => onPillClick(IconFilter.ALL)} selected={isAllSelected}>
        <BodyVerySmall>All Icons</BodyVerySmall>
      </IconPill>
      <IconPill onClick={() => onPillClick(IconFilter.LOCATION)} selected={props.selected === IconFilter.LOCATION}>
        <BodyVerySmall>Location Icons</BodyVerySmall>
      </IconPill>
      <IconPill onClick={() => onPillClick(IconFilter.TRAVEL)} selected={props.selected === IconFilter.TRAVEL}>
        <BodyVerySmall>Travel Icons</BodyVerySmall>
      </IconPill>
      <IconPill onClick={() => onPillClick(IconFilter.LEAVE)} selected={props.selected === IconFilter.LEAVE}>
        <BodyVerySmall>Leave Icons</BodyVerySmall>
      </IconPill>
      <IconPill onClick={() => onPillClick(IconFilter.OTHER)} selected={props.selected === IconFilter.OTHER}>
        <BodyVerySmall>Other</BodyVerySmall>
      </IconPill>
    </Row>
  )
}

interface Props {
  onSelect: (iconFilter: IconFilter) => any;
  selected?: IconFilter;
  disabled: boolean;
}

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  & > * {
    margin-right: 8px;
  }
`

const IconPill = styled<any>(Pill)`
  cursor: pointer;
  border: 1px solid ${props => props.selected ? Colours.blue : Colours.darkGrey};
  background-color: ${props => props.selected ? '#E6FCFF' : Colours.veryLightGrey};
  padding: 4px 12px;
  p {
    color: ${props => props.selected ? Colours.blue : Colours.darkGrey};
  }
`
