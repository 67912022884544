import React, { useMemo } from 'react';
import { ApprovalState, ApprovalType, GroupedApprovalRequest } from "../../../../../models/approval-requests.models";
import { Column, Row } from "../../../../UI/atoms/StructuralLayout";
import { UserAvatarAndName } from "../../../../UI/molecules/UserAvatarAndName";
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";
import moment from "moment";
import styled from "styled-components/macro";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";
import Colours from "../../../../UI/atoms/Colours";
import { Pill } from "../../../../UI/atoms/Pill";
import { HolidayDaysPill } from "../../../../UI/atoms/HolidayDaysPill";
import { desktop } from "../../../../UI/atoms/MediaQueries";
import { useDispatch, useSelector } from "react-redux";
import { approveRequest, declineRequest } from "../../../../../store/ducks/approvalRequests.duck";
import { useTranslation } from "react-i18next";
import { toAirBnbDate } from "../../../../../utils/DateUtils";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import { isCompanyAdminUser, isOfficeAdminUser } from "../../../../../utils/AccessControl";
import { ApprovalCustomWhereaboutsPill } from "./ApprovalCustomWhereaboutsPill";
import { ApprovalLineWrapper, BodyVerySmallWrapper } from "./ApprovalLineStructure";

function getAvatarColour(state: ApprovalState): 'blue'|'grey'|'darkBlue' {
  switch (state) {
    case ApprovalState.approved: return 'blue';
    case ApprovalState.declined: return 'grey';
    case ApprovalState.cancelled: return 'grey';
    default: return 'darkBlue';
  }
}

export function ApprovalLine(props: Props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const currentUser = useSelector(selectCurrentUser);

  const approval = props.groupedApproval;
  const formattedDate = useMemo(() => {
    const from = moment(approval.fromDate);
    const to = moment(approval.toDate);
    return toAirBnbDate(from, to);
  }, [approval]);

  const canUserApprove = useMemo(() => {
    if (isCompanyAdminUser(currentUser) || isOfficeAdminUser(currentUser)) {
      return true;
    }
    if (currentUser?.id !== approval.userId) {
      return true;
    }
    return false;
  }, [currentUser, approval]);

  const approve = () => {
    dispatch(approveRequest(approval));
  }

  const decline = () => {
    dispatch(declineRequest(approval));
  }

  return (
    <ApprovalLineWrapper state={approval.approvalState}>
      <Row>
        <UserAvatarAndName firstName={approval.firstName}
                           lastName={approval.lastName}
                           colour={getAvatarColour(approval.approvalState)}
                           hideName={true} />

        <Column className={'approvalLine__name'}>
          <BodyRegular weight={600}>{approval.firstName} {approval.lastName}</BodyRegular>
          <BodyRegular weight={400}>{formattedDate}</BodyRegular>
        </Column>
      </Row>

      <PillWrapper>
        {approval.approvalState === ApprovalState.pending && <>
          <HolidayDaysPill length={approval.duration} style={{marginRight: 16}} />
        </>}
        {approval.approvalType === ApprovalType.holiday && <>
          <Pill backgroundColour={Colours.blue02} style={{marginRight: 16}}>
            <BodyVerySmallWrapper weight={600}>Holiday</BodyVerySmallWrapper>
          </Pill>
        </>}
        {approval.approvalType === ApprovalType.customWhereabouts && <>
          <ApprovalCustomWhereaboutsPill approval={approval} />
        </>}
      </PillWrapper>


      <ApprovalsControl>
        {(approval.approvalState === ApprovalState.approved || approval.approvalState === ApprovalState.cancelled) && <>
          {approval.approvalState === ApprovalState.approved && <>
            <Pill colour={Colours.blue} backgroundColour={Colours.blue02} style={{marginRight: 16}}>
              <BodyVerySmallWrapper weight={600}>{t('approvals.approved')}</BodyVerySmallWrapper>
            </Pill>
            <Pill colour={Colours.blue} backgroundColour={Colours.blue02}>
              <BodyVerySmallWrapper weight={600}>{approval.duration} {t('approvals.days')}</BodyVerySmallWrapper>
            </Pill>
          </>}
          {approval.approvalState === ApprovalState.cancelled && <>
            <Pill backgroundColour={Colours.veryLightGrey} style={{marginRight: 16}}>
              <BodyVerySmallWrapper weight={600}>{t('approvals.cancelled')}</BodyVerySmallWrapper>
            </Pill>
            <Pill backgroundColour={Colours.veryLightGrey}>
              <BodyVerySmallWrapper weight={600}>{approval.duration} {t('approvals.days')}</BodyVerySmallWrapper>
            </Pill>
          </>}
        </>}
        {canUserApprove && approval.approvalState === ApprovalState.pending && <>
          <OutlineButton borderColour={Colours.darkGrey}
                         click={() => decline()}
                         text={'button.decline'}
                         size={'small'}
                         style={{marginRight: 16}} />
          <OutlineButton borderColour={Colours.blue}
                         click={() => approve()}
                         text={'button.approve'}
                         size={'small'} />
        </>}
        {approval.approvalState === ApprovalState.declined && <>
          <Pill colour={Colours.darkGrey} style={{marginLeft: 16}} backgroundColour={Colours.veryLightGrey}>
            <BodyVerySmallWrapper weight={600}>{t('approvals.declined')}</BodyVerySmallWrapper>
          </Pill>
        </>}
      </ApprovalsControl>
    </ApprovalLineWrapper>
  )
}

interface Props {
  groupedApproval: GroupedApprovalRequest;
}

const ApprovalsControl = styled.div`
  display: flex;
  margin-left: auto;
  margin-top: 8px;
`

const PillWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  @media (${desktop}) {
    align-self: center;
  }
`
