import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../store/ducks/auth.duck';
import { Redirect } from 'react-router';
import TextField from '../../UI/atoms/TextField';
import {
  register,
  selectIsLoading,
  selectIsSuccessful,
  selectRegisterErrorMessage,
  setError
} from '../../../store/ducks/registration.duck';
import AuthWrapper from './components/AuthWrapper';
import { BodyError, BodyRegular, BodySmall } from '../../UI/atoms/fonts/Body';
import { Checkbox } from "../../UI/atoms/Checkbox";
import ReactGA from "react-ga";
import { isInTeams } from "../../../utils/TeamsUtils";
import { failureNotification } from "../../../store/ducks/notification.duck";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaEnabled } from "../../../services/EnvironmentVariables";
import { IconTypes } from "../../UI/atoms/icon/Icon";
import { Trans, useTranslation } from "react-i18next";
import { emitRegisterEvent } from "../../../services/RegisterService";
import { getParameterByName } from "../../../utils/UrlUtils";


export default function RegisterWithTeamToday() {
  const currentUser = useSelector(selectCurrentUser);
  const isLoading = useSelector(selectIsLoading);
  const isSuccessful = useSelector(selectIsSuccessful);

  useEffect(() => {
    document.body.style.backgroundColor = '#FAFAFA';
    ReactGA.event({category: 'NewUser', action: 'Register with TeamToday'});
  }, []);

  if (currentUser) {
    return <Redirect to="/whereabouts/team"/>
  }

  if (isSuccessful) {
    return <Success/>
  }

  if (isLoading) {
    return <Processing/>
  }

  return <RegistrationForm/>
}

function RegistrationForm() {
  const dispatch = useDispatch();
  const errorMessage = useSelector(selectRegisterErrorMessage);
  const [firstName, setFirstName] = useState({value: '', error: ''});
  const [lastName, setLastName] = useState({value: '', error: ''});
  const [email, setEmail] = useState({value: '', error: ''});
  const [password, setPassword] = useState({value: '', error: ''});
  const [repeatPassword, setRepeatPassword] = useState({value: '', error: ''});
  const [recaptchaValue, setRecaptchaValue] = useState({value: '', error: ''});
  const [informMe, setInformMe] = useState(false);
  const [agreeToTnC, setAgreeToTnC] = useState(false);

  const adTag = useMemo(() => {
    const ad = getParameterByName('ad', window.location.href);
    if (ad) {
      return ad;
    }
    return ''
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (recaptchaEnabled && !recaptchaValue.value) {
      dispatch(failureNotification(`Please complete "i'm not a robot" check.`))
      return;
    }

    if (firstName.value.trim() === '') {
      setFirstName({...firstName, error: 'First name is required'});
      return;
    }

    if (lastName.value.trim() === '') {
      setLastName({...lastName, error: 'Last name is required'});
      return;
    }

    if (email.value.trim() === '') {
      setEmail({...email, error: 'Email is required'});
      return;
    }

    if (password.value.trim() === '') {
      setPassword({...password, error: 'Password is required'});
      return;
    }

    if (repeatPassword.value.trim() === '') {
      setRepeatPassword({...repeatPassword, error: 'Repeat pass is required'});
      return;
    }

    if (password.value !== repeatPassword.value) {
      setPassword({...password, error: 'Does not match'});
      setRepeatPassword({...repeatPassword, error: 'Does not match'});
      return;
    }

    if (!agreeToTnC) {
      dispatch(setError('Please agree to the terms and conditions.'))
      return;
    }

    if (!!firstName.error || !lastName.error || !email.error || !password.error || !repeatPassword.error) {
      emitRegisterEvent();
      dispatch(register({
        email: email.value.trim(),
        password: password.value,
        firstName: firstName.value.trim(),
        lastName: lastName.value.trim(),
        allowMarketingEmails: informMe,
        inTeams: isInTeams(),
        captchaResponse: recaptchaEnabled ? recaptchaValue.value : 'dev',
        ad: adTag
      }));

      // @ts-ignore
      if (window.gtag_report_conversion) {
        // @ts-ignore
        window.gtag_report_conversion();
      }
    }
  }

  return (
    <AuthWrapper dataTest="registerWithPassword" title="register.create-account-with-password">
      <script src="https://www.google.com/recaptcha/api.js" async defer></script>
      <BodyRegular><Trans i18nKey="register.best-experience-use-ms" /></BodyRegular>

      <BodyError className="login__error">{errorMessage}</BodyError>
      <form className="auth__form" onSubmit={(e) => e.preventDefault()}>
        <TextField label="textinput.firstname"
                   dataTest="firstNameInput"
                   onChange={(v: string) => setFirstName({...firstName, value: v, error: ''})}
                   value={firstName.value}
                   error={firstName.error}
                   enableAutoComplete={true}
                   type="text"/>

        <TextField label="textinput.lastname"
                   dataTest="lastNameInput"
                   onChange={(v: string) => setLastName({...lastName, value: v, error: ''})}
                   value={lastName.value}
                   enableAutoComplete={true}
                   error={lastName.error}
                   type="text"/>

        <TextField label="textinput.email"
                   dataTest="emailInput"
                   onChange={(v: string) => setEmail({...email, value: v, error: ''})}
                   value={email.value}
                   enableAutoComplete={true}
                   error={email.error}
                   type="email"/>

        <TextField label="textinput.password"
                   dataTest="passwordInput"
                   onChange={(v: string) => setPassword({...password, value: v, error: ''})}
                   value={password.value}
                   enableAutoComplete={true}
                   error={password.error}
                   type="password"/>

        <TextField label="textinput.repeat-password"
                   dataTest="repeatPasswordInput"
                   onChange={(v: string) => setRepeatPassword({...repeatPassword, value: v, error: ''})}
                   value={repeatPassword.value}
                   enableAutoComplete={true}
                   type="password"/>

        {recaptchaEnabled &&
          <ReCAPTCHA
            style={{marginBottom: 24}}
            sitekey="6LcjczIgAAAAAJ8_9LYtM_QmRqbWJ0HVRw13KPVs"
            onChange={(val: string | null) => setRecaptchaValue({value: (!!val ? val : ''), error: !!val ? '' : 'Failed reCaptcha'})}
          />}

        <Checkbox dataTest="termsAndConditionsCheckbox" value={agreeToTnC} onChange={(e: boolean) => setAgreeToTnC(e)}>
          <BodySmall className="login__checkbox">
            <Trans i18nKey={'register.terms-accept-message'} components={[
              <a target={"_blank"} rel="noreferrer" href="https://team-today.com/legal/terms-and-conditions">Terms and conditions</a>,
              <a target={"_blank"} rel="noreferrer" href="https://team-today.com/legal/privacy-policy">Privacy policy</a>]} />
          </BodySmall>
        </Checkbox>
        <Checkbox value={informMe} onChange={(e: boolean) => setInformMe(e)}
                  text="login.marketing-emails"/>
        <button data-test="submit"
                className="button button--primary btnFg login__button"
                type="submit"
                onClick={handleSubmit}>Register
        </button>
      </form>
    </AuthWrapper>
  )
}

function Processing() {
  return (
    <AuthWrapper dataTest="register-processing" title="general.processing" spinner={true}>
    </AuthWrapper>
  )
}

function Success() {
  const {t} = useTranslation();
  return (
    <AuthWrapper dataTest="register-success" title="login.email-sent" icon={IconTypes.Tick}>
      <BodyRegular>{t('register.follow-the-link')}</BodyRegular>
    </AuthWrapper>
  )
}
