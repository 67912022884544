import React, { useMemo } from 'react';
import styled from "styled-components";
import { iconPackPath } from "../../../utils/WhereaboutsHelper";
import { FreeBusyStatus } from "../../../services/WhereaboutOptions";
import { IconPack } from "../../../models/company.models";


export enum IconFilter {
  ALL, LOCATION, TRAVEL, LEAVE, OTHER
}

const ICONS = [
  {src: 'Car.svg', name: 'Car', type: IconFilter.TRAVEL},
  {src: 'Holiday.svg', name: 'Holiday', type: IconFilter.LEAVE},
  {src: 'PendingHoliday.svg', name: 'Holiday', type: IconFilter.LEAVE},
  {src: 'House.svg', name: 'House', type: IconFilter.OTHER},
  {src: 'ill.svg', name: 'ill', type: IconFilter.OTHER},
  {src: 'Office.svg', name: 'Office', type: IconFilter.LOCATION},
  {src: 'Offsite.svg', name: 'Offsite', type: IconFilter.LOCATION},
  {src: 'aeroplane.svg', name: 'Aeroplane', type: IconFilter.TRAVEL},
  {src: 'building-site.svg', name: 'Building site', type: IconFilter.LOCATION},
  {src: 'hospital.svg', name: 'Hospital', type: IconFilter.LOCATION},
  {src: 'shop.svg', name: 'Shop', type: IconFilter.LOCATION},
  {src: 'university.svg', name: 'University', type: IconFilter.LOCATION},
  {src: 'Seminar.svg', name: 'Seminar', type: IconFilter.LOCATION},
  {src: 'Travel.svg', name: 'Travel', type: IconFilter.TRAVEL},
  {src: 'Train.svg', name: 'Train', type: IconFilter.TRAVEL},
  {src: 'bank-holiday.svg', name: 'Bank Holiday', type: IconFilter.LEAVE},
  {src: 'hard-hat.svg', name: 'On-site', type: IconFilter.LOCATION},
  {src: 'sales-fayre.svg', name: 'Sales Fayre', type: IconFilter.LOCATION},
  {src: 'Boat.svg', name: 'Boat', type: IconFilter.TRAVEL},
  {src: 'DryDock.svg', name: 'Dry Dock', type: IconFilter.LOCATION},
  {src: 'Port.svg', name: 'Port', type: IconFilter.LOCATION},
  {src: 'Volunteering.svg', name: 'Volunteering', type: IconFilter.OTHER},
  {src: 'euro.svg', name: 'Euro', type: IconFilter.OTHER},
  {src: 'Adverse.svg', name: 'Adverse Weather', type: IconFilter.LEAVE},
  {src: 'Duvet.svg', name: 'Duvet', type: IconFilter.LEAVE},
  {src: 'Factory.svg', name: 'Factory', type: IconFilter.LEAVE},
  {src: 'GardenLeave.svg', name: 'Garden Leave', type: IconFilter.LEAVE},
  {src: 'Inlieu.svg', name: 'In Lieu', type: IconFilter.LEAVE},
  {src: 'Jury.svg', name: 'Jury', type: IconFilter.LEAVE},
  {src: 'Maternity.svg', name: 'Maternity', type: IconFilter.LEAVE},
  {src: 'Morning.svg', name: 'Morning', type: IconFilter.LEAVE},
  {src: 'NightShift.svg', name: 'Night Shift', type: IconFilter.LEAVE},
  {src: 'Parental.svg', name: 'Parental', type: IconFilter.LEAVE},
  {src: 'Paternity.svg', name: 'Paternity', type: IconFilter.LEAVE},
  {src: 'Sabbatical.svg', name: 'Sabbatical', type: IconFilter.LEAVE},
  {src: 'Study.svg', name: 'Study', type: IconFilter.LEAVE},
  {src: 'Star.svg', name: 'Star', type: IconFilter.LEAVE},
];

export function WhereaboutsImagePicker(props: Props) {
  const {iconPack, freeBusyStatus, iconFilter} = props;
  const colour = props.color ?? 'grey';
  const filteredIcons = useMemo(() => {
    // @ts-ignore
    if (!iconFilter || iconFilter === IconFilter.ALL) {
      return ICONS;
    } else {
      return ICONS.filter(i => i.type === iconFilter);
    }
  }, [iconFilter]);

  const onStatusSelect = (image: string) => {
    if (props.disabled) {
      return;
    }
    props.onSelect(image);
  }

  return (
    <Wrapper>
      <form>
        <RadioButtonList id="whereaboutsImages">
          {filteredIcons.map((icon, key) => (
            <RadioButtonContainer key={key}
                                  onClick={() => onStatusSelect(icon.src)}
                                  data-test={`whereabouts-picker-image-${icon.name.replace('/ /g', '-').toLowerCase()}`}>
              <div className={`whereaboutsPicker__icon movement__icon--${colour}`}>
                <img src={iconPackPath(iconPack, icon.src, freeBusyStatus)}
                     alt={icon.name}
                     data-test={`whereabouts-picker-image-${icon.name.replace(/\s+/g, '-')}`.toLowerCase()}
                     title={icon.name} />
              </div>
              <input type="radio" name="radio" readOnly={true} checked={icon.src === props.selectedImage} title={icon.name} />
            </RadioButtonContainer>
          ))}
        </RadioButtonList>
      </form>
    </Wrapper>
  )
}

interface Props {
  color?: string;
  selectedImage?: string;
  iconPack: IconPack;
  freeBusyStatus: FreeBusyStatus;
  onSelect: (image: string) => any;
  iconFilter?: IconFilter;
  disabled?: boolean;
}

const Wrapper = styled.div`
  justify-content: center;
  display: flex;
`

const RadioButtonContainer = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 74px;
  margin: 0 4px 16px 4px;
  cursor: pointer;
  border-radius: 8px;
  padding: 4px;
  img {
    border-radius: 8px;
    padding: 4px;
    height: 90%;
  }
  .whereaboutsPicker__icon {
    width: 100%;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-bottom: 6px;
  }
`

const RadioButtonList = styled.label`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
`
