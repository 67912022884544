import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../../store/ducks/auth.duck";
import { useMemo } from "react";
import { selectAllWhereaboutsOptions } from "../../../../../../store/ducks/whereaboutsOptions.duck";
import { hasUsageLimitBeenReached, UsageTypeKey } from "../../../../../dialogs/usage-limit-dialog/UsageLimitReachedDialog";
import { AccountType } from "../../../../../../models/company.models";

export function WithCustomWhereaboutsUsageLimitChecker() {
  const user = useSelector(selectCurrentUser);
  const allCustomWhereabouts = useSelector(selectAllWhereaboutsOptions);
  const accountType = user?.companyEntity.accountType ?? AccountType.STANDARD;

  return useMemo(() => {
    const customWhereaboutsCount = allCustomWhereabouts.filter(wo => wo.id > 9).length;
    return hasUsageLimitBeenReached(UsageTypeKey.CustomWhereabouts, accountType, customWhereaboutsCount);
  }, [accountType, allCustomWhereabouts]);
}
