import React, { useState } from 'react';
import styled from "styled-components/macro";
import { createUpdateWhereaboutsOption, WhereaboutsOption } from "../../../../../../services/WhereaboutOptions";
import Colours from "../../../../../UI/atoms/Colours";
import { IconPack } from "../../../../../../models/company.models";
import { BasicMovementIcon } from "../../../../../UI/atoms/BasicMovementIcon";
import { Switch } from "../../../../../UI/atoms/Switch";
import { BodyRegular, BodyVerySmall } from "../../../../../UI/atoms/fonts/Body";
import IconButton from "../../../../../UI/molecules/icon-button/IconButton";
import { IconTypes } from "../../../../../UI/atoms/icon/Icon";
import { useDispatch, useSelector } from "react-redux";
import { updateSingleWhereabouts } from "../../../../../../store/ducks/whereaboutsOptions.duck";
import { selectCurrentUser } from "../../../../../../store/ducks/auth.duck";
import { SimpleSpinner } from "../../../../../UI/atoms/SimpleSpinner";

export function CustomWhereaboutsOption(props: Props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const [loading, setLoading] = useState(false);
  const {customWhereabouts, iconPack} = props;

  const onChange = async (value: boolean) => {
    try {
      setLoading(true);
      const updatedOption = {
        ...customWhereabouts,
        enabled: value,
        companyId: currentUser?.companyEntity.id ?? '',
        outlookNote: customWhereabouts?.outlookNote ?? customWhereabouts.label,
      };
      await createUpdateWhereaboutsOption(updatedOption);
      dispatch(updateSingleWhereabouts(updatedOption));
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <WhereaboutsImage loading={loading}>
        <BasicMovementIcon onClick={() => {}}
                           option={customWhereabouts}
                           iconPack={iconPack} />
        {loading && <LoadingSpinnerContainer><SimpleSpinner size={32} /></LoadingSpinnerContainer>}
      </WhereaboutsImage>
      <Labels>
        <BodyRegular weight={600}>{customWhereabouts.label}</BodyRegular>
        {customWhereabouts?.isPrivate && <BodyVerySmall weight={400} colour={Colours.darkGrey}><Row>Private <img src={'/assets/icons/Small/Padlock.svg'} alt={""} /></Row></BodyVerySmall>}
        {customWhereabouts?.requiresApproval && <BodyVerySmall colour={Colours.darkGrey}>Requires authorisation</BodyVerySmall>}
      </Labels>
      <Controls>
        <IconButton icon={IconTypes.Edit} style={{marginRight: 8}} link={`/manage/custom-whereabouts/edit/${customWhereabouts.id}`} disabled={loading} />
        <Switch value={customWhereabouts.enabled} onChange={onChange} disabled={loading} />
      </Controls>
    </Container>
  )
}

interface Props {
  customWhereabouts: WhereaboutsOption;
  iconPack: IconPack;
}

const Row = styled.div`
  display: flex;
  img {
    margin-left: 4px;
    margin-top: 1px;
    height: 13px;
    width: 13px;
  }
`

const Container = styled.div`
  display: flex;
  border-bottom: 1px solid ${Colours.iconGrey};
  margin-bottom: 12px;
  padding-bottom: 12px;
  &:last-child {
    border-bottom: none;
  }
`

const Controls = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`

const LoadingSpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`

const WhereaboutsImage = styled.div<any>`
  position: relative;
`

const Labels = styled.div`
  margin-left: 24px;
`
