import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import { selectAllWhereaboutsOptions } from "../../../../../../store/ducks/whereaboutsOptions.duck";
import { WhereaboutsOption } from "../../../../../../services/WhereaboutOptions";
import { CustomWhereaboutsOption } from "./CustomWhereaboutsOption";
import { selectIconPack } from "../../../../../../store/ducks/auth.duck";
import { naturalComparator } from "../../../../../../utils/ArrayUtils";

export function CustomWhereaboutsList(props: Props) {
  const customWhereabouts = useSelector(selectAllWhereaboutsOptions);
  const iconPack = useSelector(selectIconPack)
  const sortedAndFilteredWhereaboutsList = useMemo(() => {
    if (customWhereabouts.length > 0) {
      const filteredWhereabouts = customWhereabouts.filter(cw => !cw.required);
      return [...filteredWhereabouts].sort(function(a, b) {
          if (a.colour === b.colour) {
            return naturalComparator(a, b, 'label');
          }
          return naturalComparator(a, b, 'colour');
        });
    } else {
      return [];
    }
  }, [customWhereabouts]);

  return (
    <Container>
      {sortedAndFilteredWhereaboutsList.map((cw: WhereaboutsOption, key) => <CustomWhereaboutsOption key={key}
                                                                                      iconPack={iconPack}
                                                                                      customWhereabouts={cw} />)}
    </Container>
  )
}

interface Props {
}


const Container = styled.div`
  margin-bottom: 24px;
`
