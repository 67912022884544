import React, { useMemo } from 'react';
import { ApprovalType, GroupedApprovalRequest } from "../../../../../models/approval-requests.models";
import { ApprovalLineWrapper, getAvatarColour } from "./ApprovalLineStructure";
import { UserAvatarAndName } from "../../../../UI/molecules/UserAvatarAndName";
import moment from "moment/moment";
import { toAirBnbDate } from "../../../../../utils/DateUtils";
import { Column } from "../../../../UI/atoms/StructuralLayout";
import { BodyRegular, BodyVerySmall } from "../../../../UI/atoms/fonts/Body";
import { Pill } from "../../../../UI/atoms/Pill";
import Colours from "../../../../UI/atoms/Colours";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { ApprovalCustomWhereaboutsPill } from "./ApprovalCustomWhereaboutsPill";
import { Tick } from "../../../../UI/atoms/Tick";
import IconButton from "../../../../UI/molecules/icon-button/IconButton";
import { IconTypes } from "../../../../UI/atoms/icon/Icon";
import { useDispatch } from "react-redux";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../../store/ducks/dialog.duck";

export function ApprovedLine(props: Props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const approval = props.groupedApproval;
  const formattedDate = useMemo(() => {
    const from = moment(approval.fromDate);
    const to = moment(approval.toDate);
    return toAirBnbDate(from, to);
  }, [approval]);

  const approvedBy = useMemo(() => {
    if (approval.approvedByUser && approval.approvedByUser.length > 0) {
      return approval.approvedByUser[0].firstName + ' ' + approval.approvedByUser[0].lastName;
    }
  }, [approval.approvedByUser])

  const onDeleteClicked = () => {
    dispatch(openDialogWithPayload({
      payload: { approval: approval },
      activeDialog: DialogIdentifiers.CancelApproveLeaveDialog
    }))
  }

  return (
    <ApprovalLineWrapper state={approval.approvalState} style={{alignItems: 'flex-start'}}>
      <UserAvatarAndName firstName={approval.firstName}
                         lastName={approval.lastName}
                         colour={getAvatarColour(approval.approvalState)}
                         hideName={true} />

      <Column className={'approvalLine__name'}>
        <BodyRegular weight={600}>{approval.firstName} {approval.lastName}</BodyRegular>
        <BodyRegular weight={400}>{formattedDate}</BodyRegular>
        <PillWrapper>

          <Pill backgroundColour={Colours.white} colour={Colours.blue}>
            <BodyVerySmall weight={600} colour={Colours.blue}>{approval.duration} {t('approvals.days')}</BodyVerySmall>
          </Pill>

          {approval.approvalType === ApprovalType.holiday && <>
            <Pill style={{marginLeft: 16}} backgroundColour={Colours.white} colour={Colours.blue}>
              <BodyVerySmall weight={600} colour={Colours.blue}>Holiday</BodyVerySmall>
            </Pill>
          </>}
          {approval.approvalType === ApprovalType.customWhereabouts && <>
            <ApprovalCustomWhereaboutsPill approval={approval} />
          </>}

        </PillWrapper>
      </Column>
      <ControlColumn>
        <Pill style={{marginLeft: 16, maxWidth: 124, alignSelf: 'flex-end'}} backgroundColour={Colours.white} colour={Colours.blue}>
          <Tick blue={true} />
          <BodyVerySmall weight={600} colour={Colours.blue}>Approved</BodyVerySmall>
        </Pill>
        <ControlRow>
          {approvedBy && <BodyVerySmall colour={Colours.darkGrey} title={(approval?.approvedByUser ?? []).length > 1 ? 'And others' : approvedBy}>{approvedBy}</BodyVerySmall>}
          <IconButton className={'approvalLine__editButton'}
                      icon={IconTypes.Bin}
                      text={'button.delete'}
                      color={'darkGrey'}
                      onClick={onDeleteClicked} />
        </ControlRow>
      </ControlColumn>
    </ApprovalLineWrapper>
  )
}

interface Props {
  groupedApproval: GroupedApprovalRequest;
}

const ControlColumn = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100px;
`

const ControlRow = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .approvalLine__editButton {
    margin-left: 16px;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
    .icon {
      width: unset;
    }
  }
`

const PillWrapper = styled.div`
  align-self: flex-start;
  margin-top: 12px;
  & > {
    margin-right: 8px;
  }
`
