import React, { useEffect } from 'react';
import styled from "styled-components/macro";
import Calendar from "../../../UI/molecules/calendar/Calendar";
import moment, { Moment } from "moment";
import { useDispatch, useSelector } from "react-redux";
import { NumberOfPeopleOption } from "./NumberOfPeopleOption";
import {
  refreshBookings,
  selectedOffice,
  selectIsSidebarOpen,
  setOffice,
  setSidebarOpen
} from "../models/room-finder.duck";
import { MeetingRoomFacilitiesOptions } from "./MeetingRoomFacilitiesOptions";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import { selectActiveDay, setActiveDay } from "../../../../store/ducks/editMovements.duck";
import { selectAllRootOffices } from "../../../../store/ducks/advanceHotDeskingSetup.duck";
import { addOrReplaceQueryParam } from "../../../../utils/UrlUtils";
import { DATE_FORMAT } from "../../../../utils/DateUtils";
import IconButton from "../../../UI/molecules/icon-button/IconButton";
import { IconTypes } from "../../../UI/atoms/icon/Icon";
import { OfficeDropDownList } from "../../../UI/organisms/OfficeDropDownList";
import { FloatingSidebar } from "../../company-movements/components/ViewComponents";
import { MicrosoftCalendarSyncActiveIndicator } from "../../../UI/organisms/MicrosoftCalendarSyncActiveIndicator";
import { HeadlineSmall } from "../../../UI/atoms/fonts/Headline";
import isMobile from "is-mobile";

export function RoomFinderSidebar(props: Props) {
  const dispatch = useDispatch();
  const calendarDate = useSelector(selectActiveDay);
  const office = useSelector(selectedOffice);
  const allOfficesForMeetingRooms = useSelector(selectAllRootOffices);
  const isSideBarOpen = useSelector(selectIsSidebarOpen);

  const onOfficeSelected = (office: OfficeEntity) => {
    dispatch(setOffice(office));
  }

  const onDateSelected = (date: Moment) => {
    addOrReplaceQueryParam('date', date.format(DATE_FORMAT))
    dispatch(setActiveDay(date));
    dispatch(refreshBookings())
  }

  useEffect(() => {
    if (isMobile()) {
      dispatch(setSidebarOpen(false));
    }
  }, [dispatch]);

  if (isSideBarOpen) {
    return (
      <FloatingSidebar className={'officeFloorMap__sidebar--open'}>
        <HeadlineSmall className={'roomFinderSidebar__title'}>Filter rooms</HeadlineSmall>
        <IconButton icon={IconTypes.Close}
                    size={"large"} className={"sidebar__closeBtn roomFinderSidebar__closeBtn"}
                    onClick={() => dispatch(setSidebarOpen(false))} />
        <Calendar onDateSelect={onDateSelected}
                  className={'calendar__roomFinderSidebar'}
                  initialDate={calendarDate ?? moment()}
                  activeDate={calendarDate} style={{margin: '0 auto 24px auto'}} />
        <OfficeDropDownListWrapper>
          <BodyRegular weight={700} style={{marginBottom: 8}}>Choose an office</BodyRegular>
          <OfficeDropDownList onSelect={onOfficeSelected} offices={allOfficesForMeetingRooms} disabled={false} preSelectedOffice={office?.id} />
        </OfficeDropDownListWrapper>
        <NumberOfPeopleOption />
        <MeetingRoomFacilitiesOptions style={{margin: '20px 0'}} />
        <MicrosoftCalendarSyncActiveIndicator />
      </FloatingSidebar>
    )
  } else {
    return null;
  }

}

interface Props {
}

const OfficeDropDownListWrapper = styled.div`
  margin: 20px 0;
  width: 100%;
`
