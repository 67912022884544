import styled from "styled-components/macro";
import Colours from "../../../../UI/atoms/Colours";
import { desktop } from "../../../../UI/atoms/MediaQueries";
import { ApprovalState } from "../../../../../models/approval-requests.models";
import { BodyVerySmall } from "../../../../UI/atoms/fonts/Body";


export function getBackgroundColour(state: ApprovalState) {
  switch (state) {
    case ApprovalState.approved: return Colours.blue02;
    case ApprovalState.declined: return Colours.veryLightGrey;
    case ApprovalState.cancelled: return Colours.veryLightGrey;
    default: return Colours.white;
  }
}

export function getAvatarColour(state: ApprovalState): 'blue'|'grey'|'darkBlue' {
  switch (state) {
    case ApprovalState.approved: return 'blue';
    case ApprovalState.declined: return 'grey';
    case ApprovalState.cancelled: return 'grey';
    default: return 'darkBlue';
  }
}

export function getBorderColour(state: ApprovalState) {
  switch (state) {
    case ApprovalState.approved: return Colours.blue20;
    default: return Colours.mildGrey;
  }
}

export const ApprovalLineWrapper = styled.div<any>`
  margin-bottom: 16px;
  border: 1px solid ${Colours.mildGrey};
  padding: 20px 24px;
  border-radius: 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  
  background-color: ${props => getBackgroundColour(props.state)};
  border: 1px solid ${props => getBorderColour(props.state)};
  
  
  .approvalLine__name {
    margin: 0 50px 0 20px;
  }
  
  @media (${desktop}) {
    align-items: center;
    flex-direction: row;
  }
`

export const BodyVerySmallWrapper = styled<any>(BodyVerySmall)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

