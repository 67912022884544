import React, { useMemo } from 'react';
import { Moment } from "moment";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";

export function HolidayDateRangeDisplay(props: Props) {
  const {toDate, fromDate} = props;
  const text = useMemo(() => {
    if (fromDate.toISOString() === toDate.toISOString()) {
      return fromDate.format('DD MMMM YYYY')
    } else if (fromDate.year() === toDate.year()) {
      return `${fromDate.format('DD MMMM')} - ${toDate.format('DD MMMM')}`
    } else {
      return `${fromDate.format('DD MMM YYYY')} - ${toDate.format('DD MMM YYYY')}`
    }
  }, [fromDate, toDate])
  return (
    <BodyRegular style={{marginBottom: 12}}>{text}</BodyRegular>
  )
}

interface Props {
  toDate: Moment;
  fromDate: Moment;
}
