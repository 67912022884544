import React, { useMemo } from 'react';
import { useSelector } from "react-redux";
import { selectAllWhereaboutsOptions } from "../../../../../store/ducks/whereaboutsOptions.duck";
import { CustomWhereaboutsListView } from "./views/CustomWhereaboutsListView";
import { NoCustomWhereaboutsView } from "./views/NoCustomWhereaboutsView";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import { canEditCustomWhereabouts } from "../../../../../utils/AccessControl";
import { selectHasGlobalConfigLoaded } from "../../../../../store/ducks/config.duck";
import { LoadingSpinner } from "../../../../UI/atoms/LoadingSpinner";

export function CustomWhereaboutsPage(props: Props) {
  const customWhereabouts = useSelector(selectAllWhereaboutsOptions);
  const currentUser = useSelector(selectCurrentUser);
  const canEdit = useMemo(() => canEditCustomWhereabouts(currentUser), [currentUser]);
  const hasGlobalConfigLoaded = useSelector(selectHasGlobalConfigLoaded);

  if (!canEdit) {
    return null;
  }

  if (!hasGlobalConfigLoaded) {
    return <LoadingSpinner fullScreen={true} hideText={true} />
  }

  if (customWhereabouts.length === 0) {
    return <NoCustomWhereaboutsView />
  } else {
    return <CustomWhereaboutsListView />
  }
}

interface Props {
}
