import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  bookVisitorDeskRequest,
  cancelVisitorDeskRequest,
  getVisitorBookings,
  VisitorBooking
} from "./visitor-booking-service";
import { AppState } from "../../../../../../store/state/app.state";
import { DATE_FORMAT } from "../../../../../../utils/DateUtils";
import { Period } from "../../../../../../models/movements.models";

export interface VisitorBookingState {
  bookings: VisitorBooking[];
  loading: boolean;
}

export const initialVisitorBookingState: VisitorBookingState = {
  bookings: [],
  loading: false,
};

export const loadVisitorBookings: any = createAsyncThunk(
  'visitorBooking/loadVisitorBookings',
  async (params: {date?: string, officeId?: number}, thunkAPI) => {
    const appState = thunkAPI.getState() as AppState;
    const date = params?.date ?? appState.dashboard.calendarDate.format(DATE_FORMAT);
    const officeId = params?.officeId ?? (appState.officeView.selectedOffice?.id ?? 0);
    const bookings = await getVisitorBookings(officeId, date);
    return bookings;
  }
);

export const updateVisitorBookingItem: any = createAsyncThunk(
  'visitorBooking/updateVisitorBookingItem',
  async (params: {visitorBooking: VisitorBooking}, thunkAPI) => {
    const appState = thunkAPI.getState() as AppState;
    const bookings = appState.visitorBooking.bookings;

    return bookings.map(b => b.id === params.visitorBooking.id ? params.visitorBooking : b);
  }
);

export const bookVisitorDesk: any = createAsyncThunk(
  'visitorBooking/bookVisitorDesk',
  async (params: {visitorBooking: VisitorBooking, selectedOfficeId?: number, selectedDeskId?: number, selectedCarParkSpace?: number, sideOfDay?: Period}, thunkAPI) => {
    await bookVisitorDeskRequest(params.visitorBooking.id,
      params.visitorBooking.date,
      params?.sideOfDay ?? Period.AllDay,
      params.selectedOfficeId,
      params.selectedDeskId,
      params.selectedCarParkSpace);
    thunkAPI.dispatch(loadVisitorBookings({date: params.visitorBooking.date}));
  }
);

export const cancelVisitorDesk: any = createAsyncThunk(
  'visitorBooking/cancel',
  async (params: {visitorBooking: VisitorBooking}, thunkAPI) => {
    await cancelVisitorDeskRequest(params.visitorBooking.id, params.visitorBooking.date);
    thunkAPI.dispatch(loadVisitorBookings({date: params.visitorBooking.date}));
  }
);

const visitorBookingSlice = createSlice({
  name: 'visitorBooking',
  initialState: initialVisitorBookingState,
  reducers: {
    resetVisitorBooking: (state) => (initialVisitorBookingState),
  },
  extraReducers: {
    [loadVisitorBookings.pending]: (state) => ({...state, bookings: [], loading: true}),
    [loadVisitorBookings.failed]: (state) => ({...state, bookings: [], loading: false}),
    [loadVisitorBookings.fulfilled]: (state, action) => ({...state, bookings: action.payload, loading: false}),

    [updateVisitorBookingItem.fulfilled]: (state, action) => ({...state, bookings: action.payload}),
  },
});

export default visitorBookingSlice.reducer;
export const {
  resetVisitorBooking,
} = visitorBookingSlice.actions;


// Selectors
export const selectVisitorBookings = (state: AppState) => state.visitorBooking.bookings;
export const selectVisitorBookingsLoading = (state: AppState) => state.visitorBooking.loading;
