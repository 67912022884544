import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from "../state/app.state";
import { getOffice, OfficeEntity } from "../../services/AdvanceHotDeskingService";
import { closeDialog, DialogIdentifiers, openDialog, setScrollPosition } from "./dialog.duck";
import { updateMovement } from "./editMovements.duck";
import { Desk, getDesk } from "../../services/DeskBookingService";
import { OFFICE_OPTION } from "../../services/WhereaboutOptions";
import { warningNotification } from "./notification.duck";
import { findOfficeInOffices, getOfficeChildren } from "../../utils/OfficeHelper";
import { hasOfficeChildren } from "../../hooks/WithOfficeChildren";
import { CarParkSpace } from "../../services/CarParkingPlanService";
import { SideOfDay } from "../../models/movements.models";

export enum SelectionState {
  Office, Floor, Done, Area,
}

function getOfficeSelectionState(office: OfficeEntity, offices: OfficeEntity[]) {
  const children = getOfficeChildren(office.id, offices);
  if (children.length === 0) {
    return SelectionState.Done;
  } else {
    if (office.floor || office.deskBookingEnabled) {
      return SelectionState.Area;
    } else {
      return SelectionState.Floor;
    }
  }
}

export interface DeskReservationState {
  selectedLocation?: OfficeEntity;
  defaultLocation?: OfficeEntity;
  selectedOffice?: OfficeEntity;
  selectedFloor?: OfficeEntity;
  selectedArea?: OfficeEntity;
  selectedDesk?: Desk;
  selectedDeskId?: number;
  selectedParkingSpace?: CarParkSpace;
  selectionState: SelectionState;
  loading: boolean;
  showFloorMap: boolean;
  showCarParkMap: boolean;
  onLocationSelected?: (selectedOffice?: number, selectedDesk?: number, selectedCarParkSpace?: number, sideOfDay?: SideOfDay) => any;
  hideFavouriteControls?: boolean;
  sideOfDay?: SideOfDay;
}

export const initialDeskReservation: DeskReservationState = {
  selectedLocation: undefined,
  defaultLocation: undefined,
  selectedOffice: undefined,
  selectedFloor: undefined,
  selectedArea: undefined,
  selectedDesk: undefined,
  selectedDeskId: undefined,
  selectedParkingSpace: undefined,
  selectionState: SelectionState.Office,
  loading: false,
  showFloorMap: false,
  showCarParkMap: false,
}

function findOfficeInHierarchy(offices: OfficeEntity[], officeId?: number) {
  if (!officeId) return;
  return offices.find((office: OfficeEntity) => office.id === officeId);
}

export const loadUserReservationDialog: any = createAsyncThunk(
  'advanceHotDeskingSetup/loadUserReservationDialog',
  async (params: {sideOfDay?: SideOfDay, hideFavouriteControls?: boolean, onLocationSelected?: (selectedOffice?: number, selectedDesk?: number, selectedCarParkSpace?: number, sideOfDay?: SideOfDay) => any}, thunkAPI) => {
    const state = (thunkAPI.getState() as AppState);
    const deskReservation = state.deskReservation;
    const allOffices = state.advanceHotDeskingSetup.offices;
    const currentUser = state.auth.currentUser;

    let result: any = {
      state: SelectionState.Office,
      onLocationSelected: params.onLocationSelected,
      hideFavouriteControls: params.hideFavouriteControls,
      sideOfDay: params?.sideOfDay,
    }

    result.office = undefined;
    result.floor = undefined;
    result.area = undefined;


    // Get the selectedLocation, FALLBACK to the current users default location FALLBACK to undefined
    const preSelectedLocation = deskReservation?.selectedLocation ?? (currentUser?.defaultLocationId ? findOfficeInHierarchy(allOffices, currentUser?.defaultLocationId) : undefined);

    if (preSelectedLocation) {

      // if no parentId then it's an OFFICE
      if (preSelectedLocation.office) {
        result.office = preSelectedLocation;
        result.state = SelectionState.Floor;
      } else if (preSelectedLocation.floor) {
        result.office = findOfficeInHierarchy(allOffices, preSelectedLocation.parentId);
        result.floor = preSelectedLocation;
        result.state = (preSelectedLocation.hasAreas || preSelectedLocation.deskBookingEnabled) ? SelectionState.Area : SelectionState.Done;
      } else if (preSelectedLocation.area) {
        result.area = preSelectedLocation;
        result.state = SelectionState.Done;

        const parent = findOfficeInHierarchy(allOffices, preSelectedLocation.parentId);
        if (parent) {
          if (parent.floor) {

            result.floor = parent;
            const grandparent = findOfficeInHierarchy(allOffices, parent.parentId);
            if (grandparent?.office) {
              result.office = grandparent;
            }

          } else if (parent.office) {
            result.office = parent;
          }
        }
      }

      if (deskReservation.selectedDeskId) {
        result.selectedDesk = await getDesk(deskReservation.selectedDeskId);
        result.state = SelectionState.Done;
      }
    }

    return result;
  }
);

export const clearDeskReservationSelection: any = createAsyncThunk(
  'advanceHotDeskingSetup/clearDeskReservationSelection',
  async (locationId: number, thunkAPI) => {
    return {};
  }
);

export const loadUsersDefaultLocation: any = createAsyncThunk(
  'advanceHotDeskingSetup/loadUsersDefaultLocation',
  async (locationId: number, thunkAPI) => {
    return await getOffice(locationId);
  }
);

export const openLocationDialog: any = createAsyncThunk(
  'deskReservation/openLocationDialog',
  async (properties: {selectedOffice?: OfficeEntity, floor?: OfficeEntity, deskId?: number}, thunkAPI) => {

    if (properties?.floor) {
      await thunkAPI.dispatch(setSelectedLocation(properties.floor));
    } else if (properties?.selectedOffice) {
      await thunkAPI.dispatch(setSelectedLocation(properties.selectedOffice));
    }

    if (properties?.deskId) {
      await thunkAPI.dispatch(setSelectedDeskId(properties.deskId));
    }

    thunkAPI.dispatch(openDialog(DialogIdentifiers.ChooseLocation));
    thunkAPI.dispatch(setScrollPosition(window.scrollY));
  }
);

export const openLocationDialogWithOfficeId: any = createAsyncThunk(
  'deskReservation/openLocationDialog',
  async (officeId: number, thunkAPI) => {
    const office = findOfficeInOffices((thunkAPI.getState() as AppState).advanceHotDeskingSetup.offices, officeId);
    thunkAPI.dispatch(openLocationDialog({selectedOffice: office}));
  }
);

export const selectOfficeForReservation: any = createAsyncThunk(
  'deskReservation/selectOfficeForReservation',
  async (officeEntity: OfficeEntity, thunkAPI) => {
    const state = (thunkAPI.getState() as AppState);
    const desk = state.deskReservation.selectedDesk;
    const parkingSpace = state.deskReservation.selectedParkingSpace;
    const offices = state.advanceHotDeskingSetup.offices;
    const sideOfDay = state.deskReservation.sideOfDay;

    if (!hasOfficeChildren(officeEntity.id, offices)) {
      if (state.deskReservation.onLocationSelected) {
        state.deskReservation.onLocationSelected(officeEntity.id, desk?.id, parkingSpace?.id, sideOfDay);
      } else {
        thunkAPI.dispatch(updateMovement({selectedOption: OFFICE_OPTION, locationId: officeEntity.id ?? 0, deskId: desk?.id, parkingSpaceId: parkingSpace?.id}));
      }
      thunkAPI.dispatch(closeDialog());
      thunkAPI.dispatch(resetDeskReservationDialog());
    }

    return {
      office: officeEntity,
      state: getOfficeSelectionState(officeEntity, offices),
    };
  }
);

export const selectFloorForReservation: any = createAsyncThunk(
  'deskReservation/selectFloorForReservation',
  async (officeFloorEntity: OfficeEntity, thunkAPI) => {
    const state = (thunkAPI.getState() as AppState);
    const offices = state.advanceHotDeskingSetup.offices;
    return {
      floor: officeFloorEntity,
      state: (officeFloorEntity.hasAreas && hasOfficeChildren(officeFloorEntity.id, offices)) || officeFloorEntity.deskBookingEnabled ? SelectionState.Area : SelectionState.Done,
    };
  }
);

export const selectAreaForReservation: any = createAsyncThunk(
  'deskReservation/selectAreaForReservation',
  async (officeAreaEntity: OfficeEntity, thunkAPI) => {
    return {
      state: SelectionState.Done,
      area: officeAreaEntity
    };
  }
)

export const selectDeskForReservation: any = createAsyncThunk(
  'deskReservation/selectDeskForReservation',
  async (desk: Desk, thunkAPI) => {
    if (!desk) throw new Error('No desk set');
    if (desk) {
      return {
        state: SelectionState.Done,
        desk: desk,
      };
    } else {
      thunkAPI.dispatch(warningNotification('Failed to pick a desk'));
    }
  }
)

export const confirmDeskForReservation: any = createAsyncThunk(
  'deskReservation/confirmDeskForReservation',
  async (desk: Desk, thunkAPI) => {
    if (!desk) throw new Error('No desk set');
    const deskReservationState = (thunkAPI.getState() as AppState).deskReservation;
    const office = deskReservationState.selectedFloor ?? deskReservationState.selectedOffice;
    const parkingSpace = deskReservationState.selectedParkingSpace;
    if (desk && office) {
      if (deskReservationState.onLocationSelected) {
        const sideOfDay = deskReservationState.sideOfDay;
        deskReservationState.onLocationSelected(office.id, desk.id, parkingSpace?.id, sideOfDay);
      } else {
        thunkAPI.dispatch(updateMovement({selectedOption: OFFICE_OPTION, locationId: desk.officeId, deskId: desk.id, parkingSpaceId: parkingSpace?.id}));
      }
      thunkAPI.dispatch(closeDialog());
      thunkAPI.dispatch(resetDeskReservationDialog());
    } else {
      thunkAPI.dispatch(warningNotification('Failed to pick a desk'));
    }
  })

export const goBackToPreviousState: any = createAsyncThunk(
  'deskReservation/goBackToPreviousState',
  async (none: any, thunkAPI) => {
    const {selectedArea, selectedOffice, selectedFloor, selectedDesk} = (thunkAPI.getState() as AppState).deskReservation;
    if (selectedDesk) {
      thunkAPI.dispatch(setSelectionState(SelectionState.Area));
    } else if (selectedArea) {
      thunkAPI.dispatch(setSelectionState(SelectionState.Area));
    } else if (selectedFloor) {
      thunkAPI.dispatch(setSelectionState(SelectionState.Floor));
    } else if (selectedOffice) {
      thunkAPI.dispatch(setSelectionState(SelectionState.Office));
    }
  }
)

const deskReservationSlice = createSlice({
  name: 'deskReservation',
  initialState: initialDeskReservation,
  reducers: {
    resetDeskReservationDialog: () => initialDeskReservation,
    setSelectionState: (state, action) => ({...state, selectionState: action.payload}),
    setSelectedFloor: (state, action) => ({...state, selectedFloor: action.payload}),
    setSelectedLocation: (state, action) => ({...state, selectedLocation: action.payload}),
    setShowFloorMap: (state, action) => ({...state, showFloorMap: action.payload}),
    setShowCarParkMap: (state, action) => ({...state, showCarParkMap: action.payload}),
    setSelectedParkingSpace: (state, action) => ({...state, selectedParkingSpace: action.payload}),
    setSelectedDeskId: (state, action) => ({...state, selectedDeskId: action.payload}),
  },
  extraReducers: {
    [loadUserReservationDialog.pending]: (state) => ({...state, loading: true,}),
    [loadUserReservationDialog.reject]: (state) => ({...state, loading: false,}),
    [loadUserReservationDialog.fulfilled]: (state, action) => ({...state,
      loading: false,
      selectedFloor: action.payload.floor,
      selectedOffice: action.payload.office,
      selectedArea: action.payload.area,
      selectedDesk: action.payload.selectedDesk,
      selectionState: action.payload.state,
      onLocationSelected: action.payload.onLocationSelected,
      hideFavouriteControls: action.payload.hideFavouriteControls,
      sideOfDay: action.payload.sideOfDay,
    }),

    [clearDeskReservationSelection.pending]: (state) => ({...state, loading: true,}),
    [clearDeskReservationSelection.reject]: (state) => ({...state, loading: false,}),
    [clearDeskReservationSelection.fulfilled]: (state) => ({...state,
      loading: false,
      selectionState: SelectionState.Office,
      selectedFloor: undefined,
      selectedOffice: undefined,
    }),

    [selectOfficeForReservation.fulfilled]: (state, action) => ({...state,
      selectedOffice: action.payload.office,
      selectionState: action.payload.state,
    }),

    [selectFloorForReservation.fulfilled]: (state, action) => ({
      ...state,
      selectedFloor: action.payload.floor,
      selectionState: action.payload.state,
    }),

    [selectAreaForReservation.fulfilled]: (state, action) => ({
      ...state,
      selectedArea: action.payload.area,
      selectionState: action.payload.state,
    }),

    [selectDeskForReservation.fulfilled]: (state, action) => ({
      ...state,
      selectedDesk: action.payload.desk,
      selectionState: action.payload.state,
    }),

    [loadUsersDefaultLocation.pending]: (state) => ({...state, loading: true}),
    [loadUsersDefaultLocation.reject]: (state) => ({...state, loading: false, defaultLocation: undefined}),
    [loadUsersDefaultLocation.fulfilled]: (state, action) => ({
      ...state,
      defaultLocation: action.payload,
      loading: false,
    }),
  }
});

export const {
  resetDeskReservationDialog,
  setSelectionState,
  setSelectedLocation,
  setShowFloorMap,
  setShowCarParkMap,
  setSelectedParkingSpace,
  setSelectedDeskId,
  setSelectedFloor,
} = deskReservationSlice.actions;
export default deskReservationSlice.reducer;

export const selectShowFloorMap = (state: AppState) => state.deskReservation.showFloorMap;
export const selectShowCarParkMap = (state: AppState) => state.deskReservation.showCarParkMap;
export const selectSelectionState = (state: AppState) => state.deskReservation.selectionState;
export const selectSelectionOffice = (state: AppState) => state.deskReservation.selectedOffice;
export const selectSelectedFloor = (state: AppState) => state.deskReservation.selectedFloor;
export const selectSelectedArea = (state: AppState) => state.deskReservation.selectedArea;
export const selectSelectedDesk = (state: AppState) => state.deskReservation.selectedDesk;
export const selectedSelectedParkingSpace = (state: AppState) => state.deskReservation.selectedParkingSpace;
export const selectIsDeskReservationLoading = (state: AppState) => state.deskReservation.loading;
export const selectHideFavouriteControls = (state: AppState) => state.deskReservation.hideFavouriteControls;
