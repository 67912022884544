import React, { useEffect, useMemo } from 'react';
import Layout from "../../../../UI/organisms/layout/Layout";
import { VerticalSpacer } from "../../../../UI/atoms/VerticalSpacer";
import { ContentPage, MainContent, Sidebar } from "../ViewComponents";
import { BiggerThanTabletOnly } from "../../../../UI/atoms/DeviceSpecific";
import { OfficeListSidebar } from "../daily-office-list-view/OfficeListSidebar";
import styled from "styled-components/macro";
import { tablet } from "../../../../UI/atoms/MediaQueries";
import { VisitorBookingHeader } from "./components/VisitorBookingHeader";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectAllOfficeEntities } from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import { selectOffice, selectSelectedOffice } from "../../../../../store/ducks/officeView.duck";
import Calendar from "../../../../UI/molecules/calendar/Calendar";
import { selectCalendarDate, setCalendarDate } from "../../../../../store/ducks/dashboard.duck";
import { PrimaryButton } from "../../../../UI/atoms/buttons/PrimaryButton";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../../store/ducks/dialog.duck";
import { VisitorBooking, VisitorSignInState } from "./data/visitor-booking-service";
import { DATE_FORMAT } from "../../../../../utils/DateUtils";
import { VisitorBookingLine } from "./components/VisitorBookingLine";
import { loadVisitorBookings, selectVisitorBookings, selectVisitorBookingsLoading } from "./data/visitorBooking.duck";
import { HeadlineSmall } from "../../../../UI/atoms/fonts/Headline";
import { useTranslation } from "react-i18next";
import Icon, { IconTypes } from "../../../../UI/atoms/icon/Icon";
import { NoVisitorBookings } from "./components/NoVisitorBookings";
import { LoadingSpinner } from "../../../../UI/atoms/LoadingSpinner";
import { Center } from "../../../../UI/atoms/StructuralLayout";
import { VisitorBookingColumnHeaders } from "./components/VisitorBookingColumnHeaders";

export function VisitorBookingView(props: Props) {
  document.body.style.backgroundColor = '#F2F6FF';
  const {t} = useTranslation();
  const allOffices = useSelector(selectAllOfficeEntities);
  const params: any = useParams();
  const calendarDate = useSelector(selectCalendarDate);
  const calendarDateString = calendarDate.format(DATE_FORMAT);
  const dispatch = useDispatch();
  const visitorBookings = useSelector(selectVisitorBookings);
  const selectedOffice = useSelector(selectSelectedOffice);
  const loading = useSelector(selectVisitorBookingsLoading);

  const officeId = selectedOffice?.id
  const noBookings = !loading && visitorBookings.length === 0;

  const dueInVisitors = useMemo(() => {
    return visitorBookings.filter(vb => vb.signInState !== VisitorSignInState.CHECKED_OUT && vb.signInState !== VisitorSignInState.CHECKED_IN)
  }, [visitorBookings]);

  const signedInVisitors = useMemo(() => {
    return visitorBookings.filter(vb => vb.signInState === VisitorSignInState.CHECKED_IN)
  }, [visitorBookings]);

  const signedOutVisitors = useMemo(() => {
    return visitorBookings.filter(vb => vb.signInState === VisitorSignInState.CHECKED_OUT)
  }, [visitorBookings]);

  const office = useMemo(() => {
    const officeId = parseInt(params.officeId)
    return allOffices.find((o: OfficeEntity) => o.id === officeId);
  }, [params, allOffices]);

  const officeHasAnyFloorPlans = useMemo(() => {
    if (!selectedOffice) return false;
    const directChildren = allOffices.filter((o: OfficeEntity) => o.parentId === selectedOffice.id);
    return directChildren.find((o: OfficeEntity) => !!o.officePlan && o.floorPlanEnabled);
  }, [allOffices, selectedOffice]);

  useEffect(() => {
    if (office) {
      dispatch(selectOffice(office));
    }
  }, [dispatch, office]);

  useEffect(() => {
    if (officeId && calendarDateString) {
      dispatch(loadVisitorBookings({date: calendarDateString, officeId: officeId}))
    }
  }, [calendarDateString, dispatch, officeId]);

  if (!office) {
    return null;
  }

  return (
    <Layout>
      <DailyVisitorContainer>
        <DailyVisitorListViewContent>

          {selectedOffice && <VisitorBookingHeader office={selectedOffice} />}

          {loading ? <>
            <Center><LoadingSpinner hideBorder={true} hideText={true} /></Center>
          </> : <>
            {noBookings ? <>
              <NoVisitorBookings />
            </> : <>

              <VisitorBookingColumnHeaders />

              {dueInVisitors.length > 0 && <>
                <HeadlineSmall style={{display: 'flex', marginBottom: 12}}><Icon style={{marginRight: 8}} icon={IconTypes.Time} />{t('visitor-booking.due-today')}</HeadlineSmall>
                <VisitorBookingsList style={{marginBottom: 24}}>
                  {dueInVisitors.map((booking: VisitorBooking, key: number) => <VisitorBookingLine booking={booking} officeHasAnyFloorPlan={officeHasAnyFloorPlans} key={key} />)}
                </VisitorBookingsList>
              </>}

              {signedInVisitors.length > 0 && <>
                <HeadlineSmall style={{display: 'flex', marginBottom: 12}}><Icon style={{marginRight: 8}} icon={IconTypes.Tick} />{t('visitor-booking.signed-in')}</HeadlineSmall>
                <VisitorBookingsList style={{marginBottom: 24}}>
                  {signedInVisitors.map((booking: VisitorBooking, key: number) => <VisitorBookingLine booking={booking} officeHasAnyFloorPlan={officeHasAnyFloorPlans} key={key} />)}
                </VisitorBookingsList>
              </>}

              {signedOutVisitors.length > 0 && <>
                <HeadlineSmall style={{display: 'flex', marginBottom: 12}}><Icon style={{marginRight: 8}} icon={IconTypes.Tick} />{t('visitor-booking.signed-out')}</HeadlineSmall>
                <VisitorBookingsList style={{marginBottom: 24}}>
                  {signedOutVisitors.map((booking: VisitorBooking, key: number) => <VisitorBookingLine booking={booking} officeHasAnyFloorPlan={officeHasAnyFloorPlans} key={key} />)}
                </VisitorBookingsList>
              </>}

            </>}
          </>}

        </DailyVisitorListViewContent>
        <VerticalSpacer/>
        <Sidebar>
          <Calendar onDateSelect={(date) => dispatch(setCalendarDate(date))}
                    activeDate={calendarDate}
                    initialDate={calendarDate}
                    style={{marginBottom: 24}} />

          <BiggerThanTabletOnly>
            <OfficeListSidebar hideUsageChart={true} />
          </BiggerThanTabletOnly>

          <PrimaryButton fullWidth={true} click={() => dispatch(openDialogWithPayload({
            payload: { date: calendarDate, office: selectedOffice },
            activeDialog: DialogIdentifiers.AddVisitorDialog
          }))} text={'visitor-booking.add-visitor'} />
        </Sidebar>
      </DailyVisitorContainer>
    </Layout>
  )
}

interface Props {
}

const DailyVisitorContainer = styled<any>(ContentPage)`
  flex-direction: column;
  min-height: 60vh;
  @media (${tablet}) {
    flex-direction: row;
  }
`

const DailyVisitorListViewContent = styled<any>(MainContent)`
  padding-top: 0;
  min-height: 700px;
`

const VisitorBookingsList = styled.div`
  @media (${tablet}) {
    margin: 0 24px 0 0;
  }
`
