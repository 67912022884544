import React, { useMemo } from 'react';
import { HolidayActivityFill } from "./WallPlannerActivityBlocks";
import { daysBetween } from "../../../../utils/DateUtils";
import { Moment } from "moment";
import { Birthday } from "../../../../models/user.models";
import styled from "styled-components/macro";
import Colours from "../../../UI/atoms/Colours";
import { CustomLeaveInfoPopup } from "./CustomLeaveInfoPopup";
import moment from "moment/moment";
import { PopupPosition } from "./HolidayInfoAndApprovalPopup";
import { SideOfDay } from "../../../../models/movements.models";
import { WhereaboutsIconOutline } from "../../../UI/atoms/WhereaboutsIconOutline";

export function CustomLeaveBlock(props: Props) {
  const {activity, firstDateOnCalendar} = props;
  const days = 1;

  const popupPositioning = useMemo(() => {
    const daysDiff = moment(activity.date).diff(firstDateOnCalendar, 'days');
    return daysDiff > 20 ? PopupPosition.Right : PopupPosition.Left;
  }, [activity, firstDateOnCalendar]);

  console.log(activity)

  if (activity.amWhereaboutsId === activity.pmWhereaboutsId) {
    return (
      <HolidayActivityFill start={daysBetween(activity.date, firstDateOnCalendar)}
                           days={days}
                           textColour={Colours.black}
                           title={activity.label}>
        <Contents>
          {activity.amImage && <WhereaboutsIconOutline label={''} image={activity.amImage} colour={'grey'} />}

          {props.children && <>{props.children}</>}
        </Contents>
        <CustomLeaveInfoPopup customLeaveDay={activity}
                              sideOfDay={SideOfDay.AllDay}
                              popupPositioning={popupPositioning} />
      </HolidayActivityFill>
    )
  } else {
    return <>
      {activity.amWhereaboutsId && activity.amImage && <>
        <HolidayActivityFill start={daysBetween(activity.date, firstDateOnCalendar)}
                             days={days}
                             startsWithHalfDay={true}
                             endsWithHalfDay={false}
                             textColour={Colours.black}
                             title={activity.label}>
          <Contents>
            <WhereaboutsIconOutline label={''} image={activity.amImage} colour={'grey'} />
            {props.children && <>{props.children}</>}
          </Contents>
          <CustomLeaveInfoPopup customLeaveDay={activity}
                                sideOfDay={SideOfDay.AM}
                                popupPositioning={popupPositioning} />
        </HolidayActivityFill>
      </>}
      {activity.pmWhereaboutsId && activity.pmImage && <>
        <HolidayActivityFill start={daysBetween(activity.date, firstDateOnCalendar)}
                             days={days}
                             startsWithHalfDay={true}
                             endsWithHalfDay={false}
                             textColour={Colours.black}
                             title={activity.label}>
          <Contents>
            <WhereaboutsIconOutline label={''} image={activity.pmImage} colour={'grey'} />
            {props.children && <>{props.children}</>}
          </Contents>
          <CustomLeaveInfoPopup customLeaveDay={activity}
                                sideOfDay={SideOfDay.PM}
                                popupPositioning={popupPositioning} />
        </HolidayActivityFill>
      </>}
    </>
  }
}

interface Props {
  activity: any;
  children?: any;
  firstDateOnCalendar: Moment;
  weeksInMonth: number;
  birthday?: Birthday;
}

const Contents = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: center;
`
