import React, { useMemo } from 'react';
import { Pill } from "../../../../UI/atoms/Pill";
import Colours from "../../../../UI/atoms/Colours";
import { GroupedApprovalRequest } from "../../../../../models/approval-requests.models";
import { useSelector } from "react-redux";
import { selectWhereaboutsOptionById } from "../../../../../store/ducks/whereaboutsOptions.duck";
import { WhereaboutsOption } from "../../../../../services/WhereaboutOptions";
import { BodyVerySmallWrapper } from "./ApprovalLineStructure";

export function ApprovalCustomWhereaboutsPill(props: Props) {
  const approval = props.approval;
  const optionAm: WhereaboutsOption | undefined = useSelector(state => selectWhereaboutsOptionById(state, approval?.amWhereaboutsId ?? 0))
  const optionPm: WhereaboutsOption | undefined = useSelector(state => selectWhereaboutsOptionById(state, approval?.pmWhereaboutsId ?? 0))

  const mouseOverText = useMemo(() => {
    if (approval.amWhereaboutsId === approval.pmWhereaboutsId) {
      return optionAm?.label;
    } else {
      return `AM: ${optionAm?.label} | PM: ${optionPm?.label}`;
    }
  }, [approval.amWhereaboutsId, approval.pmWhereaboutsId, optionAm?.label, optionPm?.label])

  if (!optionAm && !optionPm) {
    return null;
  }
  return (
    <Pill style={{maxWidth: 200, justifyContent: 'space-between'}} backgroundColour={Colours.blue02} title={mouseOverText}>
      {approval.amWhereaboutsId === approval.pmWhereaboutsId ? <>
        <BodyVerySmallWrapper weight={600}>{optionAm?.label}</BodyVerySmallWrapper>
      </> : <>
        {optionAm && <BodyVerySmallWrapper weight={600} style={{width: '47%'}}>AM: {optionAm?.label}</BodyVerySmallWrapper>}
        {optionPm && <BodyVerySmallWrapper weight={600} style={{width: '47%'}}>PM: {optionPm?.label}</BodyVerySmallWrapper>}
      </>}
    </Pill>
  )
}

interface Props {
  approval: GroupedApprovalRequest;
}
