import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveDay, selectFocusedUserId, updateMovement } from '../../../../../store/ducks/editMovements.duck';
import { BodyRegular, BodyVerySmall } from "../../../atoms/fonts/Body";
import { Moment } from "moment";
import { MovementImage } from "../../../atoms/MovementImage";
import ActivityAdvanceHotDeskingOffice from "./ActivityAdvanceHotDeskingOffice";
import ActivitySimpleOffice from "./ActivitySimpleOffice";
import { selectOffices } from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { selectAllSelectableWhereaboutsOptions } from "../../../../../store/ducks/whereaboutsOptions.duck";
import {
  CustomWhereaboutsType,
  FreeBusyStatus,
  HOLIDAY_ID,
  NON_WORKING_DAY,
  WhereaboutsOption
} from "../../../../../services/WhereaboutOptions";
import { iconPackPath } from "../../../../../utils/WhereaboutsHelper";
import { selectIconPack } from "../../../../../store/ducks/auth.duck";
import { DATE_FORMAT } from "../../../../../utils/DateUtils";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../../store/ducks/dialog.duck";
import Colours from "../../../atoms/Colours";

export default function ActivityList(props: { activeDate?: Moment }) {

  return (
    <div className="activityList">
      <ActivityListItems activeDate={props.activeDate} />
    </div>
  )
}

function ActivityListItems(props: { activeDate?: Moment }) {
  const activities = useSelector(selectAllSelectableWhereaboutsOptions);
  const iconPack = useSelector(selectIconPack);
  const activeDay = useSelector(selectActiveDay);
  const activeUserId = useSelector(selectFocusedUserId);

  const holidays = activities.filter(a => a.whereaboutsType === CustomWhereaboutsType.LEAVE || a.id === HOLIDAY_ID)

  if (holidays.length === 1) {
    return <>
      <WhereaboutsOffices activeDate={props.activeDate} />
      {activities.map((activity, key) => (
        <ActivityItem key={key}
                      activity={activity}
                      activeUserId={activeUserId}
                      activeDay={activeDay}
                      iconPack={iconPack} />
      ))}
    </>
  } else {
    const activitiesDiff = activities.filter(a => !holidays.includes(a) && a.id !== NON_WORKING_DAY);
    const nonWorkingDay = activities.filter(a => a.id === NON_WORKING_DAY);
    return <>
      <WhereaboutsOffices activeDate={props.activeDate} />
      {activitiesDiff.map((activity, key) => (
        <ActivityItem key={key}
                      activity={activity}
                      activeUserId={activeUserId}
                      activeDay={activeDay}
                      iconPack={iconPack} />
      ))}
      <MultipleLeaveItem activeUserId={activeUserId} activeDay={activeDay} />
      {nonWorkingDay.map((activity, key) => (
        <ActivityItem key={key}
                      activity={activity}
                      activeUserId={activeUserId}
                      activeDay={activeDay}
                      iconPack={iconPack} />
      ))}
    </>
  }
}

function WhereaboutsOffices(props: {activeDate: any}) {
  const {activeDate} = props;
  const offices = useSelector(selectOffices);

  if (offices.length > 0) {
    return <ActivityAdvanceHotDeskingOffice activeDate={activeDate} />
  } else {
    return <ActivitySimpleOffice activeDate={activeDate} offices={offices} />
  }
}

interface ActivityItemProps {
  activeDay?: Moment;
  iconPack: any;
  activity: any;
  activeUserId?: string;
}

function ActivityItem(props: ActivityItemProps) {
  const {activeDay, iconPack, activity, activeUserId} = props;
  const dispatch = useDispatch();

  const updateSelectedDay = (e: any, option: WhereaboutsOption) => {
    e.stopPropagation();
    dispatch(updateMovement({selectedOption: option, locationId: 0, activeDay: activeDay?.format(DATE_FORMAT), activeUserId}));
  }

  return (<div className="activity" onClick={(e: any) => updateSelectedDay(e, activity)}>
    <MovementImage src={iconPackPath(iconPack, activity.image, FreeBusyStatus.FREE)} alt={activity.alt}/>
    <BodyRegular weight={600}>{activity.label}</BodyRegular>
    {props?.activity?.isPrivate && <BodyVerySmall colour={Colours.mildGrey} title={'This is only visible to you, line managers, and company admins.'}>Private</BodyVerySmall>}
  </div>)
}

function MultipleLeaveItem(props: {activeDay?: Moment, activeUserId?: string}) {
  const {activeDay, activeUserId} = props;
  const dispatch = useDispatch();

  const onLeaveTypeSelected = (wo: WhereaboutsOption) => {
    dispatch(updateMovement({selectedOption: wo, locationId: 0, activeDay: activeDay?.format(DATE_FORMAT), activeUserId}));
  }

  const onClick = (e: any) => {
    e.stopPropagation();
    dispatch(openDialogWithPayload({
      payload: { onSelect: onLeaveTypeSelected },
      activeDialog: DialogIdentifiers.SelectLeaveTypeDialog
    }))
  }

  return (<div className="activity" onClick={onClick}>
    <MovementImage src={'/assets/icons/multiple-icon.svg'} alt={''}/>
    <BodyRegular weight={600}>Add Leave</BodyRegular>
  </div>)
}
