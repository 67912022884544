import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import { GoBackButton } from "../../../../../UI/atoms/buttons/GoBackButton";
import { HeadlineSmall } from "../../../../../UI/atoms/fonts/Headline";
import { useTranslation } from "react-i18next";
import { OfficeEntity } from "../../../../../../services/AdvanceHotDeskingService";
import { useDispatch, useSelector } from "react-redux";
import { selectAllOfficeEntities } from "../../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { getFullOfficeTitle } from "../../../../../../utils/OfficeHelper";
import { selectCalendarDate } from "../../../../../../store/ducks/dashboard.duck";
import { DATE_FORMAT } from "../../../../../../utils/DateUtils";
import { redirectTo } from "../../../../../../store/ducks/auth.duck";
import { tablet } from "../../../../../UI/atoms/MediaQueries";
import { OutlineButton } from "../../../../../UI/atoms/buttons/OutlineButton";
import { DialogIdentifiers, openDialog } from "../../../../../../store/ducks/dialog.duck";

export function VisitorBookingHeader(props: Props) {
  const {t} = useTranslation();
  const {office} = props;
  const dispatch = useDispatch();
  const allOffices = useSelector(selectAllOfficeEntities);
  const calendarDate = useSelector(selectCalendarDate);

  const officeLabel = useMemo(() => {
    return getFullOfficeTitle(office, allOffices, ', ');
  }, [allOffices, office]);

  const backUrl = useMemo(() => {
    let url = '/whereabouts/office-view';
    let params: string[] = [];
    if (office) {
      params.push(`office=${office.id}`);
    }
    if (calendarDate) {
      params.push(`date=${calendarDate.format(DATE_FORMAT)}`);
    }

    if (params.length > 0) {
      url += ('?' + params.join('&'));
    }

    return url;
  }, [office, calendarDate]);

  const onClick = () => {
    dispatch(redirectTo(backUrl))
  }

  const onFiltersClick = () => {
    dispatch(openDialog(DialogIdentifiers.VisitorBookingFilterDialog));
  }

  return (
    <Container>
      <GoBackButton onClick={onClick} />
      <HeadlineSmall>{t('visitor-booking.visitor-office-title', {officeName: officeLabel})}</HeadlineSmall>
      <OutlineButton text={"button.filters"} click={onFiltersClick} size={"small"} className={"visitorBooking_filterButton"} />
    </Container>
  )
}

interface Props {
  office: OfficeEntity;
}

const Container = styled.div`
  display: flex;
  padding: 16px 0;
  flex-direction: column;
  .visitorBooking_filterButton {
    margin: 10px auto 0 auto;
  }
  & > h3 {
    margin: 0 auto;
  }
  @media (${tablet}) {
    flex-direction: row;
    .visitorBooking_filterButton {
      display: none;
    }
  }
`
