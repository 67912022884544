import React from 'react';
import './_button.scss';
import styled from "styled-components/macro";
import Icon from "../icon/Icon";
import { BaseButton, NewButtonProps } from "./ButtonStyling";
import Colours from "../Colours";
import { ButtonImage } from "./Button";
import { Row } from '../StructuralLayout';
import { BodyVerySmall } from "../fonts/Body";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function OutlineButton(props: NewButtonProps) {
  const {t} = useTranslation();
  let buttonAttributes = {}
  if (!!props.dataTest) {
    // @ts-ignore
    buttonAttributes['data-test'] = props.dataTest;
  }
  if (!!props.className) {
    // @ts-ignore
    buttonAttributes['className'] = props.className;
  }
  if (props.link) {
    const linkBtnProps = {
      ...buttonAttributes,
      ...props,
    }
    return <OutlineLinkButton {...linkBtnProps} />
  }
  return (
    <OutlineButtonWrapper {...buttonAttributes}
                          size={props.size || 'medium'}
                          fullWidth={!!props.fullWidth}
                          disabled={!!props.disabled}
                          type={props.buttonType ?? 'button'}
                          style={props?.style ?? {}}
                          borderColour={props?.borderColour}
                          hideBorder={props?.hideBorder}
                          onClick={(e: any) => props.click ? props.click(e) : () => {}}>
      <Row>
        {props.icon && <Icon style={{marginRight: 8}} icon={props.icon ?? ''}/>}
        {props.imageUrl && <ButtonImage src={props.imageUrl} alt="image" aria-hidden={true}/>}
        {props.text && <span>{t(props.text)}</span>}
      </Row>
      {props.subtext && <BodyVerySmall colour={Colours.darkGrey} style={{margin: 0}}>{props.subtext}</BodyVerySmall>}
    </OutlineButtonWrapper>
  );
}

function OutlineLinkButton(props: NewButtonProps) {
  const linkButtonProps = {
    ...props,
    link: undefined,
  }
  return <Link to={props.link ?? '#'} style={props.style ?? {}} className={"linkButton"}>
    <OutlineButton {...linkButtonProps} />
  </Link>
}

export const OutlineButtonWrapper = styled<any>(BaseButton)<any>`
  user-select: none;
  background-color: white;
  color: ${props => !!props.disabled ? Colours.mildGrey : props.borderColour ? props.borderColour : Colours.blue};

  border: ${props => !!props.hideBorder ? 'none' : `solid 1px ${Colours.blue}`};
  border-color: ${props => !!props.disabled ? Colours.mildGrey : props.borderColour ? props.borderColour : Colours.blue};
  
  .icon {
    color: ${props => !!props.disabled ? Colours.mildGrey : props.borderColour ? props.borderColour : Colours.blue};
  }
  
  &:hover {
    color: ${props => !!props.disabled ? Colours.mildGrey : Colours.darkBlue};
    border-color: ${props => !!props.disabled ? Colours.mildGrey : Colours.darkBlue};
    .icon {
      color: ${props => !!props.disabled ? Colours.mildGrey : Colours.darkBlue};
    }
  }
`
