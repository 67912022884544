import React, { useMemo } from 'react';
import './row.scss';
import { MovementsDailies } from "../StructuralComponents";
import { UsersCarParkBooking, UsersWhereaboutsDay } from "../../../../../../models/movements.models";
import moment, { Moment } from "moment";
import { useSelector } from "react-redux";
import { selectAllWhereaboutsOptions } from "../../../../../../store/ducks/whereaboutsOptions.duck";
import { selectIconPack } from "../../../../../../store/ducks/auth.duck";
import { getListOfDaysBetweenInclusiveToExclusive, isWeekend } from "../../../../../../utils/DateUtils";
import { getWhereaboutsDayWithFallback } from "../../../../../../utils/WhereaboutsHelper";
import { MovementsDay } from "../movements-day/MovementsDay";
import { WhereaboutsOption } from "../../../../../../services/WhereaboutOptions";
import { IconPack } from "../../../../../../models/company.models";
import { canEditPastWhereabouts } from "../../../../../../utils/AccessControl";
import { User } from "../../../../../../models/user.models";

interface Props {
  areUsersMovements: boolean;
  isEditing: boolean;
  userId: string;
  sevenDayWeekEnabled?: boolean;
  carParking: UsersCarParkBooking[];
  dateFrom: string;
  dateTo: string;
  style?: any;
  movements?: UsersWhereaboutsDay[];
  enableSetMovements?: boolean;
  currentUser?: User;
  birthdayDay?: number;
  birthdayMonth?: number;
}

export default function MovementsWeek(props: Props) {
  const {movements, areUsersMovements, userId, isEditing, carParking, dateFrom, dateTo} = props;
  const whereaboutsOptions = useSelector(selectAllWhereaboutsOptions);
  const iconPack = useSelector(selectIconPack);

  const datesToRender = useMemo(() => {
    return getListOfDaysBetweenInclusiveToExclusive(moment(dateFrom), moment(dateTo));
  }, [dateFrom, dateTo]);

  if (!movements) {
    return null;
  }

  return (
    <MovementsDailies style={props?.style ?? {}}>
      {datesToRender.map((date, key) => (<React.Fragment key={key}>
        <MovementsDayWrapper dateToRender={date}
                             movements={movements}
                             currentUser={props.currentUser}
                             enableSetMovements={!!props?.enableSetMovements}
                             carParking={carParking}
                             iconPack={iconPack}
                             userId={userId}
                             isEditing={isEditing}
                             whereaboutsOptions={whereaboutsOptions}
                             isUsersMovement={areUsersMovements}
                             areUsersMovements={areUsersMovements}
                             birthdayDay={props.birthdayDay}
                             birthdayMonth={props.birthdayMonth}
                             includeWeekends={!!props?.sevenDayWeekEnabled} />
      </React.Fragment>))}

    </MovementsDailies>
  )
}

interface MovementsWrapperProps {
  dateToRender: Moment;
  movements: UsersWhereaboutsDay[];
  whereaboutsOptions: WhereaboutsOption[];
  enableSetMovements: boolean;
  carParking: UsersCarParkBooking[];
  iconPack: IconPack;
  userId: string;
  isUsersMovement: boolean;
  areUsersMovements: boolean;
  isEditing: boolean;
  currentUser?: User;
  includeWeekends: boolean;
  birthdayDay?: number;
  birthdayMonth?: number;
}

function MovementsDayWrapper(props: MovementsWrapperProps) {
  const {whereaboutsOptions} = props;

  const whereabouts = useMemo(() => {
    return getWhereaboutsDayWithFallback(props.dateToRender, props.userId, props.movements)
  }, [props.dateToRender, props.movements, props.userId]);

  const getOptionFor = (statusId: number) => {
    return whereaboutsOptions.find((whereaboutsOption: WhereaboutsOption) => whereaboutsOption.id === statusId);
  }

  if (!props.includeWeekends && isWeekend(props.dateToRender)) {
    return null;
  }

  return (
    <MovementsDay isEditing={props.isEditing}
                  amStatus={getOptionFor(whereabouts.amStatusId)}
                  pmStatus={getOptionFor(whereabouts.pmStatusId)}
                  officeAmLocationId={whereabouts.amOfficeId}
                  deskAmLabel={whereabouts.amDeskLabel}
                  officePmLocationId={whereabouts.pmOfficeId}
                  deskPmLabel={whereabouts.pmDeskLabel}
                  amDeskId={whereabouts.amDeskId}
                  pmDeskId={whereabouts.pmDeskId}
                  carParkingRequestedAm={whereabouts.amGeneralParking}
                  carParkingRequestedPm={whereabouts.pmGeneralParking}
                  momentDate={props.dateToRender}
                  isUsersMovement={props.areUsersMovements}
                  enableSetMovements={props.enableSetMovements || canEditPastWhereabouts(whereabouts, props.currentUser)}
                  carParking={props.carParking}
                  iconPack={props.iconPack}
                  userId={props.userId}
                  amCarParkSpaceId={whereabouts.amParkingSpaceId}
                  pmCarParkSpaceId={whereabouts.pmParkingSpaceId}
                  amParkingSpaceLabel={whereabouts.amParkingSpaceLabel}
                  pmParkingSpaceLabel={whereabouts.pmParkingSpaceLabel}
                  isWhereaboutsSaving={!!whereabouts.isWhereaboutsSaving}
                  whereaboutsUpdateFailed={!!whereabouts.whereaboutsUpdateFailed}
                  birthdayDay={props.birthdayDay}
                  birthdayMonth={props.birthdayMonth}
                  amApproved={whereabouts.amApproved}
                  pmApproved={whereabouts.pmApproved} />
  )
}
