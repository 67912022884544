import React, { useEffect, useMemo, useRef, useState } from 'react';
import Dialog from "../../../UI/molecules/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../../store/ducks/dialog.duck";
import DialogHeader from "../../../dialogs/DialogHeader";
import { FlatContentCard } from "../../../UI/atoms/FlatContentCard";
import { BodyLarge, BodyRegular } from "../../../UI/atoms/fonts/Body";
import { Center, Column } from "../../../UI/atoms/StructuralLayout";
import Icon, { IconTypes } from "../../../UI/atoms/icon/Icon";
import styled from "styled-components/macro";
import { PrimaryButton } from "../../../UI/atoms/buttons/PrimaryButton";
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";
import { SwimLane } from "../components/SwimLane";
import { TimeSwimlane } from "../components/TimeSwimlane";
import { Grid, GridContainer } from "../components/meeting-room-grid/StructuralComponents";
import { LoadMeetingRoom } from "../hooks/LoadMeetingRoom";
import { selectAllOfficeEntities } from "../../../../store/ducks/advanceHotDeskingSetup.duck";
import { getFullOfficeTitleById } from "../../../../utils/OfficeHelper";
import { selectActiveDay } from "../../../../store/ducks/editMovements.duck";
import { PRETTY_DATE_FORMAT } from "../../../../utils/DateUtils";
import { LoadMeetingRoomBookings } from "../hooks/LoadMeetingRoomBookings";
import { MeetingRoomBooking, MeetingRoomStatus, RoomBookingSource } from "../models/MeetingRooms";
import { MeetingRoomBookingContentCard } from "../components/MeetingRoomBookingContentCard";
import {
  BORDER_SOLID,
  HALF_HOUR_TIME_SLOT_HEIGHT,
  MEETING_ROOM_SWIMLANE_WIDTH
} from "../components/StructuralComponents";
import { Moment } from "moment/moment";
import { TimeRangeSelector } from "../components/TimeRangeSelector";
import { successNotification, warningNotification } from "../../../../store/ducks/notification.duck";
import { MeetingRoomBookingConfirmation } from "../hooks/MeetingRoomBookingConfirmation";
import TextField from "../../../UI/atoms/TextField";
import { selectCurrentUser, selectIsCalendarSyncActive } from "../../../../store/ducks/auth.duck";
import { User } from "../../../../models/user.models";
import { desktop, tablet } from "../../../UI/atoms/MediaQueries";
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";
import { Switch } from "../../../UI/atoms/Switch";
import { useTranslation } from "react-i18next";
import { MicrosoftCalendarSyncActiveIndicator } from "../../../UI/organisms/MicrosoftCalendarSyncActiveIndicator";
import { MeetingRoomLoadingSpinner } from "../components/MeetingRoomLoadingSpinner";

function getInitialBooking(meetingRoomId: string, activeDay?: Moment, currentUser?: User): MeetingRoomBooking {
  if (!activeDay) throw new Error('No active date')
  const startDate = activeDay;
  const endDate = activeDay;
  return {
    teamTodayMeetingRoomId: meetingRoomId,
    startDateTime: startDate,
    endDateTime: endDate,
    bookerName: !!currentUser ? `${currentUser.firstname} ${currentUser.lastname}` : '',
    name: '',
    status: MeetingRoomStatus.NOT_BOOKED,
    source: RoomBookingSource.TEAM_TODAY,
  }
}

function updateTimeOnDateTimeObject(dateTimeObject: Moment, timeObject: Moment) {
  const dateTime = dateTimeObject.clone();
  dateTime.hour(timeObject.hour());
  dateTime.minutes(timeObject.minutes());
  return dateTime;
}

export function AdvanceMeetingRoomBookingDialog(props: Props) {
  const {meetingRoomId} = props.payload;
  const dispatch = useDispatch();
  const activeDay = useSelector(selectActiveDay);
  const allOffices = useSelector(selectAllOfficeEntities);
  const currentUser = useSelector(selectCurrentUser);
  const calendarSyncActive = useSelector(selectIsCalendarSyncActive);
  const myRef = useRef(null);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(8);
  const [endMinutes, setEndMinutes] = useState(30);
  const [endHours, setEndHours] = useState(8);
  const [addToCalendar, setAddToCalendar] = useState(true);
  const [placeholderBooking, setPlaceholderBooking] = useState<MeetingRoomBooking>(getInitialBooking(meetingRoomId, activeDay, currentUser));

  const {meetingRoomsLoading, meetingRoom} = LoadMeetingRoom(meetingRoomId);
  const {bookingsLoading, bookings} = LoadMeetingRoomBookings(meetingRoom, activeDay);
  const {confirmatonLoading, bookMeeting} = MeetingRoomBookingConfirmation();
  const {t} = useTranslation();

  const officePath = useMemo(() => getFullOfficeTitleById(meetingRoom?.officeId ?? 0, allOffices),[allOffices, meetingRoom?.officeId]);
  const loading = useMemo(() => meetingRoomsLoading && bookingsLoading, [meetingRoomsLoading, bookingsLoading]);

  const isOutlookRoom = !!meetingRoom?.microsoftRoomId;

  const close = () => {
    dispatch(closeDialog());
  }

  const confirmBooking = async () => {
    if (!placeholderBooking) {
      dispatch(warningNotification('No placeholder booking'));
      return;
    }
    if (!meetingRoom) {
      dispatch(warningNotification('No meeting room'));
      return;
    }

    if (placeholderBooking.teamTodayMeetingId) {
      dispatch(warningNotification('Update booking not implemented'));
    } else {
      try {
        await bookMeeting(meetingRoom, placeholderBooking.startDateTime, placeholderBooking.endDateTime, placeholderBooking.name, addToCalendar);
        dispatch(successNotification('Meeting room booked'));
        close();
      } catch (e) {
        throw e;
      }
    }
  }

  const onTimeChange = (startTime: Moment, endTime?: Moment) => {
    if (!endTime) return;
    if (placeholderBooking?.startDateTime.hour() === startTime.hour() && placeholderBooking.startDateTime.minutes() === startTime.minutes() &&
        placeholderBooking?.endDateTime.hour() === endTime.hour() && placeholderBooking.endDateTime.minutes() === endTime.minutes()) {
      return;
    }

    let endTimeAdjusted = endTime.clone();
    if (endTimeAdjusted.isBefore(startTime)) {
      endTimeAdjusted = startTime.clone().add(30, 'minutes');
      setEndMinutes(endTimeAdjusted.minutes());
      setEndHours(endTimeAdjusted.hours())
    }

    setPlaceholderBooking({
      ...placeholderBooking,
      startDateTime: updateTimeOnDateTimeObject(placeholderBooking.startDateTime, startTime),
      endDateTime: updateTimeOnDateTimeObject(placeholderBooking.endDateTime, endTime),
    } as MeetingRoomBooking)
  }

  const onTimeSelect = (hour: number, minutes: number) => {
    const startTime = placeholderBooking.startDateTime.clone();
    startTime.hour(hour);
    startTime.minutes(minutes);
    const endTime = placeholderBooking.endDateTime.clone();
    endTime.hour(hour);
    endTime.minutes(minutes);
    endTime.add(30, 'minutes');

    setHours(startTime.hours());
    setMinutes(startTime.minutes());
    setEndMinutes(endTime.minutes());
    setEndHours(endTime.hours());


    setPlaceholderBooking({
      ...placeholderBooking,
      startDateTime: startTime,
      endDateTime: endTime,
    })
  }

  const onMeetingNameChange = (meetingName: string) => {
    setPlaceholderBooking({
      ...placeholderBooking,
      name: meetingName,
    } as MeetingRoomBooking)
  }

  useEffect(() => {
    if (loading) return;
    try {
      if (myRef) {
        // @ts-ignore
        myRef.current.scrollTop = (HALF_HOUR_TIME_SLOT_HEIGHT * 16) - 10;
      }
    } catch (e) {
    }
  }, [loading]);

  return (
    <BookingDialogWrapper>
      {confirmatonLoading ? <MeetingRoomLoadingSpinner isOutlook={isOutlookRoom} /> : <>
        <DialogWrapper isOpen={true} onClose={close} showLogo={true} >
          {loading ? <Center>
            <LoadingSpinner hideBorder={true} hideText={true} />
          </Center> : <>
            <MeetingRoomContentContainer>

              <MeetingRoomInfoColumn>
                <DialogHeader title={'room-booking-dialog.title'} />

                <SummaryBox>
                  <Icon icon={IconTypes.AppIcon} size={'mediumlarge'} />
                  <Column>
                    <BodyRegular weight={600}>{meetingRoom?.name ?? '???'}</BodyRegular>
                    <BodyRegular>{activeDay?.format(PRETTY_DATE_FORMAT)}</BodyRegular>
                    <BodyRegular>{officePath}</BodyRegular>
                    <BodyRegular>Approx. {meetingRoom?.capacity ?? 0} people</BodyRegular>
                  </Column>

                </SummaryBox>


                {isOutlookRoom && !calendarSyncActive ? <>
                  <BodyLarge weight={600}>{t('room-booking-dialog.cannot-book-outlook-room')}</BodyLarge>
                  <BodyRegular style={{marginBottom: 24}}>{t('room-booking-dialog.cannot-book-outlook-room-description')}</BodyRegular>
                  <MicrosoftCalendarSyncActiveIndicator />
                </> : <>
                  <TextField onChange={onMeetingNameChange} fullWidth={true} label={'room-booking-dialog.meeting-title'} value={placeholderBooking?.name ?? ''} />
                  <Switch value={addToCalendar} onChange={setAddToCalendar} label={'room-booking-dialog.add-to-calendar'} style={{marginBottom: 12}} />
                  <TimeRangeSelector hour={hours} minutes={minutes} toHour={endHours} toMinutes={endMinutes} onTimeChange={onTimeChange} style={{marginBottom: 80}} />
                  <PrimaryButton click={confirmBooking} size={'large'}
                                 text={'room-booking-dialog.confirm-booking'}
                                 fullWidth={true} />

                  <OutlineButton className={'meetingRoomDialog__button'} click={close} text={"button.close"} size={"large"} fullWidth={true} />
                  <div style={{marginBottom: 80}}></div>
                </>}

              </MeetingRoomInfoColumn>
              <MeetingRoomScheduleColumn ref={myRef}>
                <GridContainer>
                  <Grid>
                    <TimeSwimlane />
                    <MeetingSwimLaneContainer>
                      <SwimLane onClick={(hour, minutes) => onTimeSelect(hour, minutes)} />
                      {bookings.map((booking: MeetingRoomBooking, key: number) => <MeetingRoomBookingContentCard key={key} booking={booking} excludeOffset={true} />)}
                      {placeholderBooking && <MeetingRoomBookingContentCard booking={placeholderBooking} excludeOffset={true} />}
                    </MeetingSwimLaneContainer>
                  </Grid>
                </GridContainer>
              </MeetingRoomScheduleColumn>
            </MeetingRoomContentContainer>
          </>}
        </DialogWrapper>
      </>}
    </BookingDialogWrapper>
  )
}

interface Props {
  payload: {
    meetingRoomId: string,
  };
}

const MeetingRoomContentContainer = styled.div`
  display: flex;
  height: inherit;
`

export const DialogWrapper = styled<any>(Dialog)<any>`
`

const MeetingRoomInfoColumn = styled.div`
  height: inherit;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;
  @media (${tablet}) {
    max-width: 55%;
    padding: 18px 24px 18px 64px;
  }
  @media (${desktop}) {
    max-width: 55%;
    padding: 64px 24px 64px 64px;
  }
`

const MeetingRoomScheduleColumn = styled.div`
  display: none;
  height: 670px;
  max-height: 70vh;
  overflow-y: auto;
  flex: 1;
  max-width: 45%;
  min-width: 360px;
  background-color: white;
  margin: 60px 0;
  border-top: 1px solid rgba(0,87,255,0.3);
  .timeline__container .grid-item--header {
    height: 0 !important;
    min-height: 0 !important;
  }
  @media (${tablet}) {
    display: block;
  }
`

const SummaryBox = styled<any>(FlatContentCard)`
  display: flex;
  padding: 24px;
  margin-bottom: 24px;
  i {
    margin-right: 16px;
  }
`

const BookingDialogWrapper = styled.div`
  .dialog {
    height: 730px;
    overflow: hidden;
    padding: 0;
    @media (${tablet}) {
      max-width: 800px;
      min-width: 800px;
      max-height: 80vh;
    }
  }
  .dialog__contentContainer {
    height: 100%;
    max-height: 90vh;
  }
  .dialog__content {
    padding: 0;
    max-width: 100%;
    margin: 0;
    height: 100%;
  }
  .meetingRoomDialog__button {
    margin-top: 24px;

    @media (${tablet}) {
      display: none;
    }
  }
`

const MeetingSwimLaneContainer = styled.div`
  position: relative;
  border: ${BORDER_SOLID};
  width: ${MEETING_ROOM_SWIMLANE_WIDTH}px;
  min-width: ${MEETING_ROOM_SWIMLANE_WIDTH}px;
  max-width: ${MEETING_ROOM_SWIMLANE_WIDTH}px;
`
