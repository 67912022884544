import React, { useEffect, useMemo } from 'react';
import { DATE_FORMAT, PRETTY_DATE_FORMAT } from "../../../../utils/DateUtils";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { refreshBookings, selectedOffice, setSidebarOpen } from "../models/room-finder.duck";
import { selectActiveDay, setActiveDay } from "../../../../store/ducks/editMovements.duck";
import { tablet } from "../../../UI/atoms/MediaQueries";
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";
import { PageTitle } from "../../../UI/atoms/PageTitle";
import DateFlicker, { DateScale } from "../../../UI/molecules/DateFlicker";
import isMobile from "is-mobile";
import { Moment } from "moment";
import { addOrReplaceQueryParam } from "../../../../utils/UrlUtils";

export function MeetingRoomFinderPageHeader(props: Props) {
  const dispatch = useDispatch();
  const office = useSelector(selectedOffice);
  const activeDate = useSelector(selectActiveDay);

  const onFiltersClick = () => {
    dispatch(setSidebarOpen(true));
  }

  const title = useMemo(() => {
    if (office && activeDate) {
      let retVal = office.label;
      if (!isMobile()) {
        retVal += ` - ${activeDate?.format(PRETTY_DATE_FORMAT)}`;
      }
      return retVal;
    }
    return '';
  }, [office, activeDate])

  useEffect(() => {
    if (activeDate) {
      addOrReplaceQueryParam('date', activeDate.format(DATE_FORMAT))
    }
    dispatch(refreshBookings())
  }, [activeDate, dispatch]);

  const onDateChange = (date: Moment) => {
    dispatch(setActiveDay(date));
  }

  return (
    <HeaderRow className={props?.className ?? ''}>
      {office && <PageTitle text={"header.meeting-rooms"} overrideText={title} />}

      <Controls>
        <OutlineButton text={'room-finder.navigation.filters'}
                       size={'medium'}
                       className={"roomFinder__filterButton"}
                       fullWidth={false}
                       click={onFiltersClick} />
        <DateFlicker dateScale={DateScale.Day}
                     dateInTheMiddle={true}
                     showDate={true}
                     date={activeDate}
                     onChange={onDateChange}
                     hideDateText={false}
                     dateFormat={'dddd Do MMMM'}
                     updateGlobalDate={true} />
      </Controls>
    </HeaderRow>
  )
}

interface Props {
  className?: string;
}

const Controls = styled.div`
  .roomFinder__filterButton {
    margin: 8px auto 8px auto;
  }
  @media (${tablet}) {
    display: none;
  }
`

const HeaderRow = styled.div`
  display: flex;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding: 0 16px;
  flex-direction: column;
  h3 {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .goBackButton {
    margin: 0;
    align-self: center;
  }
  .roomFinder__navigation {
    margin: 0 auto;
  }
  .roomFinder__showFilters {
    margin-left: 16px;
    width: 120px;
  }
  .roomFinder__navButton {
    width: 120px;
  }
  .linkButton {
    margin: 0;
  }

  @media (${tablet}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`
