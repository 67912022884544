import React, { useMemo } from 'react';
import styled from "styled-components";
import { HeadlineMedium, HeadlineSmall } from "../../../../UI/atoms/fonts/Headline";
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";
import Colours from "../../../../UI/atoms/Colours";
import { HorizontalSpacer } from "../../../../UI/atoms/VerticalSpacer";
import { PrimaryButton } from "../../../../UI/atoms/buttons/PrimaryButton";
import { useSelector } from "react-redux";
import { selectHolidayUserInfo, selectUsersHolidayInfo } from "../../../../../store/ducks/holidays-v2.duck";
import { selectConfig } from "../../../../../store/ducks/config.duck";
import { useTranslation } from "react-i18next";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import { Permission } from "../../../../../models/user.models";
import { WithUsersLineReports } from "../../../../../hooks/WithUsersLineReports";
import { selectCalendarDate } from "../../../../../store/ducks/dashboard.duck";
import { CustomLeaveInfo } from "./CustomLeaveInfo";

export function HolidaysTakenOverview(props: Props) {
  const usersHolidayInfo = useSelector(selectUsersHolidayInfo);
  const userInfo = useSelector(selectHolidayUserInfo);
  const config = useSelector(selectConfig);
  const currentUser = useSelector(selectCurrentUser);
  const calendarDate = useSelector(selectCalendarDate);
  const {t} = useTranslation();

  const {lineReports} = WithUsersLineReports(currentUser);

  const isHolidayTrackingEnabled = currentUser?.companyEntity.enableHolidayBooking;

  const addHolidayUrl = useMemo(() => {
    if (lineReports.length > 0) {
      return '/holidays/add'
    }

    if (currentUser?.role === Permission.Basic || currentUser?.role === Permission.TeamAdmin) {
      return `/holidays/${currentUser.id}/add`
    } else {
      return '/holidays/add'
    }

  }, [currentUser, lineReports]);

  return (
    <Container data-test={"holidays-taken-overview"}>
      <HeadlineSmall style={{marginBottom: 16}}>{(!userInfo.userId || userInfo.userId === currentUser?.id) ? t('holiday.your-summary') : `${userInfo.firstName} ${userInfo.lastName}`}</HeadlineSmall>
      <BodyRegular style={{marginBottom: -4}} colour={Colours.darkGrey} weight={600}>{t('holiday.days-taken-or-booked')}</BodyRegular>
      <HeadlineMedium style={{marginBottom: 16}}>{usersHolidayInfo.daysTaken}</HeadlineMedium>

      {isHolidayTrackingEnabled && <>
        {config.holidayAllowance &&
          <>
            <HorizontalSpacer style={{marginBottom: 16}} />
            <BodyRegular style={{marginBottom: -4}} colour={Colours.darkGrey} weight={600}>{t('holiday.days-left')}</BodyRegular>
            <HeadlineMedium style={{marginBottom: 16}}>{usersHolidayInfo.daysLeft}</HeadlineMedium>
          </>
        }

        {usersHolidayInfo.customLeaveDays.length > 0 && <CustomLeaveInfo customLeave={usersHolidayInfo.customLeaveDays} />}

        <BodyRegular style={{marginBottom: 4}} colour={Colours.darkGrey} weight={600}>{t('holiday.holiday-year')}</BodyRegular>
        <BodyRegular>{config.getHolidayYearStartDate(calendarDate)?.format('Do MMM YY')} - {config.getHolidayYearEndDate(calendarDate)?.format('Do MMM YY')}</BodyRegular>
      </>}

      {isHolidayTrackingEnabled && <PrimaryButton link={addHolidayUrl}
                                                  dataTest={"add-holidays-button"}
                                                  text={isHolidayTrackingEnabled ? 'button.add-holiday-request' : 'button.add-holiday'}
                                                  fullWidth={true}
                                                  style={{marginBottom: 8, marginTop: 24}}
                                                  size={"small"} />}
    </Container>
  )
}

interface Props {
}

const Container = styled.div`
  width: 100%;
  border: 1px solid ${Colours.mildGrey};
  border-radius: 24px;
  padding: 24px;
  
`
