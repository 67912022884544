import React from 'react';
import { HolidayActivityFill } from "./WallPlannerActivityBlocks";
import { daysBetween } from "../../../../utils/DateUtils";
import { Moment } from "moment";
import { Birthday } from "../../../../models/user.models";
import styled from "styled-components/macro";
import Colours from "../../../UI/atoms/Colours";

export function NonWorkingDayBlock(props: Props) {
  const {activity, firstDateOnCalendar} = props;
  const days = 1;

  return (
    <HolidayActivityFill start={daysBetween(activity.date, firstDateOnCalendar)}
                         days={days}
                         fill={Colours.veryLightGrey}
                         outline={'#A6B4B4'}
                         textColour={Colours.black}
                         title={activity.label}>
      <Contents>
        <IconImage src={"/assets/icons/standard/NotWorking.svg"} alt={""} />
        {props.children && <>{props.children}</>}
      </Contents>
    </HolidayActivityFill>
  )
}

interface Props {
  activity: any;
  children?: any;
  firstDateOnCalendar: Moment;
  weeksInMonth: number;
  birthday?: Birthday;
}

const Contents = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const IconImage = styled.img`
  width: 16px;
  height: 16px;
  justify-self: center;
  align-self: center;
`
