import React, { useEffect, useState } from 'react';
import { BodyRegular } from "../../../../../UI/atoms/fonts/Body";
import { Switch } from "../../../../../UI/atoms/Switch";
import styled from "styled-components/macro";
import Colours from "../../../../../UI/atoms/Colours";

export function AdditionalLeaveOptions(props: Props) {
  const [isLeaveOption, setLeaveOption] = useState(false);
  const [requiresApproval, setRequiresApproval] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);

  useEffect(() => {
    setLeaveOption(props.isLeaveOption)
    setIsPrivate(props.isPrivate)
    setRequiresApproval(props.requiresApproval)
  }, [props]);

  const onRequiresApprovalChange = (value: boolean) => {
    setRequiresApproval(value);
    props.onRequiresApprovalChange(value)
  }

  const onIsPrivateChange = (value: boolean) => {
    setIsPrivate(value);
    props.onIsPrivateChange(value)
  }

  const onIsLeaveOptionChange = (value: boolean) => {
    setLeaveOption(value);
    props.onIsLeaveOptionChange(value)
  }

  return (
    <>
      <OptionRow>
        <Column>
          <BodyRegular weight={600}>Categorise as a leave option</BodyRegular>
          <BodyRegular>Enable if being used to manage leave. Will group leave options together in the menu</BodyRegular>
        </Column>
        <Switch value={isLeaveOption} onChange={onIsLeaveOptionChange} />
      </OptionRow>

      {isLeaveOption && <>
        <OptionsContainer>
          <OptionRow style={{marginLeft: 16}}>
            <Column>
              <BodyRegular weight={600}>Requires approval?</BodyRegular>
              <BodyRegular>Requires a line managers approval</BodyRegular>
            </Column>
            <Switch value={requiresApproval} onChange={onRequiresApprovalChange} />
          </OptionRow>

          <OptionRow style={{marginLeft: 16}}>
            <Column>
              <BodyRegular weight={600}>Is Private?</BodyRegular>
              <BodyRegular>When set this whereabouts will show as "Non working day" to everyone except Company Admins and this user.</BodyRegular>
            </Column>
            <Switch value={isPrivate} onChange={onIsPrivateChange} />
          </OptionRow>

          {/*<OptionRow style={{marginLeft: 16}}>*/}
          {/*  <Column>*/}
          {/*    <BodyRegular weight={600}>Included in holiday allowance?</BodyRegular>*/}
          {/*    <BodyRegular>This will deduct days from the users leave allowance</BodyRegular>*/}
          {/*  </Column>*/}
          {/*  <Switch value={includedInHolidayAllowance} onChange={setIsIncludedInHolidayAllowance} />*/}
          {/*</OptionRow>*/}
        </OptionsContainer>
      </>}
    </>
  )
}

interface Props {
  isLeaveOption: boolean;
  requiresApproval: boolean;
  isPrivate: boolean
  onIsLeaveOptionChange: (value: boolean) => any;
  onRequiresApprovalChange: (value: boolean) => any;
  onIsPrivateChange: (value: boolean) => any;
}

const Column = styled.div`
  max-width: 80%;
`

const OptionRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`

const OptionsContainer = styled.div`
  border-left: 1px solid ${Colours.mildGrey};
  margin-left: 8px;
`


