import React from 'react';
import styled from "styled-components/macro";
import { ApprovalState } from "../../../../../../models/approval-requests.models";
import Colours from "../../../../atoms/Colours";

export function WhereaboutsApprovedIcon(props: Props) {
  const {amApproved, pmApproved} = props;

  if (!amApproved && !pmApproved) {
    return null;
  } else if (amApproved === ApprovalState.approved || pmApproved === ApprovalState.approved) {
    return <StateIcon src={'/assets/icons/approved.svg'} title={'Approved'} />
  } else if (amApproved === ApprovalState.declined || pmApproved === ApprovalState.declined) {
    return <StatePlaceholder colour={Colours.red} title={'Declined'} />
  } else if (amApproved === ApprovalState.pending || pmApproved === ApprovalState.pending) {
    return <StateIcon src={'/assets/icons/pending.svg'} title={'Pending'} />
  } else {
    return null
  }
}

interface Props {
  amApproved?: ApprovalState;
  pmApproved?: ApprovalState;
}

export const StateIcon = styled.img<any>`
  position: absolute;
  right: 21px;
  top: 4px;
  width: 20px;
  height: 20px;
`;


export const StatePlaceholder = styled.div<any>`
  position: absolute;
  right: 21px;
  top: 4px;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  background-color: ${props => props.colour};
`;
