import React, { useMemo } from 'react';
import { MovementDay } from "../../../../models/movements.models";
import styled from "styled-components/macro";
import { WhereaboutsIconOutline, WhereaboutsIconOutlineUnknown } from "../../../UI/atoms/WhereaboutsIconOutline";
import { useSelector } from "react-redux";
import { selectWhereaboutsOptionById } from "../../../../store/ducks/whereaboutsOptions.duck";

export function UsersWhereaboutsIconsRow(props: Props) {
  return (
    <Row>
      {props.whereabouts.map((day, key) => (
        <WhereaboutsIconOutlineWrapper day={day} key={key} />
      ))}
    </Row>
  )
}

function WhereaboutsIconOutlineWrapper(props: {day: MovementDay}) {
  const {day} = props;
  const optionAm = useSelector(state => selectWhereaboutsOptionById(state, day.amStatusId))
  const optionPm = useSelector(state => selectWhereaboutsOptionById(state, day.pmStatusId))
  const activity = useMemo(() => {
    return {
      amOption: optionAm,
      pmOption: optionPm
    }
  }, [optionAm, optionPm]);

  if (!activity) {
    return null;
  } else if (!optionAm || !optionPm) {
    return <WhereaboutsIconOutlineUnknown />
  } else if (optionAm.status === optionPm.status) {
    return <WhereaboutsIconOutline label={activity.amOption.label} image={activity.amOption.image} colour={activity.amOption.colour} />
  } else {
    return <HalfDayRow>
      <WhereaboutsIconOutline label={activity.amOption.label} image={activity.amOption.image} colour={activity.amOption.colour} />
      <WhereaboutsIconOutline label={activity.pmOption.label} image={activity.pmOption.image} colour={activity.pmOption.colour} />
    </HalfDayRow>
  }
}

interface Props {
  whereabouts: MovementDay[]
}

const Row = styled.div`
  img {
    height: 20px;
    width: 20px;
  }
`

export const HalfDayRow = styled.div`
  display: flex;
  margin-right: 16px;
  img {
    margin-right: 2px !important;
  }
`
