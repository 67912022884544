import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../store/ducks/dialog.duck";
import Dialog from "../../UI/molecules/Dialog";
import DialogHeader from "../DialogHeader";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import TextField from "../../UI/atoms/TextField";
import { Switch } from "../../UI/atoms/Switch";
import { SpaceBetweenRow } from "../../UI/atoms/StructuralLayout";
import { BodyRegular } from "../../UI/atoms/fonts/Body";
import {
  createUpdateWhereaboutsOption,
  CustomWhereaboutsType,
  FreeBusyStatus,
  WhereaboutsOption
} from "../../../services/WhereaboutOptions";
import { selectCurrentUser, selectIconPack } from "../../../store/ducks/auth.duck";
import { LoadingSpinner } from "../../UI/atoms/LoadingSpinner";
import {
  clearEditingWhereaboutsOption,
  deleteWhereabouts,
  loadWhereaboutsOptions,
  selectEditingWhereaboutsOption
} from "../../../store/ducks/whereaboutsOptions.duck";
import { WhereaboutsAvailabilityToggle } from "./WhereaboutsAvailabilityToggle";
import { OutlineButton } from "../../UI/atoms/buttons/OutlineButton";
import { WhereaboutsImagePicker } from "./WhereaboutsImagePicker";
import { Spacer } from '../../UI/atoms/VerticalSpacer';
import { failureNotification } from "../../../store/ducks/notification.duck";
import { useTranslation } from "react-i18next";

export function AddCustomWhereaboutsDialog(props: Props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const editingWhereaboutsOption = useSelector(selectEditingWhereaboutsOption);
  const iconPack = useSelector(selectIconPack)
  const {t} = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [colour, setColour] = useState<undefined | string>(undefined);
  const [image, setImage] = useState<undefined | string>(undefined);
  const [enabled, setEnabled] = useState(true);
  const [freeBusyStatus, setFreeBusyStatus] = useState(FreeBusyStatus.FREE);

  const key = useMemo(() => name.toUpperCase().replace(/[^a-z0-9+]+/gi, '_'), [name]);

  useEffect(() => {
    if (freeBusyStatus === FreeBusyStatus.OOF || freeBusyStatus === FreeBusyStatus.BUSY) setColour('grey');
    else if (freeBusyStatus === FreeBusyStatus.WORKING_ELSEWHERE || freeBusyStatus === FreeBusyStatus.TENTATIVE) setColour('yellow');
    else setColour('green');
  }, [freeBusyStatus]);

  useEffect(() => {
    return () => {
      dispatch(clearEditingWhereaboutsOption());
    }
  }, [dispatch]);

  useEffect(() => {
    if (name.length > 16) {
      setName(name.substr(0, 16));
      setNameError('Cannot be longer than 16 characters');
    } else if (name.length < 3) {
      setNameError('Name must be at least 3 characters long');
    } else if (name.length < 16) {
      setNameError('');
    }
  }, [name]);

  useEffect(() => {
    if (editingWhereaboutsOption) {
      setName(editingWhereaboutsOption.label);
      setImage(editingWhereaboutsOption.image);
      setEnabled(editingWhereaboutsOption.enabled);
      setColour(editingWhereaboutsOption.colour);
      setFreeBusyStatus(editingWhereaboutsOption.freeBusyStatus)
    }
  }, [editingWhereaboutsOption]);

  const save = async () => {
    if (!image || !currentUser?.companyEntity || !colour) {
      // TODO Error message
      return;
    }

    setIsLoading(true);
    try {
      const whereaboutsOption: WhereaboutsOption = {
        id: editingWhereaboutsOption?.id ?? 0,
        dataTest: key.toLowerCase(),
        alt: name,
        image: image ?? '',
        key: key,
        companyId: currentUser?.companyEntity.id ?? '',
        label: name,
        enabled: enabled,
        colour: colour ?? '',
        freeBusyStatus: freeBusyStatus,
        // Values to setup
        status: key,
        outlookNote: name,
        freeTextEnabled: false,
        // Values defaulted on server
        slackNote: '',
        pickable: editingWhereaboutsOption?.pickable ?? false,
        required: editingWhereaboutsOption?.required ?? false,

        requiresApproval: false,
        whereaboutsType: CustomWhereaboutsType.CUSTOM,
        isPrivate: false,
      }
      await createUpdateWhereaboutsOption(whereaboutsOption);
      dispatch(loadWhereaboutsOptions(true));
      dispatch(closeDialog());
    } catch (e: any) {
      dispatch(failureNotification('Failed to create custom option'));
    } finally {
      setIsLoading(false);
    }
  }

  const close = () => {
    dispatch(closeDialog());
  }

  const deleteClick = () => {
    if (editingWhereaboutsOption) {
      dispatch(deleteWhereabouts(editingWhereaboutsOption));
      dispatch(closeDialog());
    }
  }

  return (
    <Dialog isOpen={true} onClose={close} showLogo={true}>
      {isLoading && <LoadingSpinner fullScreen={true} />}
      <DialogHeader title="dialog-headers.add-custom-whereabouts" />

      <TextField onChange={setName}
                 error={nameError}
                 dataTest={'whereabouts-name-field'}
                 value={name}
                 label="textinput.whereabouts-name" />
      <WhereaboutsImagePicker color={colour}
                              iconPack={iconPack}
                              freeBusyStatus={freeBusyStatus}
                              onSelect={(icon: string) => setImage(icon)}
                              selectedImage={image} />

      <WhereaboutsAvailabilityToggle onSelect={(val: FreeBusyStatus) => setFreeBusyStatus(val)} selected={freeBusyStatus} />

      <SpaceBetweenRow>
        <BodyRegular>{t('custom-whereabouts-page.enable-whereabouts-option')}</BodyRegular>
        <Switch value={enabled} label={''} onChange={setEnabled} />
      </SpaceBetweenRow>

      <Spacer style={{marginBottom: 16}} />

      <PrimaryButton click={save}
                     text={"button.save"}
                     fullWidth={true}
                     size={'large'}
                     dataTest={'save-custom-whereabouts-button'}
                     style={{marginBottom: 16}}
                     disabled={isLoading || !!nameError} />

      {editingWhereaboutsOption && <OutlineButton click={deleteClick} text={'button.delete'} size={"medium"} fullWidth={true} />}

    </Dialog>
  )
}

interface Props {
}
