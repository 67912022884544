import React from 'react';
import styled from "styled-components/macro";
import { PageTitle } from "../../../../../UI/atoms/PageTitle";
import { BodyRegular } from "../../../../../UI/atoms/fonts/Body";
import { PrimaryButton } from "../../../../../UI/atoms/buttons/PrimaryButton";

export function NoCustomWhereaboutsView(props: Props) {
  return (
    <Container>
      <PageTitle text={"whereabouts.custom-whereabouts"} headlineLarge={true} style={{margin: '0 0 24px 0'}} />
      <Image src={"/assets/images/custom-whereabouts-graphic.svg"} alt={""} />
      <BodyRegular style={{marginBottom: 16}}>Enable your team to plan their working week.</BodyRegular>
      <Row>
        <TickIcon src={"/assets/images/circle-tick.svg"} alt={""} />
        <BodyRegular>Add custom Whereabouts to give your teams more options such as <strong>Offsite</strong>, <strong>Training</strong> or <strong>Conference</strong>.</BodyRegular>
      </Row>
      <Row>
        <TickIcon src={"/assets/images/circle-tick.svg"} alt={""} />
        <BodyRegular>Get <strong>detailed reporting</strong> in the reports section.</BodyRegular>
      </Row>
      <Row style={{marginBottom: 32}}>
        <TickIcon src={"/assets/images/circle-tick.svg"} alt={""} />
        <BodyRegular>Use Team Today to <strong>manage leave</strong> and add <strong>line manager</strong> <strong>authorisation</strong> if you wish.</BodyRegular>
      </Row>

      <PrimaryButton fullWidth={true}
                     text={'whereabouts.add-custom-whereabouts'}
                     style={{marginBottom: 48}}
                     link={'/manage/custom-whereabouts/add'} />

    </Container>
  )
}

interface Props {
}

const Container = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
`

const Image = styled.img`
  width: 70%;
  margin: 0 auto;
  align-self: center;
`
const TickIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`
const Row = styled.div`
  display: flex;
  position: relative;
  align-items: flex-start;
  margin-bottom: 12px;
`
