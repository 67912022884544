import React, { useMemo } from 'react';
import { VisitorBooking } from "../data/visitor-booking-service";
import { BodyVerySmall } from "../../../../../UI/atoms/fonts/Body";
import Colours from "../../../../../UI/atoms/Colours";
import { useSelector } from "react-redux";
import { selectAllOfficeEntities } from "../../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { findOfficeInOffices } from "../../../../../../utils/OfficeHelper";

export function OfficeAndDeskVisitorBookingLabel(props: Props) {
  const {booking} = props;
  const allOffices = useSelector(selectAllOfficeEntities);

  const label = useMemo(() => {
    const amDeskLabel = booking.amDeskLabel;
    const pmDeskLabel = booking.pmDeskLabel;
    if (booking.whereaboutsDay) {
      const whereaboutsDay = booking.whereaboutsDay;

      if (!whereaboutsDay.amDeskId && !whereaboutsDay.pmDeskId) {
        const office = findOfficeInOffices(allOffices, whereaboutsDay.amOfficeId);
        return office?.label;
      }

      if (whereaboutsDay.amDeskId && whereaboutsDay.pmDeskId) {
        if (whereaboutsDay.amDeskId === whereaboutsDay.pmDeskId) {
          // Same all day
          const office = findOfficeInOffices(allOffices, whereaboutsDay.amOfficeId);
          if (office) {
            return `${amDeskLabel}, ${office.label}`;
          } else {
            return amDeskLabel;
          }
        } else {
          // Booked all day but different desks
          // This should not be possible
          return 'Error'
        }
      } else if (whereaboutsDay.amDeskId) {
        const office = findOfficeInOffices(allOffices, whereaboutsDay.amOfficeId);
        if (office) {
          return `${amDeskLabel}, ${office.label} (AM only)`;
        } else {
          return amDeskLabel + ' (AM only)';
        }
      } else if (whereaboutsDay.pmDeskId) {
        const office = findOfficeInOffices(allOffices, whereaboutsDay.pmOfficeId);
        if (office) {
          return `${pmDeskLabel}, ${office.label} (PM only)`;
        } else {
          return pmDeskLabel + ' (PM only)';
        }
      }
    }
  }, [allOffices, booking.amDeskLabel, booking.pmDeskLabel, booking.whereaboutsDay]);

  return (
    <>
      {label && <BodyVerySmall weight={400} colour={Colours.darkGrey}>{label}</BodyVerySmall>}
    </>
  )
}

interface Props {
  booking: VisitorBooking;
}
