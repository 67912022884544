import React from 'react';
import styled from "styled-components";
import Dialog from "../../../../UI/molecules/Dialog";
import { PrimaryButton } from "../../../../UI/atoms/buttons/PrimaryButton";
import Calendar from "../../../../UI/molecules/calendar/Calendar";
import { selectCalendarDate, setCalendarDate } from "../../../../../store/ducks/dashboard.duck";
import { OfficeListSidebar } from "../daily-office-list-view/OfficeListSidebar";
import { closeDialog, DialogIdentifiers, openDialogWithPayload } from "../../../../../store/ducks/dialog.duck";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedOffice } from "../../../../../store/ducks/officeView.duck";
import { useTranslation } from "react-i18next";
import { HeadlineSmall } from "../../../../UI/atoms/fonts/Headline";

export function VisitorBookingFilterDialog(props: Props) {
  const dispatch = useDispatch();
  const calendarDate = useSelector(selectCalendarDate);
  const selectedOffice = useSelector(selectSelectedOffice);
  const {t} = useTranslation();
  const close = () => {
    dispatch(closeDialog());
  }
  return (
    <DialogWrapper isOpen={true} onClose={close} showLogo={false}>
      <HeadlineSmall style={{alignSelf: 'flex-start'}}>{t('visitor-booking.filter')}</HeadlineSmall>

      <OfficeListSidebar hideUsageChart={true} style={{width: '100%'}} />

      <Calendar onDateSelect={(date) => dispatch(setCalendarDate(date))}
                activeDate={calendarDate}
                initialDate={calendarDate}
                style={{marginBottom: 24}} />

      <PrimaryButton fullWidth={true} click={() => dispatch(openDialogWithPayload({
        payload: { date: calendarDate, office: selectedOffice },
        activeDialog: DialogIdentifiers.AddVisitorDialog
      }))} text={'visitor-booking.add-visitor'} />
    </DialogWrapper>
  )
}

interface Props {
}

export const DialogWrapper = styled<any>(Dialog)<any>`
  &.dialog {
    max-width: 100%;
    min-height: 100vh;
    min-width: 100vw;
  }
  .dialog__contentContainer, .dialog__content {
    min-height: 410px;
  }
  .dialog__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    align-items: center;
  }
  .saveChangesButton {
    margin-top: auto;
  }
`
