import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import Dialog from "../../UI/molecules/Dialog";
import { smallTablet } from "../../UI/atoms/MediaQueries";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../store/ducks/dialog.duck";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import { HeadlineMedium } from "../../UI/atoms/fonts/Headline";
import Colours from "../../UI/atoms/Colours";
import { BodyLarge } from "../../UI/atoms/fonts/Body";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { AccountType } from "../../../models/company.models";
import { isInTeams } from "../../../utils/TeamsUtils";

export enum UsageTypeKey {
  CustomWhereabouts
}

const usageMatrix = [
  {
    key: UsageTypeKey.CustomWhereabouts,
    titleLabel: 'Custom Whereabouts',
    contentLabel: 'custom whereabouts option',
    limits: [
      { accountType: AccountType.STANDARD, limit: 1, },
      { accountType: AccountType.PROFESSIONAL, limit: 6, },
      { accountType: AccountType.PREMIUM, limit: 32, },
      { accountType: AccountType.FREE_TRIAL, limit: 32, },
    ]
  }
]

export function hasUsageLimitBeenReached(usageType: UsageTypeKey, accountType: AccountType, count: number): boolean {
  const usage = usageMatrix.find(um => um.key === usageType);
  if (usage) {
    const limit = usage.limits.find(l => l.accountType === accountType);
    if (limit) {
      return count >= limit.limit;
    }
  }
  return false;
}

export function UsageLimitReachedDialog(props: Props) {
  const usageKey = props.payload?.usageType ?? UsageTypeKey.CustomWhereabouts;
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const accountType = user?.companyEntity.accountType ?? AccountType.STANDARD;

  const usageType = useMemo(() => {
    return usageMatrix.find(u => u.key === usageKey);
  }, [usageKey]);

  const usageLimit = useMemo(() => {
    return (usageType?.limits ?? []).find(l => l.accountType === accountType);
  }, [accountType, usageType?.limits]);

  const accountTypeLabel = useMemo(() => {
    switch (accountType) {
      case AccountType.FREE_TRIAL: return 'Free Plan';
      case AccountType.PREMIUM: return 'Premium Plan';
      case AccountType.PROFESSIONAL: return 'Professional Plan';
      case AccountType.STANDARD: return 'Standard Plan';
    }
  }, [accountType]);

  const close = () => {
    dispatch(closeDialog());
  }

  if (!usageType) {
    return null;
  }

  return (
    <DialogWrapper isOpen={true} onClose={close}>
      <WhereaboutsImageGraphic src={"/assets/images/whereabouts-graphic.svg"} alt={""} />
      {!isInTeams() && <HeadlineMedium style={{marginBottom: 0}} color={Colours.blue}>{accountTypeLabel}:</HeadlineMedium>}
      <HeadlineMedium style={{marginBottom: 8}}>{usageType.titleLabel} Limit Reached</HeadlineMedium>
      {!isInTeams() && usageLimit && <>
        <BodyLarge>On the <strong>{accountTypeLabel}</strong> you can only add {usageLimit.limit} {usageType.contentLabel}.</BodyLarge>
      </>}
      <BodyLarge style={{marginBottom: 36}}>To add more please contact <a href="mailto:hello@team-today.com">hello@team-today.com</a></BodyLarge>
      <PrimaryButton click={close} text={'button.done'} fullWidth={true} size={'large'} />
    </DialogWrapper>
  )
}

interface Props {
  payload: {usageType?: UsageTypeKey};
}

const WhereaboutsImageGraphic = styled.img`
  display: flex;
  justify-self: center;
  margin-bottom: 16px;
`

const DialogWrapper = styled<any>(Dialog)<any>`
  width: 100%;
  
  p {
    
  }

  @media (${smallTablet}) {
    width: 550px;
  }
  .dialog__title {
    text-align: center;
    margin: 0 auto 32px auto;
  }
  .dialog__content {
    width: 100%;
    margin: 0;
    max-width: 100%;
  }
  .dialog__contentContainer {
    overflow-x: hidden;
    overflow-y: auto;
    @media (${smallTablet}) {
      height: 100%;
      max-height: 100%;
      overflow: auto;
    }
  }
`;
