import { agent } from "../../../../../../services/agent";
import { Period, WhereaboutsDayV2 } from "../../../../../../models/movements.models";

export interface CreateVisitorBookingRequest {
  date: string;
  visitingUserId: string;
  visitorName: string;
  companyName: string;
  dueInHours: number;
  dueInMinutes: number;
  visitingOfficeId: number;
  amDeskId?: number;
  pmDeskId?: number;
  amParkingSpaceId?: number;
  pmParkingSpaceId?: number
}

export async function createVisitorBooking(request: CreateVisitorBookingRequest): Promise<any> {
  await agent.requests.post('/api/visitor-booking/new', request, true);
}

export async function getVisitorBookings(officeId: number, dateString: string): Promise<VisitorBooking[]> {
  return await agent.requests.get(`/api/visitor-booking/${officeId}?date=${dateString}`, true);
}

export async function bookVisitorDeskRequest(visitorId: number, dateString: string, sideOfDay: Period, officeId?: number, deskId?: number, carParkingSpaceId?: number): Promise<VisitorBooking[]> {
  const request = {
    officeId: officeId,
    deskId: deskId,
    carParkId: carParkingSpaceId,
    visitorId: visitorId,
    dateString: dateString,
    sideOfDay: sideOfDay,
  }
  return await agent.requests.post(`/api/visitor-booking/book-desk`, request, true);
}

export async function cancelVisitorDeskRequest(visitorId: number, dateString: string): Promise<VisitorBooking[]> {
  const request = {
    visitorId: visitorId,
    dateString: dateString,
  }
  return await agent.requests.post(`/api/visitor-booking/cancel-desk`, request, true);
}

export async function signVisitorIn(bookingId: number): Promise<VisitorBooking> {
  return await agent.requests.post(`/api/visitor-booking/sign-in`, {visitorBookingId: bookingId}, true);
}

export async function signVisitorOut(bookingId: number): Promise<VisitorBooking> {
  return await agent.requests.post(`/api/visitor-booking/sign-out`, {visitorBookingId: bookingId}, true);
}

export async function getVisitorCount(dateString: string, officeId: number): Promise<VisitorCount> {
  return await agent.requests.get(`/api/visitor-booking/visitor-count/${officeId}/${dateString}`, true);
}

export interface VisitorBooking {
  id: number;
  visitingUserId: string;
  visitingUserName: string;
  visitingUserTeamName: string;
  officeId: number;
  date: string;
  visitorName: string;
  companyName: string;
  timeDueHours: number;
  timeDueMinutes: number;
  signInState: VisitorSignInState;

  amDeskId: number;
  pmDeskId: number;
  amDeskLabel: string;
  pmDeskLabel: string;

  amParkingSpaceId?: number;
  pmParkingSpaceId?: number

  bookedInByUserId: string;
  bookedInByUserName: string;
  checkedInTime: any;

  bookedOutByUserId: string;
  bookedOutByUserName: string;
  signedOutTime: any;

  whereaboutsDay?: WhereaboutsDayV2;
}

export enum VisitorSignInState {
  NONE = 'NONE',
  CHECKED_IN = 'CHECKED_IN',
  CHECKED_OUT = 'CHECKED_OUT',
}

export interface VisitorCount {
  count: number;
}
