import React from 'react';
import styled from "styled-components/macro";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import { SpaceBetweenRow } from "../../settings/StyleComponents";
import Colours from "../../../UI/atoms/Colours";
import { useSelector } from "react-redux";
import { NonWorkingDays } from "../../../../services/HolidayService";
import { iconPackPath } from "../../../../utils/WhereaboutsHelper";
import { FreeBusyStatus } from "../../../../services/WhereaboutOptions";
import { selectIconPack } from "../../../../store/ducks/auth.duck";

export enum PopupPosition {
  Left, Right, None,
}

export function NonWorkingDayInfoPopup(props: Props) {
  const iconPack = useSelector(selectIconPack);
  const popupPositioning = props.popupPositioning ?? PopupPosition.Left;

  return (
    <HolidayInfoPopupWrapper className="popup" popupPositioning={popupPositioning}>
      <SpaceBetweenRow style={{alignItems: 'center', marginBottom: 4}}>
        <BodyRegular colour={Colours.darkGreen} weight={600}>Non working day</BodyRegular>
        <LeaveImage src={iconPackPath(iconPack, 'NotWorking.svg', FreeBusyStatus.FREE)} alt={""} />
      </SpaceBetweenRow>

    </HolidayInfoPopupWrapper>
  )
}

interface Props {
  nonWorkingDay: NonWorkingDays;
  popupPositioning?: PopupPosition;
}

const HolidayInfoPopupWrapper = styled.div<any>`
  display: none;
  flex-direction: column;
  max-width: 448px;
  min-width: 300px;
  min-height: 50px;
  padding: 24px;
  position: absolute;
  top: 30px;
  left: ${props => props.popupPositioning === PopupPosition.Left ? 0 : 'auto'};
  right: ${props => props.popupPositioning === PopupPosition.Right ? 0 : 'auto'};
  z-index: 999999;
  background: #FFFFFF;
  box-shadow: 0 2px 8px 2px rgba(76, 109, 172, 0.15);
  border-radius: 24px;
  cursor: default;
  p {
    max-width: 100%;
  }
`
const LeaveImage = styled.img`
  max-width: 40px;
  max-height: 40px;
`
