import React from 'react';
import { Column } from "../../../UI/atoms/StructuralLayout";
import { useTranslation } from "react-i18next";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import { Checkbox } from "../../../UI/atoms/Checkbox";
import { MeetingRoomFacilities } from "../models/MeetingRooms";
import { useDispatch, useSelector } from "react-redux";
import { selectedMeetingRoomFacilities, setMeetingRoomFacilities } from "../models/room-finder.duck";

export function MeetingRoomFacilitiesOptions(props: Props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const selectedFacilities = useSelector(selectedMeetingRoomFacilities);

  const onChange = (val: boolean, facility: MeetingRoomFacilities) => {
    if (val && !selectedFacilities.includes(facility)) {
      dispatch(setMeetingRoomFacilities([...selectedFacilities, facility]));
    } else {
      dispatch(setMeetingRoomFacilities(selectedFacilities.filter((sf => sf !== facility))));
    }
  }

  return (
    <Column style={props?.style ?? {}}>
      <BodyRegular weight={600} style={{marginBottom: 16}}>{t('room-finder.facilities.title')}</BodyRegular>

      <Checkbox value={selectedFacilities.includes(MeetingRoomFacilities.TV)}
                style={{marginBottom: 12}}
                onChange={(val: boolean) => onChange(val, MeetingRoomFacilities.TV)}
                text={'room-finder.facilities.tv'} />

      <Checkbox value={selectedFacilities.includes(MeetingRoomFacilities.ConferenceCall)}
                style={{marginBottom: 12}}
                onChange={(val: boolean) => onChange(val, MeetingRoomFacilities.ConferenceCall)}
                text={'room-finder.facilities.conference-call'} />

      <Checkbox value={selectedFacilities.includes(MeetingRoomFacilities.WhiteBoard)}
                style={{marginBottom: 12}}
                onChange={(val: boolean) => onChange(val, MeetingRoomFacilities.WhiteBoard)}
                text={'room-finder.facilities.whiteboard'} />

      <Checkbox value={selectedFacilities.includes(MeetingRoomFacilities.ChargePoints)}
                style={{marginBottom: 12}}
                onChange={(val: boolean) => onChange(val, MeetingRoomFacilities.ChargePoints)}
                text={'room-finder.facilities.charge-points'} />


    </Column>
  )
}

interface Props {
  style?: any;
}
