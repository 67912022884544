import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearRegistration,
  selectAuthErrorMessage,
  selectCurrentUser,
  setRegisterAuthType
} from '../../../store/ducks/auth.duck';
import { Redirect } from 'react-router';
import { selectIsLoading, selectIsSuccessful } from '../../../store/ducks/registration.duck';
import AuthWrapper from './components/AuthWrapper';
import { BodyRegular } from '../../UI/atoms/fonts/Body';
import { DialogIdentifiers, openDialog } from '../../../store/ducks/dialog.duck';
import { AuthType } from "../../../utils/AuthUtils";
import ReactGA from 'react-ga';
import { isInTeams } from "../../../utils/TeamsUtils";
import { clearLastPath, getParameterByName } from "../../../utils/UrlUtils";
import { RegisterErrorMessage } from "../../UI/organisms/auth/RegisterErrorMessage";
import { OutlineButton } from "../../UI/atoms/buttons/OutlineButton";
import Colours from "../../UI/atoms/Colours";
import { Trans, useTranslation } from "react-i18next";
import { IconTypes } from "../../UI/atoms/icon/Icon";


export default function Register() {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const isLoading = useSelector(selectIsLoading);
  const isSuccessful = useSelector(selectIsSuccessful);

  useEffect(() => {
    document.body.style.backgroundColor = '#FAFAFA';
    clearLastPath();
    return () => {
      dispatch(clearRegistration());
    }
  }, [dispatch]);

  useEffect(() => {
    try {
      // @ts-ignore
      if (window.gtag) {
        // @ts-ignore
        window.gtag('config', 'AW-10781300539');
        // @ts-ignore
        window.gtag('event', 'conversion', {'send_to': 'AW-10781300539/5vTlCL7M_LgZELuu9pQo'});

      }
    } catch (e) {
    }
  }, []);

  if (currentUser) {
    return <Redirect to="/whereabouts/team"/>
  }

  if (isSuccessful) {
    return <Success/>
  }

  if (isLoading) {
    return <Processing/>
  }

  return <RegistrationForm/>
}

function RegistrationForm() {
  const dispatch = useDispatch();
  const errorMessage = useSelector(selectAuthErrorMessage);

  const registerWithMs = () => {
    dispatch(setRegisterAuthType(AuthType.Microsoft));
    dispatch(openDialog(DialogIdentifiers.SignUpTermsDialog));
    ReactGA.event({category: 'NewUser', action: 'Register with Microsoft'});
  }

  const registerWithGoogle = async (response: any) => {
    dispatch(setRegisterAuthType(AuthType.Google));
    dispatch(openDialog(DialogIdentifiers.SignUpTermsDialog));
    ReactGA.event({category: 'NewUser', action: 'Register with Google'});
  }

  const appendToLink = useMemo(() => {
    const ad = getParameterByName('ad', window.location.href);
    if (ad) {
      return '?ad=' + ad;
    }
    return ''
  }, [])

  useEffect(() => {
    ReactGA.event({category: 'NewUser', action: 'Register page'});
  }, []);

  return (
    <AuthWrapper dataTest="register" title="register.title">

      <RegisterErrorMessage errorMessage={errorMessage} />

      <BodyRegular style={{marginBottom: 24}}><Trans i18nKey={'register.best-experience-signup'} /></BodyRegular>

      <OutlineButton dataTest="outlookLogin"
                     className="login__button"
                     text="register.office365"
                     click={registerWithMs}
                     borderColour={Colours.black}
                     fullWidth={true}
                     size="large"
                     imageUrl="/assets/icons/microsoft-office-logo.svg"/>

      {!isInTeams() && <OutlineButton dataTest="googleLogin"
                                                          borderColour={Colours.black}
                                                          className="login__button"
                                                          text="register.google"
                                                          click={registerWithGoogle}
                                                          fullWidth={true}
                                                          size="large"
                                                          imageUrl="/assets/icons/google-logo.svg"/>}

      <OutlineButton text="register.email"
                     link={`/register/team-today${appendToLink}`}
                     borderColour={Colours.black}
                     className="login__button--teamToday"
                     dataTest="signUpWithPassword"
                     imageUrl="/assets/icons/font/Email.svg"
                     fullWidth={true}
                     size="large" />
    </AuthWrapper>
  )
}

function Processing() {
  return (
    <AuthWrapper dataTest="register-processing" spinner={true}>
    </AuthWrapper>
  )
}

function Success() {
  const {t} = useTranslation();
  return (
    <AuthWrapper dataTest="register-success" title="login.email-sent" icon={IconTypes.Tick}>
      <BodyRegular>{t('follow-the-link')}</BodyRegular>
    </AuthWrapper>
  )
}
