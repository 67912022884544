import React from 'react';
import styled from "styled-components/macro";

export function WhereaboutsHeaderImage(props: Props) {

  if (!props.imageUrl) {
    return null;
  }

  return (
    <Container style={props?.style ?? {}}>
      <ImageMount>
        <Image src={props?.imageUrl} />
      </ImageMount>
    </Container>
  )
}

interface Props {
  imageUrl?: string;
  style?: any;
}

const ImageMount = styled.div`
  border-radius: 16px;
  background: var(--Neutrals-White, #FFF);
  width: 100px;
  height: 100px;
  transform: rotate(-9deg);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 12px 8px 13px 0.67px #FFCA40;
`

const Image = styled.img`
`

const Container = styled.div`
  width: 205px;
  height: 133px;
  transform: rotate(9deg);
  border-radius: 12px;
  background: var(--Yellow-Grad, linear-gradient(0deg, #FFEA2F 0.03%, #FCEF7F 10.71%));
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 32px auto;
`
