import React, { useEffect, useState } from 'react';
import { HeadlineLarge, HeadlineSmall } from '../../../UI/atoms/fonts/Headline';
import { useDispatch, useSelector } from 'react-redux';
import { BodyLarge, BodyRegular } from "../../../UI/atoms/fonts/Body";
import Icon, { IconTypes } from "../../../UI/atoms/icon/Icon";
import { SpaceBetweenRow } from "../StyleComponents";
import Colours from "../../../UI/atoms/Colours";
import CalendarSwitch from '../../../UI/molecules/CalendarSwitch';
import { redirectTo, selectCurrentUser, updateCurrentUser } from "../../../../store/ducks/auth.duck";
import { GoBackButton } from "../../../UI/atoms/buttons/GoBackButton";
import { Switch } from "../../../UI/atoms/Switch";
import { updateWhereaboutsRemindersRequest } from "../../../../services/UserService";
import { failureNotification, successNotification } from "../../../../store/ducks/notification.duck";
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";
import { FlatContentCard } from "../../../UI/atoms/FlatContentCard";
import { UsersFavouriteOfficesAndDesksList } from "./personal-preferences-page/UsersFavouriteOfficesAndDesksList";
import { Trans, useTranslation } from "react-i18next";
import { UsersDefaultOffice } from "./personal-preferences-page/UsersDefaultOffice";
import { UsersCarParkingDetails } from "./personal-preferences-page/UsersCarParkingDetails";
import { deepLinkTo, isInTeams } from "../../../../utils/TeamsUtils";
import { teamsTabs, uiUrl } from "../../../../services/EnvironmentVariables";
import { BirthdaySection } from "../components/BirthdaySection";
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";
import i18next from "i18next";
import { IsSuperAdmin } from "../../../../hooks/IsSuperAdmin";

export default function PersonalPreferencesPage() {
  const dispatch = useDispatch();
  const current = useSelector(selectCurrentUser);
  const [emailSubscribed, setEmailSubscribed] = useState(false);
  const [marketingEmailsEnabled, setMarketingEmailsEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();

  const isSuperAdminEnabled = IsSuperAdmin();

  useEffect(() => {
    setEmailSubscribed(!!current?.allowWhereaboutsReminders);
    setMarketingEmailsEnabled(!!current?.allowMarketingEmails);
  }, [current]);

  const changeLanguage = (lng: string) => {
    i18next.changeLanguage(lng, (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      t('key');
    });
  }

  const updateWhereaboutsReminders = async (allowWhereaboutsReminders: boolean, allowMarketingEmails: boolean) => {
    setLoading(true);
    try {
      await updateWhereaboutsRemindersRequest(allowWhereaboutsReminders, allowMarketingEmails);
      dispatch(successNotification('successfully updated whereabouts reminder'));
      dispatch(updateCurrentUser(true));
      setEmailSubscribed(allowWhereaboutsReminders);
      setMarketingEmailsEnabled(allowMarketingEmails);
    } catch {
      dispatch(failureNotification('Failed to update whereabouts reminder'));
    }
    setLoading(false);
  }

  const onBackClick = () => {
    if (isInTeams()) {
      const externalUrl = `${uiUrl}/manage`
      deepLinkTo(teamsTabs.settings, externalUrl);
    } else {
      dispatch(redirectTo('/manage'))
    }
  }

  return (
    <>
      {loading && <LoadingSpinner fullScreen={true}/>}
      <GoBackButton onClick={onBackClick}/>
      <HeadlineLarge style={{marginBottom: 24}}>{t('settings.personal-pref')}</HeadlineLarge>
      <BodyRegular style={{marginBottom: 32}}>{t('settings.personal-pref-desc')}</BodyRegular>

      <HeadlineSmall style={{marginBottom: 24}}>{t('personal-perf.office-perf')}</HeadlineSmall>

      <UsersFavouriteOfficesAndDesksList />
      <UsersDefaultOffice />
      <UsersCarParkingDetails />

      <HeadlineSmall style={{marginBottom: 24}}>{t('personal-perf.other-perf')}</HeadlineSmall>

      <BirthdaySection userId={current?.id}
                       birthdayDay={current?.birthdayDay}
                       birthdayMonth={current?.birthdayMonth} />

      <div style={{marginTop: 24, marginBottom: 24, paddingBottom: 16, borderBottom: `1px solid ${Colours.mildGrey}`}}>
        <Icon icon={IconTypes.Calendar} size="mediumlarge" style={{marginBottom: 8}}/>
        <SpaceBetweenRow style={{marginBottom: 12}}>
          <BodyLarge colour={Colours.blue}>{t('settings.email-reminder')}</BodyLarge>
          <Switch value={emailSubscribed}
                  onChange={(val: boolean) => updateWhereaboutsReminders(val, marketingEmailsEnabled)} label=""/>
        </SpaceBetweenRow>
        <BodyRegular>{t('settings.email-reminder-desc')}</BodyRegular>
      </div>

      <div style={{marginBottom: 24, paddingBottom: 16, borderBottom: `1px solid ${Colours.mildGrey}`}}>
        <Icon icon={IconTypes.Calendar} size="mediumlarge" style={{marginBottom: 8}}/>
        <SpaceBetweenRow style={{marginBottom: 12}}>
          <BodyLarge colour={Colours.blue}>{t('settings.calendar-link')}</BodyLarge>
          <CalendarSwitch/>
        </SpaceBetweenRow>
        <FlatContentCard style={{padding: 24, marginBottom: 24}}>
          <BodyRegular>{t('settings.calendar-link-tip')}</BodyRegular>
        </FlatContentCard>
        <BodyRegular><Trans i18nKey={'settings.calendar-link-desc'} /></BodyRegular>
      </div>

      <div style={{marginBottom: 16, borderBottom: `1px solid ${Colours.mildGrey}`}}>
        <Icon icon={IconTypes.Email} size="mediumlarge"/>
        <SpaceBetweenRow style={{marginBottom: 12}}>
          <BodyLarge colour={Colours.blue}>{t('settings.marketing-emails')}</BodyLarge>
          <Switch value={marketingEmailsEnabled}
                  onChange={(val: boolean) => updateWhereaboutsReminders(emailSubscribed, val)} label=""/>
        </SpaceBetweenRow>
        <BodyRegular>{t('settings.marketing-emails-desc')}</BodyRegular>
      </div>

      {isSuperAdminEnabled && <>
        <div>
          <Icon icon={IconTypes.Flag} size="mediumlarge" style={{marginBottom: 8}}/>
          <BodyLarge colour={Colours.blue} style={{marginBottom: 12}}>{t('settings.languages')}</BodyLarge>
          <OutlineButton text={"English"} click={() => changeLanguage('en')} style={{marginBottom: 8}} />
          <OutlineButton text={"French"} click={() => changeLanguage('fr')} />
          <OutlineButton text={"German"} click={() => changeLanguage('de')} />
        </div>
      </>}

    </>
  );
}

