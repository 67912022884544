import React, { useMemo } from 'react';
import Dialog from "../UI/molecules/Dialog";
import { closeDialog } from "../../store/ducks/dialog.duck";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { smallTablet } from "../UI/atoms/MediaQueries";
import Colours from "../UI/atoms/Colours";
import DialogHeader from "./DialogHeader";
import { selectAllWhereaboutsOptions } from "../../store/ducks/whereaboutsOptions.duck";
import { CustomWhereaboutsType, FreeBusyStatus, WhereaboutsOption } from "../../services/WhereaboutOptions";
import { BodyRegular, BodyVerySmall } from "../UI/atoms/fonts/Body";
import { selectIconPack } from "../../store/ducks/auth.duck";
import { iconPackPath } from "../../utils/WhereaboutsHelper";
import { Column } from "../UI/atoms/StructuralLayout";

export function SelectLeaveTypeDialog(props: Props) {
  const dispatch = useDispatch();
  const iconPack = useSelector(selectIconPack);
  const whereaboutsOptions = useSelector(selectAllWhereaboutsOptions);

  const leaveOptions: WhereaboutsOption[] = useMemo(() => {
    return whereaboutsOptions
      .filter(wo => wo.whereaboutsType === CustomWhereaboutsType.LEAVE)
      .filter(wo => wo.enabled)
      .filter(wo => wo.pickable);
  }, [whereaboutsOptions]);

  const onSelect = (wo: WhereaboutsOption) => {
    props.payload.onSelect(wo);
    dispatch(closeDialog());
  }

  return (
    <DialogWrapper isOpen={true} onClose={() => dispatch(closeDialog())}>
      <DialogHeader title="leave-type-dialog.title" />
      <OptionsContainer>
        {leaveOptions.map((option, key) => <OptionWrapper key={key} onClick={() => onSelect(option)}>
          <IconImage src={iconPackPath(iconPack, option.image, FreeBusyStatus.BUSY)} />
          <Column>
            <BodyRegular colour={Colours.darkGrey} weight={600}>{option.label}</BodyRegular>
            {option.isPrivate && <BodyVerySmall colour={Colours.darkGrey} title={'This is only visible to you, line managers, and company admins.'}>Private</BodyVerySmall>}
          </Column>
        </OptionWrapper>)}
      </OptionsContainer>
    </DialogWrapper>
  )
}

interface Props {
  payload: {
    onSelect: (wo: WhereaboutsOption) => any;
  }
}

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const IconImage = styled.img`
  width: 40px;
  height: 40px;
`

const OptionWrapper = styled.div`
  border-radius: 8px;
  border: 0.5px solid var(--Neutrals-Mid-grey, #BCC6C7);
  background: var(--Neutrals-White, #FFF);
  width: 48%;
  max-width: 48%;
  min-width: 48%;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${Colours.veryLightGrey};
  }
  p {
    display: inline-flex;
    margin-left: 12px;
  }
  &:nth-child(odd) {
    margin-right: 1%;
  }
  &:nth-child(even) {
    margin-left: 1%;
  }
`

const DialogWrapper = styled<any>(Dialog)<any>`
  width: 100%;
  height: 80vh;
  max-height: 80vh;

  @media (${smallTablet}) {
    width: 960px;
  }
  .dialog__title {
    text-align: center;
    margin: 0 auto 32px auto;
  }
  .dialog__content {
    width: 100%;
    margin: 0;
    max-width: 100%;
  }
  .dialog__contentContainer {
    overflow-x: hidden;
    overflow-y: auto;
    @media (${smallTablet}) {
      height: 100%;
      max-height: 100%;
      overflow: auto;
    }
  }
  .domainBasedSignup__section {
    border: none !important;
    border-bottom: 1px solid ${Colours.lightGrey} !important;
    margin-bottom: 32px;
    padding-bottom: 16px;
  }
`;
