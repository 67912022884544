import React from 'react';
import { HeadlineMedium } from "../../UI/atoms/fonts/Headline";
import styled from "styled-components/macro";
import Dialog from "../../UI/molecules/Dialog";
import { smallTablet } from "../../UI/atoms/MediaQueries";
import { useTranslation } from "react-i18next";
import { OutlineButton } from "../../UI/atoms/buttons/OutlineButton";
import { Period } from "../../../models/movements.models";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../../store/ducks/dialog.duck";

export function SideOfDaySelectorDialog(props: Props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const onClick = (sideOfDay: Period) => {
    close();
    props.payload.onSideOfDaySelected(sideOfDay);
  }
  const close = () => {
    dispatch(closeDialog());
  }
  return (
    <DialogWrapper isOpen={true} onClose={close}>
      <WhereaboutsImageGraphic src={"/assets/icons/standard/office.svg"} alt={""} />
      <HeadlineMedium style={{marginBottom: 40}}>{t('side-of-day-dialog.title')}</HeadlineMedium>
      <OutlineButton style={{marginBottom: 16}} size={'large'} text={"button.all-day"} click={() => onClick(Period.AllDay)} fullWidth={true} />
      <HalfDayButtonRow>
        <OutlineButton text={"general.am-only"} size={'large'} click={() => onClick(Period.AM)} />
        <OutlineButton text={"general.pm-only"} size={'large'} click={() => onClick(Period.PM)} />
      </HalfDayButtonRow>
    </DialogWrapper>
  )
}

interface Props {
  payload: { onSideOfDaySelected: (sideOfDay: Period) => any};
}


const HalfDayButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    width: 48%;
  }
`

const WhereaboutsImageGraphic = styled.img`
  display: flex;
  justify-self: center;
  margin-bottom: 16px;
  width: 100px;
`

const DialogWrapper = styled<any>(Dialog)<any>`
  width: 100%;
  
  p {
    
  }

  @media (${smallTablet}) {
    width: 550px;
  }
  .dialog__title {
    text-align: center;
    margin: 0 auto 32px auto;
  }
  .dialog__content {
    width: 100%;
    margin: 0;
    max-width: 100%;
  }
  .dialog__contentContainer {
    overflow-x: hidden;
    overflow-y: auto;
    @media (${smallTablet}) {
      height: 100%;
      max-height: 100%;
      overflow: auto;
    }
  }
`;
